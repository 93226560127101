import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BooleanListContentModel, TabModel } from "src/app/features/results/resultView/resultgrid/grid-filter/tab.model";
import { ListValue } from "src/app/features/results/shared/models/filter-definition.model";

@Component({
  selector: 'app-boolean-list',
  templateUrl: './boolean-list.component.html',
  styleUrls: ['./boolean-list.component.scss']
})
export class BooleanListComponent implements OnInit {
  @Input() tab: TabModel;
  @Output() dateChip: EventEmitter<any> = new EventEmitter();
  options: any[];
  selectedContent: BooleanListContentModel;
  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.selectedContent = new BooleanListContentModel();
    if (this.tab.selectedContent instanceof (BooleanListContentModel)) {
      setTimeout(() => {
        this.selectedContent.selectedValues = (this.tab.selectedContent as BooleanListContentModel).selectedValues;
        this.selectedContent.level = this.tab.selectedContent.level ?? null;
      });
    }

    this.options = [
      new ListValue(true, this.translateService.instant('eiYes')),
      new ListValue(false, this.translateService.instant('eiNo'))
    ];
  }

  onChange(e: any) {
    this.tab.selectedContent = this.selectedContent;
    this.dateChip.emit(this.tab);
  }
}
