import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { AppConfigService } from '../../core/services/app-config-service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DataService } from '../../core/services/data.service';
import { EventService } from 'src/app/core/services/event.service';
import { EventTypes } from 'src/app/core/enums/event.types';
export class CustomTranslationLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private dataService: DataService,
    private eventService: EventService) { }

  public getTranslation(lang: string): Observable<any> {
    const localSource = `assets/translations/${lang}.json`;
    if (this.appConfigService.configuration.translationProvider === 'Api') {
      const translationSource = `${this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl}configuration/translations`;
      return this.dataService.get(translationSource).pipe(
        map((res: any) => {
          this.appConfigService.userLanguageCode = res.languageCode;

          this.appConfigService.formatCulture = res.formatLanguageCode;
          this.appConfigService.setCurrentCulture(this.appConfigService.formatCulture);

          return res.translations;
        }), catchError((err: any) => {
          // On Error get translation from local
          return this.http.get(localSource);
        }));
    }
    return this.http.get(localSource);
  }
}
