<div class="layout-nav-top" *ngIf="isLoggedIn() && isUserDataLoaded">
  <app-header class="app-header"></app-header>
  <p-breadcrumb *ngIf="layout.breadcrumbItems.length !== 0" [model]="layout.breadcrumbItems"></p-breadcrumb>
  <div class="content" [ngStyle]="{'margin-top': getPage() ? '75px' : '104px'}">
    <router-outlet></router-outlet>
  </div>
  <app-footer [userLanguage] = "userLanguage" class="app-footer"></app-footer>
</div>

<app-loading [hidden]="!loading" [enableSpinnerText]="enableText"></app-loading>

<div *ngIf="!unKnownProfile">
  <p-dialog [(visible)]="openAccessDeniedDialog" [modal]="true" [responsive]="true" (onHide)="closeErrorDialog()">
    <p-header>
      <i class="ei ei-warning orange"></i>&nbsp; Access not granted
    </p-header>
    <div class="m-b-12">
      You have not been granted access for Results module.
      <br><br>
      Please contact your superior / admin to get access
    </div>
    <p-footer>
      <div class="text-right">
        <button pButton class="p-button" type="button" label="Ok" (click)="closeErrorDialog()">
        </button>
      </div>
    </p-footer>
  </p-dialog>
</div>

<p-dialog [(visible)]="unKnownProfile" [modal]="true" [responsive]="true" closable="false">
  <p-header>
    <i class="ei ei-warning orange"></i>&nbsp; Technical Error
  </p-header>
  <div class="m-b-12">
    Technical error occured, please try again.
    <br><br>
    If persisted please contact your superior / admin.
  </div>
  <p-footer>
    <div class="text-right">
      <button pButton class="p-button" type="button" label="Try Again" (click)="retryProfile()">
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="openGlobalErrorDialog" [modal]="true" [style]="{'overflow':'hidden',width:'500px','word-wrap':'break-word'}" [responsive]="true" (onHide)="onCloseGlobalErrorDialog()">
  <p-header>
    <i class="ei ei-warning orange"></i>&nbsp; {{'eiError'|translate}}
  </p-header>
  <div *ngIf='isTimeoutError' class="m-b-12">
    {{errorMessage}}
  </div>
  <div *ngIf='!isTimeoutError' class="m-b-12">
    {{technicalErrorReasonMessage}}
    <br>
    {{technicalErrorSupportMessage}}
  </div>
  <div *ngIf='!isTimeoutError' class="m-b-12">
    <span>{{'eiCorrelationId'|translate}}: {{correlationId}}</span>
  </div>
  <p-footer>
    <div class="text-right">
      <button *ngIf='isTimeoutError' pButton class="p-button" type="button" label="{{'eiRetry'|translate}}" (click)="retry()">
      </button>
      <button *ngIf='!isTimeoutError' pButton class="p-button" type="button" label="{{'eiOk'|translate}}" (click)="onCloseGlobalErrorDialog()">
      </button>
    </div>
  </p-footer>
</p-dialog>
<app-new-result-view [isEdit]="isBuildNewResultsModalEdit" [(openBuildNewResultsModal)]="openBuildNewResultsModal"></app-new-result-view>
