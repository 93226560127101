<div class="container">
  <div class="one-row col-lg">
    <label for="fromTime">{{'eiFrom'|translate}}</label>
    <p-calendar #timeMin inputId="fromTime" class="control-gap panel-top" [pTooltip]="setTimeFormat(minTime)" [tooltipDisabled]="tooltipDisabled" tooltipPosition="top" [(ngModel)]="minTime" [timeOnly]="true" [dateFormat]="timeFormat" [hourFormat]="hourFormat" (onClickOutside)="onMinTimeSelect($event,timeMin)" readonlyInput="true"></p-calendar>
  </div>
  <div class="one-row col-sm">-</div>
  <div class="one-row col-lg">
    <label for="untilTime">{{'eiTo'|translate}}</label>
    <p-calendar #timeMax inputId="untilTime" class="control-gap panel-top" [pTooltip]="setTimeFormat(maxTime)" [tooltipDisabled]="tooltipDisabled" tooltipPosition="top" [(ngModel)]="maxTime" [timeOnly]="true" [dateFormat]="timeFormat" [hourFormat]="hourFormat" (onClickOutside)="onMaxTimeSelect(timeMax)" readonlyInput="true"></p-calendar>
  </div>
</div>
