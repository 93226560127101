export enum TestStatus {
  UnderRegistration = 'UnderRegistration',
  Registered = 'Registered',
  InProgress = 'InProgress',
  Undervalidation = 'Undervalidation',
  Validated = 'Validated',
  Done = 'Done',
  UnderRevalidation = 'UnderRevalidation',
  ProvisionallyValidated = 'ProvisionallyValidated',
  Cancelled = 'Cancelled'
}
