import { NumberInput } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from "@angular/core";
import { InputNumber } from "primeng/inputnumber";
import { NumberRangeContentModel, TabModel } from "src/app/features/results/resultView/resultgrid/grid-filter/tab.model";

@Component({
  selector: 'app-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss']
})
export class NumberRangeComponent implements OnInit {
  @Input() tab: TabModel;
  @Output() dateChip: EventEmitter<any> = new EventEmitter();
  min: number;
  max: number;
  constructor() {

  }

  ngOnInit(): void {
    if (this.tab.selectedContent instanceof (NumberRangeContentModel)) {
      this.min = (this.tab.selectedContent as NumberRangeContentModel).selectedValues[0];
      this.max = (this.tab.selectedContent as NumberRangeContentModel).selectedValues[1];
    }
  }

  onBlurMinBox(minElement) {
    //maxElement.min = this.min;
    if (this.max && this.min > this.max) {
      this.min = undefined;
      minElement.value = undefined;
      minElement.input.nativeElement.value = ''
    }

    this.setOrResetSelectedContent();

  }

  onBlurMaxBox(maxElement) {
    if (this.min && this.min > this.max) {
      this.max = undefined;
      maxElement.value = undefined;
      maxElement.input.nativeElement.value = ''
    }
    this.setOrResetSelectedContent();
  }

  private setOrResetSelectedContent() {
    var selectedContent = this.tab.selectedContent as NumberRangeContentModel;
    if (!selectedContent) {
      selectedContent = new NumberRangeContentModel();
    }
    if ((!this.min && this.min !== 0) || (!this.max && this.max !== 0)) {
      selectedContent.selectedValues = [];
    } else {
      selectedContent.selectedValues = [this.min, this.max];
    }
    this.tab.selectedContent = selectedContent;
    this.dateChip.emit(this.tab);
  }

  resetValues(){
    this.min = undefined;
    this.max = undefined;
  }
}
