import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InterceptorMessageService } from './interceptor.message.service';
import { HttpStatusCode } from '../enums/HttpStatusCode';
import { BrowserFinder } from '../../common/static-classes/browser.finder';
import { RequestCache } from './request-cache.service';
import { EnableCacheHeaderName, NoProgressIndicator } from '../../common/global-constants/application-constants';
import { AuthService } from '../services/auth.service';
import { CookieStateService } from 'src/app/common/services/cookie-state-service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  UserId: string;
  LanguageCode: string;
  constructor(
    private readonly authService: AuthService,
    private interceptorMsgService: InterceptorMessageService,
    private readonly cache: RequestCache,
    private readonly cookieStateService: CookieStateService
  ) { }

  private requests: { RequestUri: string, Method: String, IgnoreProgress: boolean }[] = [];

  removeRequest(req: HttpRequest<void>) {
    const i = this.requests.findIndex(x => x.RequestUri === req.url && x.Method === req.method);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if (this.requests.length === 0 || this.requests.every(x => x.IgnoreProgress)) {
      this.interceptorMsgService.isLoading.next(false);
    }
  }

  intercept(
    request: HttpRequest<void>,
    next: HttpHandler
  ): Observable<HttpEvent<void>> {
    if (!this.isCachingEnabled(request)) {
      return this.sendRequest(request, next, false) as Observable<HttpEvent<void>>;
    }
    const cachedResponse = this.cache.get(request);
    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(request, next, true, this.cache) as Observable<HttpEvent<void>>;
  }

  private sendRequest(
    request: HttpRequest<void>,
    next: HttpHandler,
    isCacheEnabled: boolean,
    cache?: RequestCache
  ) {
    request = this.setHeader(request);
    this.requests.push({ RequestUri: request.url, Method: request.method, IgnoreProgress: request.headers.has(NoProgressIndicator) });
    if (!request.headers.has(NoProgressIndicator)) {
      this.interceptorMsgService.isLoading.next(true);
    } else {
      request.headers.delete(NoProgressIndicator);
    }

    return new Observable((observer) => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(request).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            if (isCacheEnabled) {
              cache.put(request, event);
            }
            this.removeRequest(request);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(request);
          observer.error(err);
        },
        () => {
          this.removeRequest(request);
          observer.complete();
        }
      );
      // return teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }

  private setHeader(request: HttpRequest<void>) {
    if (this.isHeaderUpdateRequired(request)) {
      const impersonatedData = this.cookieStateService.getState();
      if (BrowserFinder.isIE() && !this.isStaticFileRequest(request)) {
        request = request.clone({
          headers: request.headers
            .set('Authorization', 'Bearer ' + this.authService.getAccessToken())
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .set('If-Modified-Since', '0'),
        });
        if (impersonatedData && impersonatedData.isImpersonated) {
          request = request.clone({
            headers: request.headers.set(
              'X-Impersonate',
              impersonatedData.impersonatedEmail
            ),
          });
        }
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.authService.getAccessToken(),
          },
        });
        if (impersonatedData && impersonatedData.isImpersonated) {
          request = request.clone({
            setHeaders: {
              'X-Impersonate': impersonatedData.impersonatedEmail,
            },
          });
        }
      }
    }
    return request;
  }

  isHeaderUpdateRequired(request: HttpRequest<void>): boolean {
    return environment.enableAuthentication &&
      request.url.indexOf(this.authService.issuerUri ?? 'openid-configuration') === -1;
  }

  isCachingEnabled(request: HttpRequest<void>) {
    return (
      request.headers.has(EnableCacheHeaderName) &&
      request.headers.get(EnableCacheHeaderName).toLowerCase() === 'true'
    );
  }

  sendLoadingValue(value: boolean) {
    this.interceptorMsgService.isLoading.next(value);
  }

  private isStaticFileRequest(request: HttpRequest<void>) {
    // Add any static file extenstion which need to be handled
    return (
      request.url.indexOf('.json') > -1 ||
      request.url.indexOf('.html') > -1 ||
      request.url.indexOf('.css') > -1
    );
  }

  setLoadingValue(error: HttpErrorResponse) {
    switch (error.status) {
      case HttpStatusCode.BAD_REQUEST:
      case HttpStatusCode.NOT_FOUND:
      case HttpStatusCode.UNPROCESSABLE_ENTITY:
      case HttpStatusCode.FORBIDDEN:
        this.sendLoadingValue(false);
        break;

      default:
        break;
    }
  }
}
