import { EnumTypeComponent } from './enum-type.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [EnumTypeComponent],
  imports: [CommonModule],
  exports: [EnumTypeComponent]
})
export class EnumTypeModule {}
