import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, delay, switchMap } from 'rxjs';
import { EventService } from '../services/event.service';
import { EventTypes } from '../enums/event.types';
import { GlobalErrorEventModel } from '../../common/models/global-error-event.model';
import { InterceptorMessageService } from './interceptor.message.service';
import { HandleTechnicalError, HandleTimeout } from '../../common/global-constants/application-constants';
import { ChartService } from 'src/app/features/results/graphsAndCharts/charts/chart.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private maxRetries = 1000;
  private delayms = 100;
  constructor(
    private readonly eventService: EventService,
    private readonly interceptorMsgService: InterceptorMessageService,
    private readonly chartService: ChartService
  ) { }

  intercept(
    request: HttpRequest<void>,
    next: HttpHandler
  ): Observable<HttpEvent<void>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.chartService.setIsGraphLoading(false);
        if (this.isTimeoutError(request, error)) {
          this.handleLoading(false);

          return this.handleRetry(request, next, error, 0);
        } else if (error.status >= 500 && !error.error?.ErrorCode && this.handleErrorEnabled(request, HandleTechnicalError)) {
          let globalErrorModel = new GlobalErrorEventModel(error.status, false, error.error?.reference);
          this.eventService.broadCast(EventTypes.GlobalServiceError, globalErrorModel)
          throw error;
        }
        else {
          throw error;
        }
      })
    )

  }

  private handleLoading(display: boolean) {
    setTimeout(() => {
      this.interceptorMsgService.isLoading.next(display);
    })
  }
  private isTimeoutError(request: HttpRequest<void>, error: HttpErrorResponse) {
    return error.status >= 500 && error.error?.ErrorCode === 'ER-003-DB' && this.handleErrorEnabled(request, HandleTimeout);
  }

  private handleErrorEnabled(request: HttpRequest<void>, headerName: string) {
    return (request.headers.has(HandleTimeout) || request.headers.has(HandleTechnicalError)) &&
      request.headers.get(headerName).toLowerCase() === 'true';
  }


  private handleRetry = (request: HttpRequest<void>, next: HttpHandler, previousError: HttpErrorResponse, retries: number): Observable<HttpEvent<any>> => {
    if (retries >= this.maxRetries) {
      throw 'Maximum retries reached';
    }

    let globalErrorModel = new GlobalErrorEventModel(previousError.status, true);
    return this.eventService.tryListen(EventTypes.GlobalServiceError, globalErrorModel)
      .pipe(switchMap((consent: boolean) => {
        if (consent) {
          this.handleLoading(true);
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (this.isTimeoutError(request, error)) {
                this.handleLoading(false);
                return this.handleRetry(request, next, error, retries + 1).pipe(delay(this.delayms));
              } else {
                throw error;
              }
            }));
        } else {
          throw previousError;
        }
      }));
  }
}
