export class FilterDefinition {
  reference: string;
  code?: string;
  name: string;
  type?: string;
  values: any;
  features: { [key: string]: any };
  isHidden?: boolean;
  hidden?: boolean;
  dateType?: string;
  referenceFields?: string[];
  public get isAf() {
    return this.code.startsWith('AF_');
  }
}

export class FilterFeatures {
}

export class ListValue {
  constructor(
    public code: any,
    public text: string
  ) { }
  isApplied?= false;

  public equals(incoming: ListValue): boolean {
    return this.code === incoming.code && this.text === incoming.text && this.isApplied === incoming.isApplied;
  }
}

export class FieldSearchResponse {
  hasMoreResults: boolean;
  items: ListData[];
}
export class ListData {
  constructor(listValues: ListValue, label: string) {
    this.value = listValues;
    this.label = label;
  }
  value: ListValue;
  label: string;

  public equals(incoming: ListData): boolean {
    return this.value.equals(incoming.value) && this.label == incoming.label;
  }
}
