export class SelectedItemModel {
  id: string;
  name: string;
  parentId?: string;
  dataItem?: any;
  selectedToMove = false;
  capability: Display;
  isHidden?: boolean;
  isSearched?: boolean;
  selectedForMoreAction?: boolean;
}

export class Display {
  width: any;
}
