<div class="page-header">
  <span class="page-header-left flex-inline-body">
    <span class="flex-inline-body">
      <a class="page-icon" style="cursor: pointer;"><i class="{{icon}}"></i></a>
      <span class="page-title align-center">{{title}}</span>
    </span>
    <ng-container *ngIf='displayTemplate' [ngTemplateOutlet]='templateContent'></ng-container>
  </span>
  <span class="page-header-right"></span>
</div>
