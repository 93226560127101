export class Converter {
  public static FromTimeStringToDate(timeStr: string) {
    var now = new Date();
    now.setHours(parseInt(timeStr.substr(0, timeStr.indexOf(":"))));
    now.setMinutes(parseInt(timeStr.substr(timeStr.indexOf(":") + 1)));
    now.setSeconds(0);
    return now;
  }

  public static ToTimeString(date: Date) {
    return `${date.getHours()}:${date.getMinutes()}`;
  }
}
