import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { DopSessionRevocationConfig } from './dop-session-revocation.config';
import { NoProgressIndicator } from 'src/app/common/global-constants/application-constants';

@Injectable({
    providedIn: 'root'
})
export class DopSessionRevocationService {
    private config: DopSessionRevocationConfig | undefined;
    constructor(private httpClient: HttpClient) {}

    configure(config: DopSessionRevocationConfig | undefined): void {
        this.config = config;
    }

    revokeSession(sessionId: string, callback: (data: any) => void, errorCallback: (error: any) => void): void {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        if (this.config) {
            const url = this.config.serviceBaseUrl + 'session/revoke-session/' + sessionId;
            this.httpClient.post(url, null, httpOptions).subscribe(
                response => {
                    callback(response);
                },
                error => {
                    errorCallback(error);
                }
            );
        }
    }

    checkSessionStatus(sessionId: string, callback: (data: any) => void, errorCallback: (error: any) => void): void {
        if (this.config) {
            interval(this.config.sessionCheckInterval * 1000).subscribe(() => {
                const url = this.config?.serviceBaseUrl + 'session/' + sessionId + '/status';
                const headers = {};
                headers[NoProgressIndicator] = 'true';
                
                this.httpClient.get(url, { headers: headers }).subscribe(
                    response => {
                        callback(response);
                    },
                    error => {
                        errorCallback(error);
                    }
                );
            });
        }
    }
}