export enum ServiceMethod {
  ExportOptions = 1,
  ExportSettings = 2,
  PerformExport = 3,
  QuickExport = 4,
  QuickAssessment = 5,
  AssessmentOptions = 6,
  SampleAssessmentSummary = 7,
  DownloadAssessment = 8
}
