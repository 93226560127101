import { Component, Input } from '@angular/core';
import { AppConfigService } from './../../core/services/app-config-service';
import { RouteConstants } from '../global-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
  constructor(
    private readonly appConfig: AppConfigService,
  ) { }
  @Input() userLanguage: string;
  currentYear = new Date().getFullYear();
  version = this.appConfig.getResultsAppVersion();
  isLanguageEnabled = this.appConfig.getLanguagePreference();

  isGraphPage(){
    return window.location.href.includes(RouteConstants.GraphsAndChartsPath);
  }

  getLanguageCode(): string {
    return (this.userLanguage || "").toUpperCase(); 
  }
}
