import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from '../core/interceptor/token-interceptor';
import { AppConfigService } from '../core/services/app-config-service';
import { AuthService } from '../core/services/auth.service';
import { SharedModule } from '../shared/shared.module';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppPageHeaderComponent } from './app-page-header/app-page-header.component';

@NgModule({
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    AppPageHeaderComponent,
  ],
  imports: [
    SharedModule,
    DialogModule,
    CommonModule,
    DropdownModule
  ],
  exports: [
    AppHeaderComponent,
    AppFooterComponent,
    AppPageHeaderComponent,
    CommonModule,
    FormsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
})

export class ResultsCommonModule {

}
