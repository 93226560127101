import { NgModule } from "@angular/core";
import { CustomDatePipe } from "./custom-date.pipe";


@NgModule({
  declarations: [CustomDatePipe],
  imports: [],
  providers: [CustomDatePipe],
  exports: [CustomDatePipe]
})
export class CustomDatePipeModule { }
