import { Inject, Injectable } from '@angular/core';
import {
  OAuthService,
  AuthConfig,
  OAuthInfoEvent,
  OAuthErrorEvent,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { AppConfigService } from 'src/app/core/services/app-config-service';
import { StoreService, StoreKeys } from '../store/ngrx-store.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, interval } from 'rxjs';
import { AppConfig } from '../../shared/models/app-config';
import { ConfigurationService } from './configuration-service';
import { EventService } from './event.service';
import { EventTypes } from '../enums/event.types';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileModel } from '../../shared/models/user-profile.model';
import { map } from 'rxjs/operators';
import { AnalyticsServiceProvider } from '../analytics/analytics-service-provider';
import { DOCUMENT } from '@angular/common';
import { FieldDefinitionCachedService } from '../../features/results/buildNewResultView/services/fieldDefinition.cached.service';
import { SystemData } from '../../shared/models/system-data.model';
import { ResultsSearchService } from '../../features/results/resultView/results-search.service';
import { StoreKey } from '../store/ngrx-store.keys';
import { CookieService } from 'ngx-cookie-service';
import { OAuthInfoEventTypes } from '../enums/oauth-event-types';
import { CookieStateService } from 'src/app/common/services/cookie-state-service';
import { Router } from '@angular/router';
import { DopSessionRevocationConfig, DopSessionRevocationService } from 'libs/dop-session-revocation/src/public-api';
import { GraphTypes } from '../enums/graph-preference';

@Injectable()
export class AuthService {
  private configuration: AppConfig;
  private authConfig: AuthConfig;
  private userDetails: UserProfileModel;
  isUserDataLoaded$ = new BehaviorSubject<boolean>(false);
  dopSessionConfig?: DopSessionRevocationConfig;

  constructor(
    private appConfigService: AppConfigService,
    private oauthService: OAuthService,
    private storeService: StoreService,
    private configurationService: ConfigurationService,
    private eventService: EventService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceProvider,
    private readonly fieldDefinitionService: FieldDefinitionCachedService,
    private readonly resultSearchService: ResultsSearchService,
    private readonly cookieService: CookieService,
    private sessionRevocationService: DopSessionRevocationService,
    private readonly cookieStateService: CookieStateService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  async login() {
    await this.setConfiguration();
    if (environment.enableAuthentication) {
      const data: any = await this.storeService
        .Get(StoreKeys.UserDetails)
        .toPromise();
      if (!data) {
        // Below OAuth events are logged to troubleshoot customer user intermittenet access issue.
        this.oauthService.events.subscribe((event) => {
          if (event instanceof OAuthInfoEvent) {
            switch (event.type) {
              case OAuthInfoEventTypes.Logout:
                location.reload();
                break;
              case OAuthInfoEventTypes.TokenExpire:
                this.logout();
                break;

              default:
                console.log(
                  'OAuthInfoEvent:',
                  event.type,
                  this.getValidIdTokenMessage()
                );
                break;
            }
          } else if (event instanceof OAuthErrorEvent) {
            console.log(
              'OAuthErrorEvent:',
              event.type,
              this.getValidIdTokenMessage()
            );
          } else if (event instanceof OAuthSuccessEvent) {
            console.log(
              'OAuthSuccessEvent:',
              event.type,
              this.getValidIdTokenMessage()
            );
          } else console.log(JSON.stringify(event));
        });

        return this.tryLogin().then(async () => {
          let currentRedirectUri;
          if (!this.oauthService.hasValidAccessToken()) {
            if (
              this.cookieService.check('logOutInitiated') &&
              this.cookieService.get('logOutInitiated') == 'true'
            ) {
              this.oauthService.redirectUri =
                this.appConfigService.configuration.oauthConfiguration.redirectUri;
              this.cookieService.delete('logOutInitiated');
            }
            else {
                this.oauthService.redirectUri = this.document.defaultView.location.href;
            }

            currentRedirectUri = this.oauthService.redirectUri;

            if (!this.checkRedirectUrlMatching(currentRedirectUri)) {
              this.createCookie(this.oauthService.redirectUri);
              this.oauthService.redirectUri = this.appConfigService.configuration.oauthConfiguration.redirectUri;
            }

            this.oauthService.initLoginFlow();
          }

          if (this.oauthService.hasValidAccessToken()) {
            await this.performPostAuthenticationChecks();
          }

          if (this.userDetails?.trackingConsent) {
            await this.analyticsService.initialize();
          }
          if (this.cookieService.check('currentRedirectUri') && currentRedirectUri == undefined) {
            this.router.navigate([this.getRoutePath(this.cookieService.get('currentRedirectUri'))]);
            this.cookieService.delete('currentRedirectUri');
          }
        });
      } else {
        this.userDetails = data;
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(true);
  }

  private tryLogin() {
    // code = code flow + PKCE
    return this.appConfigService.authConfig.responseType === 'code'
      ? this.oauthService.loadDiscoveryDocumentAndTryLogin()
      : this.oauthService.tryLogin();
  }

  async performPostAuthenticationChecks() {
    await this.getInitialData().toPromise();
    this.doSessionBackgroundCheck();
    if (
      this.configurationService.configurations &&
      this.configurationService.configurations.useResults
    ) {
      this.fieldDefinitionService.setDefinitions();
      //TBD: call below service for respective graphs
      this.fieldDefinitionService.setResultsDefinitions(GraphTypes.ParameterTrend, StoreKeys.ParameterTrendFilterDefinitions, StoreKeys.ParameterTrendPrefDefinitions);
      this.loadConfigurations();
      this.resultSearchService.initializeSearch();
    }
  }

  private getInitialData() {
    const browserLanguage = this.translateService.getBrowserLang() || 'en';
    return this.configurationService.getSystemInitialData(browserLanguage).pipe(
      map((res: SystemData | { [key: string]: any }) => {
        this.setLocalization(res, browserLanguage);
        if (res?.menuItems || res?.profile || res?.translationDetail) {
          this.eventService.broadCast(EventTypes.ConfigurationsLoaded);
          this.getUserDetailsFromService(res);
        } else {
          this.userDetails = new UserProfileModel();
          this.userDetails.trackingConsent = false;
          this.userDetails.unknownProfile = true;
          this.isUserDataLoaded$.next(true);
        }
      })
    );
  }

  private loadConfigurations() {
    this.configurationService.getConfigurations().subscribe((response: any) => {
      this.storeService.Save(
        StoreKey.ShowAggregatedColumnsinDownload,
        response.showAggregatedColumnsinDownload,
        true
      );
      this.storeService.Save(
        StoreKey.UseSpecificExport,
        response.useSpecificExport,
        true
      );
      this.storeService.Save(
        StoreKey.UseComplianceAssessment,
        response.useComplianceAssessment,
        true
      );
      this.storeService.Save(
        StoreKey.EnableGraphsAndCharts,
        response.enableGraphsAndCharts,
        true
      );
      this.storeService.Save(
        StoreKey.EnableParameterTrend,
        response.enableParameterTrend,
        true
      );
      this.storeService.Save(
        StoreKey.EnableResultConformity,
        response.enableResultsConformity,
        true
      );
      this.storeService.Save(
        StoreKey.EnableReceivedSampleStatus,
        response.enableReceivedSampleStatus,
        true
      );
      this.configurationService.enableGraphsAndCharts =
        response.enableGraphsAndCharts;
      this.configurationService.isConfigurationsLoaded$.next(true);
      this.appConfigService.uiStartDate = response.uiStartDate ? response.uiStartDate : this.appConfigService.getUIStartDate();
    });
  }

  getUserDetailsFromService(systemData: any) {
    this.userDetails = systemData.profile;
    this.userDetails.canManagePublicViews = systemData.canManagePublicViews;
    this.storeService.Save(StoreKeys.UserDetails, systemData.profile, true);
    this.isUserDataLoaded$.next(true);
  }

  private setLocalization(
    systemData: SystemData | { [key: string]: any },
    browserLanguage: string
  ) {
    // Langugae code is taken from server. Set language here to load the user language translations from server.
    // If server fails translations will be loaded from client side using browser language or default to english.

    if (systemData.translationDetail) {
      this.translateService.setTranslation(
        systemData.translationDetail.languageCode,
        systemData.translationDetail.translations
      );
      this.translateService.currentLang =
        systemData.translationDetail.languageCode;
    } else {
      this.translateService.setTranslation(browserLanguage, systemData);
      this.translateService.currentLang = browserLanguage;
    }
  }

  async setConfiguration() {
    this.configuration = await this.appConfigService.setConfiguration();
    this.authConfig = this.appConfigService.setupAuthConfig(this.configuration);
    this.dopSessionConfig = this.appConfigService.setSessionRevocationConfig(this.configuration);

    if (!this.isLoggedIn()) {
      this.appConfigService.setInit();
    }
    this.oauthService.configure(this.authConfig);
    this.sessionRevocationService.configure(this.dopSessionConfig);
  }

  getPostLogoutRedirectUrl() {
    return this.oauthService.postLogoutRedirectUri;
  }

  getRedirectUrl(): string {
    return this.oauthService.redirectUri;
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  getClaims() {
    return this.oauthService.getIdentityClaims();
  }

  isLoggedIn() {
    return (
      this.oauthService.hasValidIdToken() &&
      this.oauthService.hasValidAccessToken()
    );
  }

  logout() {
    var expirydate = new Date();
    expirydate.setSeconds(expirydate.getSeconds() + 10);
    this.cookieService.set('logOutInitiated', 'true', expirydate);

    // Call revoke end point - do logout irrespective of response.
    this.sessionRevocationService.revokeSession(
      this.getSessionId(),
      this.handleLogout,
      this.handleLogout
    );
  }

  handleLogout = () => {
    this.cookieStateService.removeUserStateCookie();
    this.oauthService.logOut(true);
  };

  doSessionBackgroundCheck() {
    this.sessionRevocationService.checkSessionStatus(
      this.getSessionId(),
      (data) => {
        if (data.isRevoked) this.handleLogout();
      },
      (error) => {
        //do nothing/swallow the error.
      }
    );
  }

  getSessionId(): string {
    const idClaims = this.oauthService.getIdentityClaims();
    type ObjectKey = keyof typeof idClaims;
    if (idClaims === null || idClaims === undefined) {
      return null;
    }
    return idClaims['pi.sri' as ObjectKey];
  }

  getUserDetails() {
    return this.userDetails;
  }

  isPreconditionFailure(): boolean {
    return this.configurationService.preconditionFailed;
  }

  isImpersonatedUser(): boolean {
    const impersonatedData = this.cookieStateService.getState();
    return impersonatedData && impersonatedData.isImpersonated;
  }

  get issuerUri() {
    return this.authConfig?.issuer;
  }

  private getValidIdTokenMessage(): string {
    return `Id_Token_Expired: ${this.oauthService.hasValidIdToken()}`;
  }

  private getRoutePath(data) {
    let redirectUri = this.appConfigService.configuration.oauthConfiguration.redirectUri;
    return data.substring(redirectUri.length);
  }

  private checkRedirectUrlMatching(currentRedirectUri) {
    if (currentRedirectUri != undefined && currentRedirectUri != this.appConfigService.configuration.oauthConfiguration.redirectUri)
      return false;

    return true;
  }

  private createCookie(uri: string) {
    this.cookieService.set('currentRedirectUri', uri);
  }

  getUserLanguage() {
    return this.userDetails.language;
  }
}
