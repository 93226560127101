import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core/guards/auth.guard';
import { RouteConstants } from './common/global-constants/route-constants';

const routes: Routes = [
  {
    path: RouteConstants.ResultsHomePath, loadChildren: () => import('./features/results/results.module').then(m => m.ResultsModule)
    , canActivate: [AuthenticationGuard]
  },
  {
    path: RouteConstants.ImpersonatePath,
    loadChildren: () => import('./features/impersonate/impersonate.module').then(m => m.ImpersonateModule),
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
