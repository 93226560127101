import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})

export class CookieStateService {
    constructor(private cookieService: CookieService) {
    }

    getState(): any {
        const userStateCookie = 'userState';
        const impersonatedCookieExists = this.cookieService.check(userStateCookie);
        if (impersonatedCookieExists) {
            const impersonatedDetails = atob(this.cookieService.get(userStateCookie));
            return JSON.parse(impersonatedDetails);
        }
        return null;
    }

    removeUserStateCookie(): any {
        let subDomains = [];
        const path = '/';
        const impersonatedCookieExists = this.cookieService.check('userState');
        let domain = window.location.host;
        subDomains = domain.split('.');
        if (subDomains.length > 2) {
            domain = subDomains.length >= 3 ?
                '.' + subDomains[subDomains.length - 2] + '.' + subDomains[subDomains.length - 1]
                : '.' + subDomains[subDomains.length - 1];
        }
        if (impersonatedCookieExists) {
            this.cookieService.delete('userState', path, domain);
        }
    }
}
