import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './app-confirm-dialog.component.html',
  styleUrls: ['./app-confirm-dialog.component.scss']
})

export class AppConfirmDialogComponent implements OnInit {
  @Input() public openDialog: boolean;
  @Input() public header: string;
  @Input() public width: number;
  @Input() public height: number;
  @Input() public scrollable: boolean;
  @Input() public positiveButtonDisabled = false;
  @Input() public positiveButtonLabel = 'eiYes';
  @Input() public negativeButtonLabel = 'eiNo';
  @Input() public toolTipMessage = '';

  @Output() confirmYes: EventEmitter<any> = new EventEmitter();
  @Output() confirmNo: EventEmitter<any> = new EventEmitter();
  @Output() openDialogChange: EventEmitter<any> = new EventEmitter();

  dialogHeight = 100;

  constructor(private router: Router){
  }

  confirmYesClick(): void {
    this.confirmYes.emit();
  }

  confirmNoClick(): void {
    this.confirmNo.emit();
    this.openDialogChange.emit(true);
  }

  resetDialog(): void {
    this.openDialog = false;
    this.openDialogChange.emit(false);
  }

  ngOnInit(): void {
    if (this.scrollable) {
      this.dialogHeight = this.height - 172;
    }
  }
}
