<div class="pickList-widget" id="build_new_dialog">
  <div class="picklist-selectionBox">
    <div class="box-title-bar" [style.width]="selectionBoxWidth+'px'">
      <span class="box-label">
        {{libTranslations.availableFieldsTitle}}
      </span>
      <span class="addRemoveLink" *ngIf="treeModel.getAvailableItems().length>0" (click)="addAll()">{{libTranslations.addAllTitle||'Add all'}} <i class="ei ei-arrow-right"></i></span>
    </div>
    <div [class.selectionBox]="!enableSearch" [class.selectionBox-container]="enableSearch" [style.height]="selectionBoxHeight +'px'" [style.width]="selectionBoxWidth+'px'" [style.max-height]="selectionBoxHeight +'px'">
      <div *ngIf='enableSearch'>
        <div class="p-inputgroup-pick-list search-input m-r-12">
          <span class="p-inputgroup-addon-pick-list"><i class="ei ei-magnifier ei-16"></i></span>
          <div *ngIf="searchOnKeyUp">
            <input id="searchField_{{treeModel.id}}" class="p-inputtext" #searchFieldBox style="padding-left:25px" type="text" pInputText size="50" (keyup)="onSearch(searchFieldBox.value)" placeholder="{{libTranslations.search ||'Search'}}">
            <i class="'p-button ei ei-cross clearSearch" [class.hidden]="!searchFieldBox.value" (click)="searchFieldBox.value='';onSearch('')" title="Clear"></i>
          </div>
          <div *ngIf="!searchOnKeyUp">
            <input id="searchField_{{treeModel.id}}" class="p-inputtext" #searchBox style="padding-left:25px" type="text" pInputText size="50" (keyup.enter)="onSearch(searchBox.value)" placeholder="{{libTranslations.searchBackgroundText ||'Enter search keyword'}}">
            <i class="'p-button ei ei-cross clearSearch" [class.hidden]="!searchBox.value" (click)="searchBox.value='';onSearch('')" title="Clear"></i>
          </div>
        </div>
        <div *ngIf="libTranslations.searchTextNote" class="search-note">
          <span class="text-light">{{libTranslations.searchTextNote}}</span>
        </div>
      </div>
      <ng-container *ngIf="treeModel.isFlatData; then listWithVirtualScroll; else treeviewOther">
      </ng-container>
      <ng-template #listWithVirtualScroll>
        <div [class.selectionBox-fields]="enableSearch"
             [style.margin-top]="enableSearch?'10px':''">
          <ul *ngIf="virtualDatasource" id="{{treeModel.id}}" class="treeview-container" [style.height]="enableSearch? ((selectionBoxHeight-100) + 'px') :''" style="overflow:auto;" [style.width]="enableSearch? (selectionBoxWidth+'px'):''">
            <li *uiScroll="let item of virtualDatasource" class="treenode" style="display: block; width: 100%;">
              <div class="treenode-content" style="margin-left:-21px;margin-right:-21px;padding-left:18px;margin-bottom:-4px"
                   [class.selected-node]="item.selected && !item.hasChildren()" id="{{item.id}}">
                <div class="treecontent-first">
                  <input id="chk_{{item.id}}" type="checkbox" [(ngModel)]="item.selected" class="tree-checkbox" (click)="item.onSelect($event,treeModel)" />
                  <label for="chk_{{item.id}}" title="{{item.name}}">{{item.name}}</label>
                </div>
                <div class="treecontent-second">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
      <ng-template #treeviewOther>
        <div [class.selectionBox-fields]="enableSearch"
             [style.height]="enableSearch? ((selectionBoxHeight-(searchOnKeyUp?68:85)) + 'px') :''"
             [style.max-height]="enableSearch? ((selectionBoxHeight-(searchOnKeyUp?68:85))+'px'): ''"
             [style.width]="enableSearch? (selectionBoxWidth+'px'):''"
             [style.margin-top]="enableSearch?'10px':''">
          <ul id="{{treeModel.id}}" class="treeview-container" [style.height]="enableSearch? ((selectionBoxHeight-(searchOnKeyUp?78:90)) + 'px') :''" style="overflow:auto;" [style.width]="enableSearch? (selectionBoxWidth+'px'):''">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: {list:filterNonMovedItems(treeModel.items),parentInd:0 } }"></ng-container>
            <ng-template #recursiveList let-data>
              <li *ngFor="let item of data.list;index as i" class="treenode">
                <div class="treenode-content" [class.parent-node]="item.hasChildren()" [class.heighlight-parent]="item.level===1 && !treeModel.isFlatData"
                     [style.width]="(selectionBoxWidth+2)+'px'" [style.margin-left]="(item.level*(-21))+'px'"
                     [style.margin-right]="(item.level*(-21))+'px'" [style.padding-left]="(item.level*18)+'px'"
                     style="margin-bottom:-4px;" [class.selected-node]="item.selected && !item.hasChildren()"
                     id="{{item.id}}">
                  <div class="treecontent-first">
                    <input *ngIf="(item.level===1 && !item.allChildrenMoved() && item.hasSearched(searchKey)) || !item.hasChildren()" id="chk_{{data.parentInd}}_{{i}}_{{item.id}}" type="checkbox" [(ngModel)]="item.selected" class="tree-checkbox" (click)="item.onSelect($event, null, searchKey)" [indeterminate]="item.indeterminated" />
                    <label for="chk_{{data.parentInd}}_{{i}}_{{item.id}}" title="{{item.name}}">{{item.name}}</label>
                  </div>
                  <div class="treecontent-second">
                    <span *ngIf="item.items.length > 0 && item.expanded && !item.allChildrenMoved() && item.hasSearched(searchKey)" class="toggle-btn" (click)="item.onToggle()"><i class="ei ei-lg ei-circle-minus"></i></span>
                    <span *ngIf="item.items.length > 0 && !item.expanded && !item.allChildrenMoved() && item.hasSearched(searchKey)" class="toggle-btn" (click)="item.onToggle()"><i class="ei ei-lg ei-plus-circle"></i></span>
                  </div>
                </div>
                <ul *ngIf="item.items.length > 0 && item.expanded">
                  <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: {list:filterNonMovedItems(item.items),parentInd:data.parentInd+'_'+i}}"></ng-container>
                </ul>
              </li>
            </ng-template>
          </ul>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="pickList-movement">
    <button class="p-button p-component move-button m-w-auto" [disabled]="!enableMoveRight" (click)="moveRight()"><i class="ei ei-arrow-right" id="move_right"></i></button>
    <button class="p-button p-component move-button m-w-auto" style="margin-top:5px;" [disabled]="!enableMoveLeft" (click)="moveLeft()"><i class="ei ei-arrow-left" id="move_left"></i></button>
  </div>
  <div class="picklist-selectionBox">
    <div class="box-title-bar" [style.width]="selectionBoxWidth+'px'">
      <span class="box-label">
        {{libTranslations.selectedItemsTitle}}
      </span>
      <span class="addRemoveLink" (click)="removeAll()" *ngIf="selectedItems && selectedItems.length>0"><i class="ei ei-arrow-left"></i> {{libTranslations.removeAllTitle||'Remove all'}}</span>
    </div>
    <div [class.selectionBox]="!enableSelectedSearch" [class.selectionBox-container]="enableSelectedSearch" [style.height]="selectionBoxHeight+'px'" [style.max-height]="selectionBoxHeight+'px'" [style.width]="selectionBoxWidth+'px'">
      <div *ngIf="selectedItems && selectedItems.length>0 && (enableSelectedSearch || displaySelectionCheckbox)" class="action-bar">
        <div class="search-box">
          <div class="p-inputgroup-pick-list search-input m-r-12" *ngIf="enableSelectedSearch">
            <span class="p-inputgroup-addon-pick-list"><i class="ei ei-magnifier ei-16"></i></span>
            <input id="selectedItemsSearch_{{treeModel.id}}" class="p-inputtext" #assignedItemSearchBox style="padding-left:25px" type="text" pInputText size="50"
                   (keyup.enter)="onSearchSelectedItems(assignedItemSearchBox.value)" [(ngModel)]="assignItemSearchKey" placeholder="{{libTranslations.searchBackgroundText ||'Enter search keyword'}}">
            <i class="'p-button ei ei-cross clearSearch" [class.hidden]="!assignedItemSearchBox.value" (click)="assignedItemSearchBox.value='';onSearchSelectedItems('')" title="Clear"></i>
          </div>
          <div *ngIf="libTranslations.searchTextNote" class="search-note">
            <span class="text-light">{{libTranslations.searchTextNote}}</span>
          </div>
        </div>
        <div *ngIf="displaySelectionCheckbox" style="width:35%; display: table;">
          <span class="selectcheck-title">
            {{libTranslations.displaySelectionBoxTitle}}
          </span>
          <span [libDopTooltipDirective]="libTranslations.displaySelectionBoxNote" [tooltipStyleClass]="'tooltip-top-yellow'" tooltipPosition="top">
            <i class="ei ei-16 ei-info mr-4 mt-2"></i>
          </span>
        </div>
      </div>
      <div [class.selectionBox-fields]="enableSelectedSearch"
           [style.height]="enableSelectedSearch? ((selectionBoxHeight-85) + 'px') :''"
           [style.max-height]="enableSelectedSearch? ((selectionBoxHeight-85)+'px'): ''"
           [style.width]="enableSelectedSearch? (selectionBoxWidth+'px'):''"
           [style.margin-top]="enableSelectedSearch?'10px':''">
        <ul class="treeview-container" [style.height]="enableSelectedSearch? ((selectionBoxHeight-100) + 'px') :''"
            style="overflow:auto;" [style.width]="enableSelectedSearch? (selectionBoxWidth+'px'):''">
          <li class="treenode" *ngIf="displaySelectAll">
            <div style="display: flex;">
              <div class="treecontent" [class.selected-check]="displaySelectionCheckbox">
                <input id="assignedListSelectAllChk" type="checkbox" class="tree-checkbox" [(ngModel)]="allSelectedItemsChecked"
                       (change)="onSelectAllSearchedData($event)" [indeterminate]="fewSelectedItemsChecked"/>
                <label for="assignedListSelectAllChk">{{libTranslations.selectAll}}</label>
              </div>
            </div>
          </li>
          <li *ngFor="let item of getDisplayableItems(selectedItems);index as i" class="treenode">
            <div style="display: flex;">
              <div class="treecontent" [class.selected-check]="displaySelectionCheckbox">
                <input id="selectedChk_{{i}}_{{item.id}}" type="checkbox" [(ngModel)]="item.selectedToMove" (change)="onSelectSelectedItem()" class="tree-checkbox" />
                <label for="selectedChk_{{i}}_{{item.id}}" title="{{item.name}}">{{item.name}}</label>
              </div>
              <div *ngIf="displaySelectionCheckbox" class="actioncheck-container">
                <input id="performActionChk_{{item.id}}" type="checkbox" [(ngModel)]="item.selectedForMoreAction" class="tree-checkbox" />
                <label for="performActionChk_{{item.id}}"></label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="pickList-movement pickList-reorder">
    <button class="p-button p-component reorder-button m-w-auto" [disabled]="!enableReorder" (click)="reorder(true)"><i class="ei ei-arrow-up"></i></button>
    <button class="p-button p-component reorder-button m-w-auto" style="margin-top:5px;" [disabled]="!enableReorder" (click)="reorder(false)"><i class="ei ei-arrow-down"></i></button>
  </div>
</div>
