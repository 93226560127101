import { FormatWidth, getLocaleDateFormat, getLocaleDateTimeFormat, getLocaleTimeFormat } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { DateFormat } from 'src/app/core/enums/date-formats';
import { AppConfigService } from 'src/app/core/services/app-config-service';


@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor(@Inject(LOCALE_ID) private locale: string,
    private appConfig: AppConfigService) {
  }

  getCustomLocaleDateFormat(formatWidth: FormatWidth, isPrimeNgFormat: boolean = false) {
    const localeFormat = getLocaleDateFormat(this.locale, formatWidth);
    return this.getCustomFormat(localeFormat, isPrimeNgFormat);
  }

  getAcceptableDateFormats(): string[] {
    let localeFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);

    if (localeFormat.len('y') < 4) {
      localeFormat = localeFormat.replaceAllOccurenceWith('y', 'YYYY');
    }

    if (localeFormat.len('d') < 2) {
      localeFormat = localeFormat.replaceAllOccurenceWith('d', 'dd');
    }
    if (localeFormat.len('m') < 2) {
      localeFormat = localeFormat.replaceAllOccurenceWith('m', 'MM');
    }

    //Returning array as to support aditional formats in future
    return [localeFormat];
  }

  getCustomLocaleDateTimeFormat(formatWidth: FormatWidth, isPrimeNgFormat: boolean = false) {

    const shortTime = getLocaleTimeFormat(this.locale, formatWidth);
    const shortDate = getLocaleDateFormat(this.locale, formatWidth);
    const formatValue = this.formatDateTime(getLocaleDateTimeFormat(this.locale, formatWidth), [shortTime, shortDate]);

    return this.getCustomFormat(formatValue, isPrimeNgFormat);
  }

  getCustomLocalTimeFormat(formatWidth: FormatWidth, isPrimeNgFormat: boolean = false) {
    const localeTimeFormat = getLocaleTimeFormat(this.locale, formatWidth);
    return this.getCustomFormat(localeTimeFormat, isPrimeNgFormat);
  }

  private formatDateTime(str, opt_values) {
    if (opt_values) {
      str = str.replace(/\{([^}]+)}/g, function (match, key) {
        return (opt_values != null && key in opt_values) ? opt_values[key] : match;
      });
    }
    return str;
  }

  private getCustomFormat(value: string, isPrimeNgFormat: boolean): string {
    const initialIndex = value.indexOf('y');
    const lastindex = value.lastIndexOf('y');

    if (isPrimeNgFormat) {
      return (value.substring(0, initialIndex) + 'yy' + value.substring(lastindex + 1)).toLowerCase();
    }

    return initialIndex === -1 ? value : value.substring(0, initialIndex) + 'y' + value.substring(lastindex + 1);
  }

  public getModifiedDateWithFormat(val: string, minYear: number) {
    const acceptableFormats = this.getAcceptableDateFormats();
    let dateTime: DateTime;
    acceptableFormats.forEach((x) => {
      if (DateTime.fromFormat(val, x).isValid) {
        dateTime = DateTime.fromFormat(val, x);
        if (dateTime.year < minYear) {
          dateTime = null;
        }
      }
    });
    return dateTime?.toJSDate();
  }

  public checkForInvalidDate(event: any) {
    const regExp = /[a-zA-Z]/g;
    const eventChar = event.target.value;
    if (regExp.test(eventChar) && this.appConfig.formatCulture !== 'bg') {
      event.target.value = eventChar.slice(0, -1);
      return false;
    }
    return true;
  }

  public setDateOnCondition(value, currentDate, minDate, maxDate, minYear, enableFutureDate = false) {
    if (!value) {
      currentDate = null;
      return currentDate;
    }
    if (!currentDate) {
      currentDate = this.getModifiedDateWithFormat(value, minYear)
    }
    if (currentDate < minDate) {
      currentDate = minDate
    }
    if (!enableFutureDate && currentDate > maxDate) {
      currentDate = maxDate;
    }
    return currentDate
  }

  getDaysDiff(date1, date2): number {
    // One day Time in ms (milliseconds)
    let one_day = 1000 * 60 * 60 * 24;
    let diff = Math.round((date2.getTime() - 
    date1.getTime()) / one_day);
    // To remove the decimals from the (diff) 
    // resulting days value
    return parseInt(diff?.toFixed(0));
  }
  getWeeksDiff(date1, date2): number {
    return parseInt(Math.round(this.getDaysDiff(date1, date2)/7).toFixed(0));
  }
  getMonthDiff(date1, date2) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }

}
