class Group<T>  {
  keys: any;
  members: T[] = [];
  constructor(keys: any) {
    this.keys = keys;
  }
}

class SortMeta {
  field: string;
  order: number; // 1 Asc or -1 Desc
}

/**
 * Use example : data.groupBy(['shape', 'color']);
 */
if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T>(this: Array<T>, keys: string[]): Group<T>[] {
    const groups: { [key: string]: T[] } = {};
    this.forEach(function (o: T) {
      const keyObj: { [k: string]: any } = {};
      keys.forEach(k => keyObj[k] = o[k] ?? '');
      const group = JSON.stringify(keyObj);
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (groupKey) {
      const group = new Group<T>(JSON.parse(groupKey));
      group.members = groups[groupKey];
      return group;
    }).clone();
  };
}

/**
 * Use example : data.multsort([{field:'shape',order:1},{field:'color',order:-1}]);
 * OR only ascending sorting of all fields can be called as data.multsort(['shape','color'])
 */
if (!Array.prototype.multiSort) {
  const fieldSorter = function (fields: string[]) {
    const dir = [];
    const l = fields.length;
    let i;
    fields = fields.map(function (o, v) {
      if (o[0] === '-') {
        dir[v] = -1;
        o = o.substring(1);
      } else {
        dir[v] = 1;
      }
      return o;
    });

    return function (a, b) {
      for (i = 0; i < l; i++) {
        const o = fields[i];
        const firstData = Array.isArray(a[o]) ? (a[o].length > 0 ? a[o][0]?.toLowerCase() : '') : a[o]?.toLowerCase();
        const secondData = Array.isArray(b[o]) ? (b[o].length > 0 ? b[o][0]?.toLowerCase() : '') : b[o]?.toLowerCase();
        if (firstData > secondData) {
          return dir[i];
        }
        if (firstData < secondData) {
          return -(dir[i]);
        }
      }
      return 0;
    };
  };
  Array.prototype.multiSort = function <T>(this: Array<T>, sortMetaIndo: Array<SortMeta> | Array<string>) {
    if (!sortMetaIndo || sortMetaIndo.length === 0) {
      throw new console.error('Atleast one sort field must be provided');
    }
    const sortableFields = typeof (sortMetaIndo[0]) === 'string' ? sortMetaIndo as Array<string> :
      (sortMetaIndo as Array<SortMeta>).map((sortItem: SortMeta) => sortItem.order === 1 ? sortItem.field : '-' + sortItem.field);
    this.sort(fieldSorter(sortableFields));
  };
}

if (!Array.prototype.singleSort) {
  Array.prototype.singleSort = function <T>(this: Array<T>, sortOrder: number) {
    this.sort((one, two) => (one > two ? (1 * sortOrder) : (-1 * sortOrder)));
  };
}

if (!Array.prototype.distinct) {
  Array.prototype.distinct = function <T>(this: Array<T>, keys?: string[]): T[] {
    if (!keys) {
      return [...new Set(this)]
    }

    const seen = [];
    return this.filter(o => {
      const key = keys.map(k => o[k]).join('|');
      if (seen.indexOf(key) === -1) {
        seen.push(key);
        return true;
      }
      return false;
    });
  };
}

if (!Array.prototype.clone) {
  Array.prototype.clone = function <T>(this: Array<T>): T[] {
    const serialized = JSON.stringify(this);
    return JSON.parse(serialized) as T[];
  };
}

declare global {
  interface Array<T> {
    groupBy(this: Array<T>, keys: string[]): Group<T>[];
    multiSort(this: Array<T>, sortFields: SortMeta[] | string[]);
    singleSort(this: Array<T>, sortOrder: number);
    distinct(this: Array<T>, keys?: string[]): T[];
    clone(this: Array<T>): T[];
  }
}

export { };
