import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DopSessionRevocationService } from './dop-session-revocation.service';

@NgModule({
  imports: [BrowserModule, HttpClientModule],
  providers: [DopSessionRevocationService],
})
export class DopSessionRevocationModule {
  static forRoot(): ModuleWithProviders<DopSessionRevocationModule> {
    return {
      ngModule: DopSessionRevocationModule,
      providers: [],
    };
  }
}
