import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditResultViewModel {
  code: string;
  name: string;
  default: boolean;
  fieldConfig: any;
  accessType: string;
  selectedViewCodeToClone: string;
}
