import { GridColumn } from './../../../shared/models/gridColumn.model';
import { TextContentModel, DateRangeContentModel, TimeRangeContentModel, BooleanListContentModel, NumberRangeContentModel} from './../resultView/resultgrid/grid-filter/tab.model';
import { GridTemplate } from './../../../core/enums/grid-template';
import { Configuration, ViewSetting, ViewConfiguration, ViewAccess } from './models/result-viewconfiguration.model';
import { FilterDefinition } from './models/filter-definition.model';
import { Definitions } from './models/definitions';
import { StoreService } from './../../../core/store/ngrx-store.service';
import { SortCapability, ListFilterCapability, DateFilterCapability, RollingDateFilterCapability, FieldDescription, TimeFilterCapability, BooleanFilterCapability, NumberFilterCapability, OpenSearchCapability, FieldGroup } from './models/field-definition.model';
import { Pagination, Search, SearchRequest } from './models/search-result.model';
import { ColumnCapability, SortOrder } from './../../../core/enums/column-capability';
import { FilterMetadata, LazyLoadEvent, SortMeta } from 'primeng/api';
import { Injectable } from '@angular/core';
import { StoreKey } from 'src/app/core/store/ngrx-store.keys';
import { EventService } from '../../../core/services/event.service';
import { EventTypes } from '../../../core/enums/event.types';
import { EventUpdateSeletedField } from './models/eventUpdateSeletedField.model';
import { DateRange } from 'src/app/core/enums/date-ranges';

@Injectable({
  providedIn: 'root'
})
export class FieldConfigurationService {
  isFieldDefinitionsLoaded: boolean;
  private viewConfiguration: ViewConfiguration = new ViewConfiguration();
  public fieldDescription: FieldDescription[] = [];
  private searchRequest: SearchRequest;
  private filterColumns: FilterDefinition[];
  private fieldGroups: FieldGroup[];
  public savedWidth: { [key: string]: { [key: string]: any }; } = {};
  public columnCapabilites: Set<string>;
  settings: any;
  constructor(
    private storeService: StoreService,
    private eventService: EventService
  ) { 
    this.columnCapabilites = new Set([
      ColumnCapability.ListTextFilter,
      ColumnCapability.RollingDateFilter,
      ColumnCapability.DateFilter, 
      ColumnCapability.Search,
      ColumnCapability.OpenSearch,
      ColumnCapability.TimeFilter,
      ColumnCapability.NumberFilter, 
      ColumnCapability.ListBoolFilter
    ])
  }

  private mapGridColumns(fieldDesc: FieldDescription[], isCustom: boolean): GridColumn[] {
    const gridColumns = [];
    fieldDesc.filter(f => !f.capabilities || !f.capabilities[ColumnCapability.ExportOnly])
      .forEach((item: FieldDescription) => {
        if (isCustom || !this.viewConfiguration.configuration.fields[item.code].exclude) {
          const column = new GridColumn();
          column.field = item.code;
          column.header = item.name;
          column.type = item.type;
          column.subType = item.subtype;
          column.domain = item.domain;
          column.isDataTranslatable = item.isDataTranslatable;
          column.sort = item?.capabilities?.hasOwnProperty(ColumnCapability.Sort);
          column.filter = item?.capabilities?.hasOwnProperty(ColumnCapability.Filter);
          column.displayTooltip = item?.capabilities?.hasOwnProperty(ColumnCapability.Tooltip);
          column.isAFColumm = item.code.startsWith('AF_') ? true : false;
          column.isResultValueColumn = (item.code == 'ResultValue') ? true : false;

          const viewSetting = this.viewConfiguration?.configuration?.view;
          if (!isCustom && viewSetting && viewSetting.display && viewSetting.display[item.code]) {
            column.displayCapability = viewSetting.hasOwnProperty(ColumnCapability.Display);
            column.width = viewSetting?.display[item.code].width;
          }
          else {
            column.displayCapability = item?.capabilities?.hasOwnProperty(ColumnCapability.Display);
            column.width = item?.capabilities?.display.width;
          }

          const selectedFields = this.viewConfiguration?.configuration?.fields;
          if (!isCustom && column.sort && selectedFields && selectedFields[item.code]?.sort && item?.capabilities) {
            column.sortCapability =
              new SortCapability(selectedFields[item.code].sort.direction, selectedFields[item.code].sort.level);
          }
          gridColumns.push(column);
        }
      });
    return gridColumns;
  }

  private mapFields(event: LazyLoadEvent, viewSetting?: any) {
    this.viewConfiguration.configuration = this.viewConfiguration.configuration ?
      this.viewConfiguration.configuration : new Configuration();

      //set result view settings (display settings)
    this.getViewSettings(viewSetting);
    if (!this.filterColumns) {
      this.storeService.Get(StoreKey.Definitions).subscribe((definitions: Definitions) => {
        this.filterColumns = definitions.filters;
        this.fieldGroups = definitions.groups;
        this.mapFieldsConfigurations(event, this.settings);
      });
    }
    else {
      this.mapFieldsConfigurations(event, this.settings);
    }
    this.cleanViewConfiguration();
    this.reorderSelectedItems();
    this.transmitFieldConfigurationEvent();
  }

  private getViewSettings(viewSetting?: any) {
    const displaySettings = this.viewConfiguration?.configuration?.view?.display;    

    if (viewSetting != null && viewSetting != undefined) {
      if (Object.keys(viewSetting).filter(x => x).length >= 1 && displaySettings != null &&
      Object.keys(displaySettings).filter(x => x).length >= 1)
      {
        this.settings = Object.assign(displaySettings, viewSetting);
      }
      else {
        this.settings = (Object.keys(viewSetting).filter(x => x).length >= 1) ?
          viewSetting : displaySettings;
      }
    }
    else {
      this.settings = displaySettings;
    }
  }

  private transmitFieldConfigurationEvent() {
    this.eventService.broadCast(EventTypes.FieldConfigurationsChanged, this.viewConfiguration);
  }

  private reorderSelectedItems(gridColumnData?: EventUpdateSeletedField) {
    const fieldsSort = {};

    const fieldDescriptionSort = gridColumnData && gridColumnData.length > 0 ?
      this.fieldDescription.sort((a, b) => { return gridColumnData.columns[a.code] - gridColumnData.columns[b.code] }) : null;

    this.fieldDescription.forEach(item => {
      if (this.viewConfiguration.configuration.fields.hasOwnProperty(item.code)) {
        fieldsSort[item.code] = this.viewConfiguration.configuration.fields[item.code];
      }
    });

    Object.getOwnPropertyNames(this.viewConfiguration.configuration.fields)
      .filter(field => Object.getOwnPropertyNames(fieldsSort).indexOf(field) === -1)
      .forEach(code => {
        fieldsSort[code] = this.viewConfiguration.configuration.fields[code];
      });

    this.viewConfiguration.configuration.fields = fieldsSort;
  }

  updateFieldConfiguration(gridColumnData: EventUpdateSeletedField) {
    this.reorderSelectedItems(gridColumnData);
    this.transmitFieldConfigurationEvent();
  }

  private cleanViewConfiguration() {
    const fieldCodes = Object.getOwnPropertyNames(this.viewConfiguration.configuration.fields);
    fieldCodes.forEach(code => {
      if (!this.fieldDescription.some(x => x.code === code) && this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.Search)) {
        delete this.viewConfiguration.configuration.fields[code][ColumnCapability.Search];
      }

      if (!this.fieldDescription.some(x => x.code === code)
        && !this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.Exclude)
        && !this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.RollingDateFilter)
        && !this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.DateFilter)
        && !this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.ListTextFilter)
        && !this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.OpenSearch)) {
        delete this.viewConfiguration.configuration.fields[code];
      }
    });
  }

  private mapFieldsConfigurations(event: LazyLoadEvent, viewSetting?: any) {
    this.fieldDescription.forEach((item: FieldDescription) => {
      const capability: { [key: string]: any } = this.viewConfiguration.configuration.fields[item.code] ?? {};
      if (item.capabilities.hasOwnProperty(ColumnCapability.Sort)) {
        if (event?.multiSortMeta?.some(s => s.field === item.code)) {
          let dir: string;
          let level: number;
          event.multiSortMeta.forEach((sort: SortMeta, index: number) => {
            if (sort.field === item.code) {
              dir = sort.order === 1 ? SortOrder.Ascending : SortOrder.Descending;
              level = index;
            }
          });
          const sortCapability = new SortCapability(dir, level);
          capability[ColumnCapability.Sort] = sortCapability;
        } else {
          if (event?.multiSortMeta && capability.hasOwnProperty(ColumnCapability.Sort)) {
            delete capability[ColumnCapability.Sort];
          }
        }
      }
      if (capability.hasOwnProperty(ColumnCapability.Exclude) && (item.isHidden !== undefined && !item.isHidden)) {
        delete capability[ColumnCapability.Exclude];
      }

      if (item.capabilities.hasOwnProperty(ColumnCapability.Filter) && event?.filters) {
        if (item.code in event?.filters) {
          const filter = event.filters[item.code];
          this.addFilterCapability(filter, capability, viewSetting, item.code);
        }
      }

      if (item.capabilities.hasOwnProperty(ColumnCapability.Search) && event && event?.globalFilter !== null) {
        capability[ColumnCapability.Search] = true;        
      }

      if (item.capabilities.hasOwnProperty(ColumnCapability.ExportOnly)) {
        capability[ColumnCapability.ExportOnly] = true;
      }
      if (item.capabilities.hasOwnProperty(ColumnCapability.OpenSearch)) {
        capability[ColumnCapability.OpenSearch] = true;
      }
      this.viewConfiguration.configuration.fields[item.code] = capability;
    });

    // add or remove or modify filter capability and exlude capability based on filter selected
    this.filterColumns.forEach(filterColumn => {
      const capability: { [key: string]: any } = this.viewConfiguration.configuration.fields[filterColumn.reference] ?? {};
      if (event?.filters) {
        if (filterColumn.reference in event?.filters) {
          const filter = event.filters[filterColumn.reference];
          this.addFilterCapability(filter, capability, viewSetting, filterColumn.code);
          if (!this.fieldDescription.some(x => x.code === filterColumn.reference)
            || this.fieldDescription.some(x => x.code === filterColumn.reference && x.isHidden)) {
            capability[ColumnCapability.Exclude] = true;
          }
          this.viewConfiguration.configuration.fields[filterColumn.reference] = capability;
        } else {
          const filterType: string = this.hasFilterCapability(filterColumn.reference);
          if (this.viewConfiguration.configuration.fields[filterColumn.reference] && filterType) {
            if (this.viewConfiguration.configuration.fields[filterColumn.reference][ColumnCapability.Exclude]) {
              // Exclude is true means field is added for filter purpose, hence Remove the field.
              delete this.viewConfiguration.configuration.fields[filterColumn.reference];
            } else {
              // Remove only filter
              delete this.viewConfiguration.configuration.fields[filterColumn.reference][filterType];
            }
          }
        }
      }
    });
    if (!this.viewConfiguration.configuration.view) {
      this.viewConfiguration.configuration.view = new ViewSetting(GridTemplate.None, 'ReceptionDate', viewSetting);
    }
  }

  private hasFilterCapability(code: string): string {
    if (!this.viewConfiguration.configuration.fields[code]) {
      return null;
    }

    if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.RollingDateFilter)) {
      return ColumnCapability.RollingDateFilter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.DateFilter)) {
      return ColumnCapability.DateFilter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.ListTextFilter)) {
      return ColumnCapability.ListTextFilter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.Filter)) {
      return ColumnCapability.Filter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.ListBoolFilter)) {
      return ColumnCapability.ListBoolFilter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.TimeFilter)) {
      return ColumnCapability.TimeFilter;
    } else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.NumberFilter)) {
      return ColumnCapability.NumberFilter;
    }else if (this.viewConfiguration.configuration.fields[code].hasOwnProperty(ColumnCapability.OpenSearch)) {
      return ColumnCapability.OpenSearch;
    } else {
      return null;
    }
  }

  private addFilterCapability(filter: FilterMetadata, capability: { [key: string]: any }, viewSetting: any, filterColumnCode: string) {
    if (filter.value instanceof TextContentModel && filter.value.selectedValues.length > 0) {
      const listFilterCapability = new ListFilterCapability(filter.value.selectedValues.map(x => x.code), filter.value?.level ?? 0);
      capability[ColumnCapability.ListTextFilter] = listFilterCapability;
      delete capability[ColumnCapability.OpenSearch];
    }
    //Each text Tab will either have selected values or string Filter text
    else if(typeof(filter.value) == 'string'  && filter.value !='' && filter.value!=undefined)
    {
      const openSearchCapability = new OpenSearchCapability(filter.value, 0);
      capability[ColumnCapability.OpenSearch] = openSearchCapability;
      delete capability[ColumnCapability.ListTextFilter];
      const groupWithCapability = this.fieldGroups.find(group =>
        group.fields.some(a =>
          a.code.toLowerCase() === filterColumnCode.toLowerCase() &&
          a.capabilities[ColumnCapability.Filter]?.searchMode === 'contains'
        )
      );

      if (groupWithCapability) {
        openSearchCapability.operation = 'contains';
        capability[ColumnCapability.OpenSearch] = openSearchCapability;
      }
    }
    else if (filter.value instanceof DateRangeContentModel) {
      delete capability[ColumnCapability.DateFilter];
      delete capability[ColumnCapability.RollingDateFilter];
      if (filter.value.isDateRange) {
        this.viewConfiguration.configuration.view = new ViewSetting(this.viewConfiguration.configuration.view.template, filter.value.type, viewSetting);
      }
      if (filter.value.value && filter.value.value !== '' && filter.value.value !== DateRange.CustomRange) {
        let value = '';
        if(filter.value.value === DateRange.Current || filter.value.value === DateRange.Previous) {
          value = filter.value.value;
        } else {
          value = filter.value.textValue !== "" && filter.value.textValue !== undefined ? filter.value.textValue : filter.value.value;
        }
        const rollingFilterCapability = new RollingDateFilterCapability(value, filter.value?.level ?? 0, filter.value?.unit);
        capability[ColumnCapability.RollingDateFilter] = rollingFilterCapability;
      } 
      else if (filter.value.Dates?.length > 0) {
        const dateFilterCapability = new DateFilterCapability(filter.value.Dates[0], filter.value.Dates[1], filter.value?.level ?? 0);
        capability[ColumnCapability.DateFilter] = dateFilterCapability;
      }
    }
    else if (filter.value instanceof TimeRangeContentModel) {
      const timeFilterCapability = new TimeFilterCapability(filter.value.times[0], filter.value.times[1], filter.value?.level ?? 0);
      capability[ColumnCapability.TimeFilter] = timeFilterCapability;
    }
    else if (filter.value instanceof BooleanListContentModel) {
      const booleanFilterCapability = new BooleanFilterCapability(filter.value.selectedValues.map(x => x.code as boolean), filter.value?.level ?? 0);
      capability[ColumnCapability.ListBoolFilter] = booleanFilterCapability;
    }
    else if (filter.value instanceof NumberRangeContentModel) {
      const numberFilterCapability = new NumberFilterCapability(filter.value.selectedValues[0], filter.value.selectedValues[1], filter.value?.level ?? 0);
      capability[ColumnCapability.NumberFilter] = numberFilterCapability;
    }
  }

  GetSelectedFilters(search: Search): any {
    const list: { [key: string]: any } = {};
    Object.getOwnPropertyNames(this.viewConfiguration.configuration.fields).forEach(item => {
      const capablityist = Object.getOwnPropertyNames(this.viewConfiguration.configuration.fields[item]);
      if (capablityist.length > 0) {
        capablityist.forEach(element => {
          if (this.columnCapabilites.has(element)) {
            list[item] = Object.assign({}, this.viewConfiguration.configuration.fields[item]);
            (list[item] as { [key: string]: any })[ColumnCapability.Exclude] = true;

            if (element === ColumnCapability.Search && search.text) {
              (list[item] as { [key: string]: any })[ColumnCapability.Search] = true;
            }
            delete list[item][ColumnCapability.Sort];
          }
        });
      }
    });
    return list;
  }
  private deleteExporOnly(field: any) {
    if (field) {
      if (field.capabilities && field.capabilities[ColumnCapability.ExportOnly]) {
        delete field.capabilities[ColumnCapability.ExportOnly];
      } else if (field[ColumnCapability.ExportOnly]) {
        delete field[ColumnCapability.ExportOnly];
      }
    }
  }

  setViewConfiguration(
    viewConfiguration: ViewConfiguration,
    fieldDescription?: FieldDescription[],
    event?: LazyLoadEvent,
    viewSetting?: any,
    viewAccess?: ViewAccess,
    exportOnlyFileds?: string[]) {

    if (exportOnlyFileds && fieldDescription) {
      //reset if any item not selected
      if (viewConfiguration.configuration) {
        Object.getOwnPropertyNames(viewConfiguration.configuration.fields).forEach(se => {
          let field = viewConfiguration.configuration.fields[se];
          this.deleteExporOnly(field);

          if (fieldDescription) {
            let fieldDes = fieldDescription.find(x => x.code === se);
            this.deleteExporOnly(fieldDes);
          }
        });
      }

      exportOnlyFileds.forEach(ef => {
        const fieldDes = fieldDescription.find(x => x.code === ef);
        if (fieldDes) {
          const capability: { [key: string]: any } = {};
          Object.getOwnPropertyNames(fieldDes.capabilities).forEach(c => capability[c] = fieldDes.capabilities[c]);

          capability[ColumnCapability.ExportOnly] = true;
          fieldDes.capabilities = capability;
        }
      });
    }

    this.viewConfiguration = viewConfiguration;
    if (fieldDescription) {
      this.fieldDescription = fieldDescription;
    }

    if (viewAccess) {
      this.viewConfiguration.access = viewAccess;
    }

    if (fieldDescription || event) {
      this.mapFields(event, viewSetting);
    }
  }

  getViewConfiguration(): ViewConfiguration {
    return this.viewConfiguration;
  }

  getColumnConfiguration(fieldDescription?: FieldDescription[], customFieldDesc?: FieldDescription[]): GridColumn[] {
    if (fieldDescription) {
      const fields = Object.keys(this.viewConfiguration.configuration.fields);
      let fdesc: FieldDescription[] = [];
      fieldDescription.filter(x => fields.some(f => f === x.code)).sort((a, b) => {
        return fields.indexOf(a.code) - fields.indexOf(b.code);
      }).forEach(fieldDes => {
        if (this.viewConfiguration.configuration.fields[fieldDes.code][ColumnCapability.ExportOnly] ||
          (this.viewConfiguration.configuration.fields[fieldDes.code].capabilities &&
            this.viewConfiguration.configuration.fields[fieldDes.code].capabilities[ColumnCapability.ExportOnly])) {

          const capability: { [key: string]: any } = {};
          Object.getOwnPropertyNames(fieldDes.capabilities).forEach(c => capability[c] = fieldDes.capabilities[c]);
          capability[ColumnCapability.ExportOnly] = true;
          fieldDes = { ...fieldDes, capabilities: capability };
        }
        fdesc.push(fieldDes);
      });

      this.fieldDescription = fdesc
    }
    return this.mapGridColumns(customFieldDesc ?? this.fieldDescription, customFieldDesc !== undefined);
  }

  getFields(event: LazyLoadEvent, includeCount: boolean, paginationState: Pagination): SearchRequest {
    this.mapFields(event);
    this.searchRequest = new SearchRequest(paginationState.offset ?? 0, paginationState.size, includeCount, event.filters.global);
    this.searchRequest.fields = this.viewConfiguration.configuration.fields;
    return this.searchRequest;
  }
}
