
export class FieldGroup {
  code: string;
  name: string;
  fields: FieldDescription[];
}

export class FieldDescription {
  constructor(
    public code: string,
    public name: string,
    public type: string,
    public values: any,
    public capabilities: { [key: string]: any },
    public isHidden?: boolean,
    public domain?: string,
    public subtype?: string,
    public isDataTranslatable?: boolean,
  ) {
  }
}

export abstract class ICapability {
  constructor(level: number) {
    this.level = level;
  }
  level: number;
}
export class SortCapability extends ICapability {
  constructor(direction: string, level: number) {
    super(level);
    this.direction = direction;
  }
  direction: string;
}
export class ListFilterCapability extends ICapability {
  constructor(values: any[], level: number) {
    super(level);
    this.values = values;
  }
  values: any[];
}

export class OpenSearchCapability extends ICapability {
  constructor(text: string, level: number, operation?: string) {
    super(level);
    this.text = text;
    this.operation = operation;
  }
  text: string;
  operation?: string;
}

export class TextFilterCapability extends ICapability {
  constructor(public operation: string, public text: string, level: number) {
    super(level);
  }
}

export class RollingDateFilterCapability extends ICapability {
  constructor(value: string, level: number, unit?: string) {
    super(level);
    this.value = value;
    if (unit !== undefined) {
      this.unit = unit;
    }
  }
  value: string;
  unit?: string;
}
export class DateFilterCapability extends ICapability {
  constructor(from: Date, until: Date, level: number) {
    super(level);
    this.from = from;
    this.until = until;
  }
  from: Date;
  until: Date;
}

export class TimeFilterCapability extends ICapability {
  constructor(from: string, until: string, level: number) {
    super(level);
    this.from = from;
    this.until = until;
  }
  from: string;
  until: string;
}

export class BooleanFilterCapability extends ICapability {
  constructor(values: boolean[], level: number) {
    super(level);
    this.values = values;
  }
  values: any[];
}

export class NumberFilterCapability extends ICapability {
  constructor(from: number, until: number, level: number) {
    super(level);
    this.from = from;
    this.until = until;
  }
  from: number;
  until: number;
}

export class FieldConfiguration {
  constructor() {
    this.fields = {};
  }
  fields: { [key: string]: { [key: string]: any } };
}

