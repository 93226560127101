export class PicklistTranslationModel {
  availableFieldsTitle = '';
  addAllTitle = '';
  removeAllTitle = '';
  selectedItemsTitle = '';
  searchBackgroundText?= '';
  searchTextNote?: string;
  displaySelectionBoxTitle?= '';
  displaySelectionBoxNote?= '';
  selectAll?= '';
  search='';
}
