import { Observable, Subject } from 'rxjs';
import { EventTypes } from '../../core/enums/event.types';

export class EventModel {
  Type: EventTypes;
  Data?: EventBaseData;
}

export abstract class EventBaseData {
  constructor(public isRead?: boolean) { }
  source: string;
  private readonly _onAction = new Subject<any>();
  
  onAction: Observable<any> = this._onAction.asObservable();

  execute = (result?: any) => {
    this._onAction.next(result);

    setTimeout(() => {
      this._onAction.complete();
    }, 1000);
  }
}
