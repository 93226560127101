export class UserProfileModel {
  id: string;
  name: string;
  internal: boolean;
  language: string;
  instances: string[];
  accounts?: string[];
  trackingConsent : boolean;
  unknownProfile = false;
  policiesAccepted: boolean;
  analyticsEnabled: boolean;
  canManagePublicViews = false;
}
