import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventModel, EventBaseData } from '../../shared/models/event.model';
import { EventTypes } from '../enums/event.types';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private $event: BehaviorSubject<EventModel> = new BehaviorSubject<EventModel>(null);

  public broadCast(eventType: EventTypes, data?: EventBaseData): void {
    this.$event.next({ Type: eventType, Data: data });
  }

  public tryListen(eventType: EventTypes, data?: EventBaseData): Observable<any> {
    this.$event.next({ Type: eventType, Data: data });
    return data.onAction;
  }

  public listen(eventType: EventTypes): Observable<EventBaseData> {
    return this.$event.asObservable()
      .pipe(
        filter(e => e !== null && e.Type === eventType),
        map(e => {
          var res = Object.assign({}, e.Data);
          if (e.Data) {
            e.Data.isRead = true;
          }
          return res;
        })
      );
  }
}
