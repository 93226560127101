import { AppConfigService } from '../../core/services/app-config-service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../core/services/auth.service';
import { StoreService, StoreKeys } from 'src/app/core/store/ngrx-store.service';
import { EventService } from '../../core/services/event.service';
import { EventTypes } from '../../core/enums/event.types';
import { ConfigurationService } from '../../core/services/configuration-service';
import { TranslateService } from '@ngx-translate/core';
import { EventBuildNewResults } from '../../features/results/shared/models/eventBuildNewResults.model';
import { UserProfileModel } from '../../shared/models/user-profile.model';
import { RouteConstants } from '../global-constants/route-constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppRoute } from '../../shared/models/app-route.enum';
import { Layout } from 'src/app/shared/models/layout.model';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  navItems: MenuItem[];
  userMenuItems: MenuItem[];
  isReportManagementEnabled: boolean;
  userOperations: string[];
  userName = '';
  menuItems = [];
  activeRoute = -1;
  appRoute = AppRoute;
  layout: Layout = {
    pageHeader: {
      pageTitle: '',
      pageIcon: '',
      iconLink: '',
      confirmDialogRequired: false,
      pullDownItems: []
    },
    breadcrumbItems: []
  } as Layout;
  displayTemplate: boolean;

  constructor(
    private readonly appConfigService: AppConfigService,
    private router: Router,
    private authService: AuthService,
    private storeService: StoreService,
    private eventService: EventService,
    private configurationService: ConfigurationService,
    private translate: TranslateService,
    private layoutService: LayoutService) {
    this.eventService.listen(EventTypes.ConfigurationsLoaded).subscribe(() => {
      this.setNavigationMenus();
    });
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (event.url.indexOf('impersonate') !== -1) {
        this.activeRoute = this.appRoute.Impersonate;
      }
      this.setNavigationMenus();
    });
  }

  async ngOnInit() {
    this.getUserName();
    this.configurationService.isConfigurationsLoaded$.subscribe((res: boolean) => {
      if (res) {
        this.setNavigationMenus();
      }
    });
    this.layoutService.layoutChanges.subscribe((layout: Layout) => {
      this.layout = layout;
      this.displayTemplate = false;
    });
  }

  getUserName() {
    this.authService.isUserDataLoaded$.subscribe(_ => {
      this.storeService.Get(StoreKeys.UserDetails).subscribe((data: UserProfileModel) => {
        this.userName = data.name;
      });
    });
  }

  getSupportUri() {
    if (this.menuItems.some(item => item.code === 'eolSupportAdvanced')) {
      this.menuItems.filter(item => item.code === 'eolSupportAdvanced')
        .forEach(item => {
          this.redirectTo(item);
        });
    } else {
      this.menuItems.filter((item) => item.code === 'eolSupport')
        .forEach((item) => {
          this.redirectTo(item);
        });
    }
  }

  redirectTo(item) {
    this.router.navigate(['/']).then((result) => {
      if (result != false) {
        window.location.href = item.navigationUri;
      }
    });
  }

  logout(): void {
    this.router.navigate(['/']).then((result) => {
      if (result != false) {
        this.authService.logout();
      }
    });
  }

  openNewResultViewModal() {
    const eventData = new EventBuildNewResults();
    eventData.isEdit = false;
    eventData.isPreview = true;
    this.eventService.broadCast(EventTypes.BuildNewResultView, eventData);
  }

  getEolHomePage() {
    this.menuItems.filter(item => item.code === 'eolHome')
      .forEach((item) => {
        this.redirectTo(item);
      });
  }

  private setNavigationMenus() {
    this.navItems = [];
    this.menuItems = this.configurationService.menuItems;
    if (this.configurationService.configurations.useResults) {
      this.navItems = [
        {
          label: this.translate.instant('eiResults'),
          icon: 'ei ei-clipboard-text',
          expanded: true,
          styleClass: 'new-tag',
          items: [
            {
              label: this.translate.instant('eiMyResults'),
              icon: 'ei ei-clipboard-text',
              routerLink: `/${RouteConstants.ResultsHomePath}`
            },
            {
              label: this.translate.instant('eiManageResultViews'),
              icon: 'ei ei-library ei-26',
              routerLink: `/${RouteConstants.ManageResultViewPath}`
            },
            {
              label: this.translate.instant('eiBuildNewResultView'),
              icon: 'ei ei-plus text-primary ei-2x',
              command: () => this.openNewResultViewModal()
            },
          ],
        },
      ];

      if (this.configurationService.enableGraphsAndCharts && this.navItems.length > 0) {
        this.navItems[0].items.push({
          label: this.translate.instant('eiGraphsAndCharts'),
          icon: 'ei ei-graph ei-26',
          routerLink: `/${RouteConstants.GraphsAndChartsPath}`
        });
      }
    }

    const documentsMenuItem = this.menuItems.find(i => i.code === 'documents');
    if (documentsMenuItem) {
      this.navItems.push({
        label: this.translate.instant('eiPortfolio'),
        icon: 'ei ei-documents',
        styleClass: 'new-tag',
        command: () => {
          this.router.navigate(['/']).then((result) => {
            if (result != false) {
              window.location.href = documentsMenuItem.navigationUri;
            }
          });
        },
      });
    }

    this.navItems.push({ separator: true });

    if (this.authService.isImpersonatedUser()) {
      this.navItems.push({
        label: this.translate.instant('eiStopImpersonate'),
        icon: 'ei ei-theater ei-26',
        url: `${this.appConfigService.EolBaseUrl}stopimpersonate`,
        target: '_self'
      })
    }

    this.navItems = this.navItems.concat([
      {
        label: this.translate.instant('eiSupport'),
        icon: 'ei ei-bubble-question ei-26',
        command: () => this.getSupportUri()
      },
      {
        label: this.userName, icon: 'ei ei-user ei-26',
        items: [{
          label: this.translate.instant('eiLogoff'),
          icon: 'ei ei-exit ei-26',
          command: () => this.logout()
        }]
      }
    ]);
  }
  
}
