<div class="container">
  <div class="one-row col-lg">
    <label for="numberStart">{{'eiFrom'|translate}}</label>
    <p-inputNumber #minEle [(ngModel)]="min" inputId="numberStart" mode="decimal" [useGrouping]="false" (onBlur)="onBlurMinBox(minEle)">
    </p-inputNumber>
  </div>
  <div class="one-row col-sm">-</div>
  <div class="one-row col-lg">
    <label for="numberEnd">{{'eiTo'|translate}}</label>
    <p-inputNumber #maxEle [(ngModel)]="max" inputId="numberEnd" mode="decimal" [useGrouping]="false" (onBlur)="onBlurMaxBox(maxEle)">
    </p-inputNumber>
  </div>
</div>
