import { NgModule } from '@angular/core';

import { DopTooltipDirective } from './dop-tooltip.directive';



@NgModule({
  declarations: [DopTooltipDirective],
  imports: [
  ],
  exports: [DopTooltipDirective]
})
export class DopTooltipModule { }
