import { Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config-service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { ConfigurationModel } from '../../shared/models/configuration.model';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { SystemData } from '../../shared/models/system-data.model';
import { HttpStatusCode } from '../enums/HttpStatusCode';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    private appConfigService: AppConfigService,
    private dataService: DataService) {
  }

  isConfigurationsLoaded$ = new BehaviorSubject<boolean>(false);
  enableGraphsAndCharts: boolean;
  preconditionFailed:boolean;

  configurations: ConfigurationModel = new ConfigurationModel();
  menuItems: any[] = [];

  IsAdvancedSupportEnabled() {
    const apiUrl = 'configuration/advancedSupportEnabled';
    return this.getData<any>(apiUrl)
      .pipe(
        map((value: any) => {
          return value;
        }), catchError(error => this.errorHandl(error)));
  }
  getConfigurations() {
    const apiUrl = 'configuration';
    return this.getData<any>(apiUrl)
      .pipe(
        map((value: any) => {
          return {
            showAggregatedColumnsinDownload: value.data.useResultsAggregation,
            useSpecificExport: value.data.useSpecificExport,
            useComplianceAssessment: value.data.useComplianceAssessment,
            enableGraphsAndCharts: value.data.enableGraphsAndCharts,
            enableParameterTrend: value.data.enableParameterTrend,
            enableResultsConformity: value.data.enableResultsConformity,
            enableReceivedSampleStatus: value.data.enableReceivedSampleStatus,
            uiStartDate: value.data.uiStartDate
          };
        }), catchError(error => this.errorHandl(error)));
  }

  getSystemInitialData(lang: string): Observable<SystemData | { [key: string]: any }> {
    const api = `${this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl}system/workspace`;
    return this.dataService.get(api, true).pipe(
      map((res: SystemData) => {
        this.setInfo(res);

        return res;
      }), catchError((err: any) => {
        // On Error get translation from local
        if (err.status === HttpStatusCode.FORBIDDEN 
          || err.status === HttpStatusCode.PRECONDITION_FAILED 
          || err.status === HttpStatusCode.INTERNAL_SERVER_ERROR || err.status === 0) {
          console.log(err.message);
          this.setInfo(err.error, err.status, lang);
          if (err?.error?.translationDetail?.translations){
            return of(err.error);
          }
          return this.dataService.get<{ [key: string]: any }>(`assets/translations/${lang}.json`, true);
        } else {
          return this.dataService.get<{ [key: string]: any }>(`assets/translations/${lang}.json`, true);
        }
      }));
  }

  private setInfo(res: SystemData, status = 200, browserLanguage?: string) {
    if (status != 200 && !res?.menuItems) {
      this.configurations.useResults = false;
      this.preconditionFailed = status === HttpStatusCode.PRECONDITION_FAILED 
                                || status === HttpStatusCode.INTERNAL_SERVER_ERROR;
      console.log('pre-condition failed', this.preconditionFailed);
      return;
    }
    this.appConfigService.userLanguageCode = res.translationDetail.languageCode ?? browserLanguage;

    this.appConfigService.formatCulture = res.translationDetail.formatLanguageCode ?? browserLanguage;
    this.appConfigService.setCurrentCulture(this.appConfigService.formatCulture);

    this.configurations.useResults = res.canAccessResults;
    this.menuItems = res.menuItems;
  }

  // Error handling
  errorHandl(error) {
    this.configurations.useResults = false;
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => error);
  }

  private getData<T>(apiUrl: string): Observable<T> {
    const url = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + apiUrl;
    return this.dataService.get<T>(url, true);
  }
}
