import { formatDate, FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateService } from './date.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor(private dateService: DateService, @Inject(LOCALE_ID) private locale: string) { }

  transform(value: Date | string | number | any, format?: string, timezone?: string, locale?: string): string
    | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | any, format?: string, locale?: string, timezone?: string): string {
    if (value == null || value === '' || value !== value) {
      return null;
    }

    let customFormat: string = null;
    if (format === 'shortDate') {
      customFormat = this.dateService.getCustomLocaleDateFormat(FormatWidth.Short);
    } else if (format === 'short') {
      customFormat = this.dateService.getCustomLocaleDateTimeFormat(FormatWidth.Short);
    } else if (format === 'shortTime') {
      customFormat = this.dateService.getCustomLocalTimeFormat(FormatWidth.Short);
    } 

    try {

      return formatDate(value, customFormat ?? format, locale || this.locale, timezone);

    } catch (error) {
      throw this.invalidPipeArgumentError(error.message);
    }

  }

  invalidPipeArgumentError(message: any) {
    console.log(message);
  }
}
