import { NgModule } from '@angular/core';
import { ManageResultViewsComponent } from './manageResultViews.component';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { KebabMenuModule } from '../../../common/components/kebab-menu/kebab-menu.module';
import { AppConfirmDialogModule } from '../../../common/components/app-confirm-dialog/app-confirm-dialog.module';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { EnumTypeModule } from '../../../common/components/enum-type/enum-type.module';
import { DateTypeModule } from '../../../common/components/date-type/dateType.module';
import { DopTooltipModule } from 'libs/dop-tooltip/src/libs';

@NgModule({
  declarations: [ManageResultViewsComponent],
  imports: [
    ButtonModule,
    TableModule,
    KebabMenuModule,
    AppConfirmDialogModule,
    CommonModule,
    DialogModule,
    TranslateModule,
    EnumTypeModule,
    DateTypeModule,
    DopTooltipModule
  ],
  providers: [],
  exports: [
  ]
})
export class ManageResultViewsModule { }
