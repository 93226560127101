import { FilterDefinition, ListData, ListValue } from '../../../shared/models/filter-definition.model';
import { DynamicFilterOptions } from './dynamic-filter.model';

export class TabModel extends FilterDefinition {
  dynamicFilters: DynamicFilterOptions[];
  selectedOption?: DynamicFilterOptions;
  isStatic: boolean;
  isSelected: boolean;
  selectedContent: TabContentModel;
  searchContent: ListData[];
  filterTextToKeywordView : any;
  emptyResultsMessage : boolean = false;
  hasMoreResults : boolean = false;
  filterCategorySearchText: string ="";   
  selectedValueForInput?: any;
  showClear? = false;
  dateRangeTextValue: any;
  lastDateRangeMaxValueError: boolean;
  displayText?: string;
  filterOption?: string;
  fromDateEmpty? = false;
  toDateEmpty? = false;
}

export abstract class TabContentModel {
  level?: number;
}

export class DateRangeContentModel extends TabContentModel {
  type: string;
  value: string;
  isDateRange: boolean;
  Dates?: Date[] = [];
  label?: any;
  textValue?: any;
  unit?: string;
}

export class TextContentModel extends TabContentModel {
  selectedValues: ListValue[] = [];
  filterText: string;  
}

export class TimeRangeContentModel extends TabContentModel {
  times: string[] = [];
}

export class BooleanListContentModel extends TabContentModel {
  selectedValues: ListValue[] = [];
}

export class NumberRangeContentModel extends TabContentModel {
  selectedValues: number[] = [];
}
