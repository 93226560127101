export class RouteConstants {
  public static readonly ResultsHomePath = '';
  public static readonly ResultByViewCodePath = 'resultView/:code';
  public static readonly ManageResultViewPath = 'manageResultViews';
  public static readonly BuildNewResultViewPath = 'buildNewResultView';
  public static readonly ResultViewPreviewPath = 'resultViewPreview';
  public static readonly EditResultViewPath = 'editResultView';
  public static readonly ImpersonatePath = 'impersonate';
  public static readonly ImpersonateViewPath = '';
  public static readonly GraphsAndChartsPath = 'graphsAndCharts';
}

