export enum EventTypes {
  BuildNewResultView = 1,
  ConfigurationsLoaded = 2,
  UpdateDateFilterOption = 3,
  UpdateBackTabDateRangeOptions = 4,
  ClearChips = 5,
  DefaultFilterLoad = 6,
  RenderPageHeaderContent = 7,
  LoadView = 8,
  EditResultView = 9,
  FieldConfigurationsChanged = 10,
  DisplayNotification = 11,
  CompareSample = 12,
  DownloadExcel = 13,
  UpdateSelectedFields = 14,
  ServiceError = 15,
  SystemDataLoaded = 16,
  ExportJobCreated = 17,
  GlobalServiceError = 18
}
