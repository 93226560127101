import { EventBaseData } from '../../../../shared/models/event.model';

export class ViewConfiguration extends EventBaseData {
  code: string;
  name: string;
  default: boolean;
  configuration: Configuration;
  template: string;
  access: ViewAccess;
  isPreviewToSaveTransition: boolean;
}

export class ViewAccess {
  constructor() {
    this.items = [];
  }
  type: string;
  items: string[];
}

export class Configuration {
  constructor() {
    this.fields = {};
  }
  view: ViewSetting;
  fields: { [key: string]: { [key: string]: any } };
}

export class ViewSetting {
  constructor(template: string, dateRange: string, display?: any) {
    this.template = template;
    this.dateRange = dateRange;
    if (display) {
      this.display = display;
    }
  }
  template: string;
  dateRange: string;
  display?: { [key: string]: { [key: string]: any } };
}

export class DisplayModel {
  constructor(width: number) {
    this.width = width;
  }
  width: number;
}
export class Display extends DisplayModel {
  constructor(width: number, field: string) {
    super(width);
    this.field = field;
  }
  field: string;
}
