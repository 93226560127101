<div *ngIf="((canManagePublicViews && isInternal) || !isInternal)">
  <div *ngFor="let type of types; let i=index">
    <div>
      <p-radioButton name="assignType" [value]="type.code" [label]="type.label" [(ngModel)]="selectedType" class="left"
        [inputId]="type.code"></p-radioButton>
    </div>
  </div>
</div>
<div class="header-label" *ngIf="!canManagePublicViews && isInternal">
  {{'eiAssignSelectedAccounts' | translate}}
</div>
<div>
  <lib-dop-pick-list #accountSelector *ngIf="selectedType===selectAccountOption"
    [enableSearch]="true" [enableSelectedSearch]="true" [selectAllAssigned]="true" [dataSource]="searchAccounts"
    [clientSideLoad]="clientSideLoading" [selectionBoxHeight]="300" [selectionBoxWidth]="360"
    [libTranslations]="pickListTranslations" [(treeModel)]="treeAccounts" [(selectedItems)]="selectedItems"
    [enableVirtualScroll]="true" (selectedItemsChange)="onSelectedItemsChange($event)"></lib-dop-pick-list>
</div>