import { QuickAssessmentResponse, AssessmentSettingsResponse, SearchDataResponse, AttachmentResponse, AttachmentRequest } from './../shared/models/search-result.model';
import { FieldSearchResponse, ListValue } from '../shared/models/filter-definition.model';
import { SearchRequest, SearchResponse, SearchFieldResponse, SelectionRequest, FieldSearchRequest, AvailableExport, ExportSettings, ExportRequest, SampleAssessment } from '../shared/models/search-result.model';
import { AppConfigService } from './../../../core/services/app-config-service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from '../../../core/services/data.service';
import { HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ListData } from 'src/app/features/results/shared/models/filter-definition.model';
import { ExportConstants } from '../../../common/global-constants';
import { HttpStatusCode } from '../../../core/enums/HttpStatusCode';
import { EventTypes } from '../../../core/enums/event.types';
import { EventService } from '../../../core/services/event.service';
import { AssessmentSettingsRequest } from '../shared/models/bcast-models/assessment-settings-request';
import { ErrorEventModel } from './error-event.model';
import { SearchComplianceAssessment } from './../shared/models/bcast-models/perform-assessment';
import { ServiceMethod } from '../../../core/enums/service-method';
import { ToastData } from 'src/app/shared/models/message.model';
import { HandleTechnicalError, HandleTimeout } from '../../../common/global-constants/application-constants';
import { TestStatus } from 'src/app/core/enums/test-status';


@Injectable({
  providedIn: 'root',
})
export class ResultsSearchService {
  private responses: { [id: number]: Function } = {};

  constructor(
    private dataService: DataService,
    private appConfigService: AppConfigService,
    private translateService: TranslateService,
    private eventService: EventService
  ) {
    this.setFunctionalErrors();
  }

  initializeSearch() {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'warmup';
    this.dataService.head(url).subscribe();
  }

  SearchResults(request: SearchRequest): Observable<SearchDataResponse> {
    const response = new SearchResponse();
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results';
    return this.dataService.put<SearchRequest, SearchDataResponse>(url, request, true);
  }

  SearchResultsData(request: SearchRequest): Observable<SearchResponse> {
    const response = new SearchResponse();
    const headers = {};
    headers[HandleTimeout] = 'true';
    const headerInfo = new HttpHeaders(headers);
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/stream';
    return this.dataService.put<SearchRequest, any>(url, request, true, { headers: this.getHeader() }).pipe(
      map((value: any) => {
        const response = new SearchResponse();
        if (value && value.length > 0) {
          if (value[0].properties["Count"]) {
            response.count = value[0].properties["Count"];
          }
          response.data = value;
        }
        return response;
      })
    );
  }

  SampleAttachmentList(request:  SelectionRequest) : Observable<AttachmentResponse>
  {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/samples/reports-attachments';
    return this.dataService.put<SelectionRequest, AttachmentResponse>(url, request, true).pipe(
      map((value: AttachmentResponse) => {
        return value;
      })
    );
  }

  CompareSamplesData(request: SelectionRequest): Observable<any> {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'samples/compare/';
    return this.dataService.put<SelectionRequest, any>(url, request, true, { headers: this.getHeader() }).pipe(
      map((value: any) => {
        let cancelled = [];
        let nonCancelled = [];
        value.data.map(obj => {
          obj.references.map(ref => {
            if (ref.TestStatus?.toLowerCase() === TestStatus.Cancelled.toLowerCase()) {
              ref.ResultValue = this.translateService.instant(TestStatus.Cancelled);
              if (cancelled.filter(x => x.TestCode === ref.TestCode && x.ParameterName === ref.ParameterName)?.length === 0) {
                cancelled.push(ref);
              }
            } 
            else if (nonCancelled.filter(x => x.TestCode === ref.TestCode && x.ParameterName === ref.ParameterName)?.length === 0) {
                nonCancelled.push(ref);
            }
          });

          for (let i = 0; i < cancelled?.length; i++) {
            const res = obj.references.filter(y => y.TestCode === cancelled[i].TestCode 
              && y.ParameterName === cancelled[i].ParameterName 
              && y.TestStatus?.toLowerCase() !== TestStatus.Cancelled.toLowerCase())[0];
            if (res !== undefined) {
              nonCancelled.push(res);
            }
          }
        });

        value.data.map(obj => {
          obj.references.map(ref => {
            if (ref.TestStatus?.toLowerCase() === TestStatus.Cancelled.toLowerCase()) {
              const res = nonCancelled.filter(y => y.TestCode === ref.TestCode && y.ParameterName === ref.ParameterName)[0];
              if (res !== undefined) {
                ref.ResultUnit = res.ResultUnit;
              }
            }
          });
        });

        const response = new SearchResponse();
        response.count = value.pagination.count;
        response.data = value.data;
        return response;
      })
    );
  }

  DownloadAttachments(request: SelectionRequest): Observable<any> {
    const headerInfo = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/attachments/';
    return this.dataService.put<SelectionRequest, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo });
  }

  getOrderAttachments(code: string, request: AttachmentRequest): Observable<any> {
    const apiUrl =
      `${this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl}results/orders/${code}/samples/reports-attachments`;
    const headerInfo = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

    return this.dataService.post<AttachmentRequest, any>(apiUrl, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo });
  }

  DownloadARAttachments(request: AttachmentRequest): Observable<any> {
    const headerInfo = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/samples/reports-attachments/';
    return this.dataService.post<AttachmentRequest, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo });
  }

  SearchFilterResults(field: string, filter?: string, selectedValues?: string[], selectedValuesCollection?: any[]): Observable<FieldSearchResponse> {
    let request = new FieldSearchRequest(field, filter, selectedValues, selectedValuesCollection);
    request.pagination.size = this.appConfigService.configuration.filterItemsPerPage;
    //To check if there are more records
    request.pagination.size = request.pagination.size + 1;
    const url = this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/field/';
    return this.dataService.put<FieldSearchRequest, SearchFieldResponse>(url, request, true, { headers: this.getHeader() })
      .pipe(
        map((value: SearchFieldResponse) => {                
          let response = new FieldSearchResponse();
          response.hasMoreResults = value.data.length > this.appConfigService.configuration.filterItemsPerPage;
          const items: ListData[] = [];
          value.data.forEach((x) => {
            const item = Object.keys(x.values).length > 0 ? new ListValue(x.values, x.text) : new ListValue(x.value, x.text);
            if (selectedValues != undefined)
              if (selectedValues?.indexOf(x.value) !== -1) {
                item.isApplied = true;
              }

              if (selectedValuesCollection !== undefined)
                if (selectedValuesCollection.find(s => JSON.stringify(s) === JSON.stringify(x.values)) !== undefined) {
                  item.isApplied = true;
                }
            items.push(new ListData(item, item.text));
          });
          response.items = items;
          return response;
        }));
  }

  exportResult(request: SelectionRequest, exportFormat: string): Observable<any> {
    const headerInfo = new HttpHeaders({
      'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', accept: this.getAcceptHeader(exportFormat)
    });
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'results/export/';
    return this.dataService.put<SelectionRequest, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo });
  }

  availableAssessments(request: SelectionRequest): Observable<SampleAssessment> {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'bcast/assessments';
    return this.dataService.put<SelectionRequest, SampleAssessment>(url, request, true).pipe(
      catchError(error => this.handleError(error, ServiceMethod.AssessmentOptions)));
  }

  assessmentSettings(request: AssessmentSettingsRequest): Observable<AssessmentSettingsResponse[]> {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'bcast/assessments/settings';
    return this.dataService.put<SelectionRequest, AssessmentSettingsResponse[]>(url, request, true).pipe(
      catchError(error => this.handleError(error, ServiceMethod.SampleAssessmentSummary)));
  }

  performComplianceAssessment(request: SearchComplianceAssessment): Observable<any> {
    const headerInfo = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });

    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'assessments/bcast/compliance';
    return this.dataService.put<SearchComplianceAssessment, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo })
      .pipe(
        catchError(error => this.handleError(error, ServiceMethod.DownloadAssessment)));
  }

  performQuickAssessment(request: SelectionRequest): Observable<QuickAssessmentResponse> {
    const headerInfo = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'assessments/bcast/compliance-quick';

    return this.dataService.put<SelectionRequest, QuickAssessmentResponse>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo }).pipe(
        map((value: any) => {
          const response = new QuickAssessmentResponse();
          response.fileResponse = value;
          response.sampleCount = value.headers.get('x-dop-sample-count');
          return response;
        }),
        catchError(error => this.handleError(error, ServiceMethod.QuickAssessment))
      );
  }

  availableExportOptions(request: SelectionRequest): Observable<AvailableExport> {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'bcast/exports';
    return this.dataService.put<SelectionRequest, AvailableExport>(url, request, true)
      .pipe(
        catchError(error => this.handleError(error, ServiceMethod.ExportOptions)));
  }

  getExportSettings(request: SelectionRequest, exportCode: string): Observable<ExportSettings> {
    const url =
      `${this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl}bcast/exports/settings/${exportCode}`;
    return this.dataService.put<SelectionRequest, ExportSettings>(url, request, true)
      .pipe(
        catchError(error => this.handleError(error, ServiceMethod.ExportSettings)));
  }

  performExport(request: ExportRequest): Observable<any> {
    const headerInfo = new HttpHeaders({
      'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
    });
    const url =
      `${this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl}exports/bcast/download`;
    return this.dataService.put<ExportRequest, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo })
      .pipe(
        catchError(error => this.handleError(error, ServiceMethod.PerformExport)));
  }

  performQuickExport(request: SelectionRequest): Observable<any> {
    const headerInfo = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    const url =
      `${this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl}exports/bcast/download-quick`;
    return this.dataService.put<SelectionRequest, any>(url, request, true,
      { observe: 'response', responseType: 'blob', headers: headerInfo })
      .pipe(
        catchError(error => this.handleError(error, ServiceMethod.QuickExport)));
  }

  async samplesCount(request: SelectionRequest): Promise<number> {
    let countRequest = new SearchRequest(0, 1, true);
    countRequest.fields = request.fields;
    countRequest.search = request.search;
    countRequest.template = 'Sample';
    let response = await this.SearchResults(countRequest).toPromise();
    return response.pagination.count;
  }

  private getAcceptHeader(exportFormat: string): string {
    if (exportFormat === ExportConstants.Excel) {
      return 'application/excel';
    }
    else if (exportFormat === ExportConstants.Csv) {
      return 'text/csv';
    }
    else {
      return 'application/json';
    }
  }

  private setFunctionalErrors() {
    const title = () => this.translateService.instant('eiErrorOccured');
    this.responses[ServiceMethod.PerformExport] = () => new ErrorEventModel(ServiceMethod.PerformExport,
      this.translateService.instant('eiBcastDownloadError'), title(), true, false);
    this.responses[ServiceMethod.QuickExport] = () => new ErrorEventModel(ServiceMethod.PerformExport,
      this.translateService.instant('eiQuickExportError'), title(), true, false);
    this.responses[ServiceMethod.ExportSettings] = () => new ErrorEventModel(ServiceMethod.PerformExport,
      this.translateService.instant('eiBcastExportSettingError'), title(), true, false);
    this.responses[ServiceMethod.ExportOptions] = () => new ErrorEventModel(ServiceMethod.PerformExport,
      this.translateService.instant('eiBcastExportError'), title(), true, true);
    this.responses[ServiceMethod.QuickAssessment] = () => new ErrorEventModel(ServiceMethod.QuickAssessment,
      this.translateService.instant('eiQuickAssessmentError'), title(), true, false);
    this.responses[ServiceMethod.AssessmentOptions] = () => new ErrorEventModel(ServiceMethod.AssessmentOptions,
      this.translateService.instant('eiComplianceAssessmentError'), title(), true, true);
    this.responses[ServiceMethod.SampleAssessmentSummary] = () => new ErrorEventModel(ServiceMethod.SampleAssessmentSummary,
      this.translateService.instant('eiSampleSummaryError'), title(), true, false);
    this.responses[ServiceMethod.DownloadAssessment] = () => new ErrorEventModel(ServiceMethod.DownloadAssessment,
      this.translateService.instant('eiComplianceReportError'), title(), true, false);
  }

  private getMessage = (serviceMehod: ServiceMethod, isFunctional: boolean): ErrorEventModel => {
    if (isFunctional) {
      return this.responses[serviceMehod]();
    }
    const title = this.translateService.instant('eiErrorOccured');
    let message = this.translateService.instant('eiGenericError');
    return new ErrorEventModel(serviceMehod, message, title, true, false, true);
  }

  private handleError(error: HttpErrorResponse, key: ServiceMethod): Observable<any> {
    if (error.status === HttpStatusCode.BAD_REQUEST || error.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
      this.eventService.broadCast(EventTypes.ServiceError, this.getMessage(key, error.status === HttpStatusCode.BAD_REQUEST));
    }
    return throwError(error);
  }

  private getHeader() {
    const headers = {};
    headers[HandleTimeout] = 'true';
    headers[HandleTechnicalError] = 'true';
    return headers;
  }
}
