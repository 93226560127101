import { Injectable, Injector } from '@angular/core';
import { AnalyticsService } from './analytics-service';

@Injectable({
  providedIn: "root"
})
export class AnalyticsServiceProvider {
  constructor(private injector: Injector) {
  }

  async initialize() {    
    var provider = this.injector.get(AnalyticsService);

    await provider.initialize();
  }
}
