export enum GraphPreferenceType {
  radio = "radio",
  checkbox = "checkbox",
  dropdown = "dropdown",
}

export enum PreferencesIcons {
  chart = "ei-chart-growth",
  view = "ei-graph",
  resultValues = "ei-percent-circle",
}

export enum GraphTypes {
  ParameterTrend = "ParameterTrend",
  ResultConformity = "ResultConformity",
  ReceivedSampleStatus = "ReceivedSampleStatus",
}

export enum PreferencesTranslations {
  chart = "eiChartType",
  view = "eiViewType",
  resultValues = "eiResultValues",
  includeNotDetected = "eiIncludeNotDetected",
  dimension = "eiDimension",
  LineChartSmooth = "eiLineChartSmooth",
  LineChartStraight = "eiLineChartStraight",
  BarChart = "eiBarChart",
  Scatter = "eiScatter",
  Combined = "eiCombined",
  Split = "eiSplit",
  AverageValue = "eiAverageValue",
  MaximumValue = "eiMaximumValue",
  AllValues = "eiAllValues",
  IncludeNotDetected = "eiIncludeNotDetected",
  None = "None",
  SamplingPoint = "SamplingPointComposite",
  ProductMatrix = "eiProductMatrix",
  SampleCode = "", //TBD
  Project = "" //TBD
}
