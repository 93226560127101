import { Injectable } from '@angular/core';
import { ChartPreference, ChartType, resultValue, viewType } from '../Models/chart-type';
import { ChartRequestModel, ChartResponseModel } from '../Models/graphDataModels';
import { AppConfigService } from '../../../../core/services/app-config-service';
import { DataService } from '../../../../core/services/data.service';
import { Observable, catchError, map, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HandleTechnicalError } from 'src/app/common/global-constants/application-constants';

@Injectable()
export class ChartService {

  public isGraphLoading = new BehaviorSubject(false);

  constructor(private dataService: DataService, private appConfigService: AppConfigService) { }

  getChartOptions() { }

  getChartData(request: ChartRequestModel): Observable<ChartResponseModel> {
    const url =
      this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'analytics';

    return this.dataService.put<typeof request, any>(url, request, true, { headers: this.getHeader() }
    ).pipe(
     map((response: ChartResponseModel) => {
       return response;
     })
    );
  }
  
  private getHeader() {
    const headers = {};
    headers[HandleTechnicalError] = 'true';
    return headers;
  }

  setIsGraphLoading(loading: boolean) {
    this.isGraphLoading.next(loading);
  }
}
