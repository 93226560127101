import { SortCapability } from '../../features/results/shared/models/field-definition.model';

export class GridColumn {
  field: string;
  header: string;
  sort: boolean;
  filter: boolean;
  type: string;
  subType?: string;
  sortCapability: SortCapability;
  displayCapability: boolean;
  displayTooltip?: boolean;
  width: any;
  sortStatus?: string;
  domain: string;
  isDataTranslatable?:boolean;
  isAFColumm: boolean;
  isResultValueColumn: boolean;
  isFiltered: boolean;
  filterTabCode?: string;
}

