import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, filter } from 'rxjs/operators';
import { AuthGuardMessageService } from './auth-guard.message.service';
import { environment } from 'src/environments/environment';
import { MatomoRouteTracker } from 'ngx-matomo';
import { AppConfigService } from '../services/app-config-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private authGuardMessageService: AuthGuardMessageService,
    private matomoTracker: MatomoRouteTracker,
    private appConfigService: AppConfigService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    if (environment.enableAuthentication) {
      console.log('isLogged in? :', this.authService.isLoggedIn());
      if (!this.authService.isLoggedIn()) { 
        // TODO: redirect to login page
        return of(false);
      } else {
        return this.authService.isUserDataLoaded$.pipe(filter(x => x === true), map((isLoaded) => {
          const userDetails = this.authService.getUserDetails();

          if(this.authService.getUserDetails().policiesAccepted && this.authService.getUserDetails().analyticsEnabled){
            this.matomoTracker.startTracking();
          } else {
            this.matomoTracker.stopTracking();
          }

          if (route.data.expectedRole !== undefined) {
            // If User role is expected role allow to page
            if (route.data.expectedRole.indexOf(userDetails.internal) > -1) {
              this.sendErrorValue(false);
              return true;
            } else {
              // Redirect to Home Page
              this.sendErrorValue(true);
              return false;
            }
          } else {
            // Default behavior ie. user need not have specific permission to access page
            this.sendErrorValue(false);
            return true;
          }
        }));
      }
    }
  }

  sendErrorValue(value: boolean) {
    const policiesAccepted = this.authService.getUserDetails().policiesAccepted;
    if (typeof policiesAccepted === 'boolean' && !policiesAccepted) {
      window.location.href = this.appConfigService.EolBaseUrl;
    } else {
      this.authGuardMessageService.userDeniedPermission.next(value);
    }
  }
}
