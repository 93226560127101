import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, MATOMO_CONFIG } from './app/app.module';
import { environment } from './environments/environment';

fetch(environment.configurationPath).then(response => response.json()).then((config: any) => {
    // Here include the classic bootstrap process

    if (environment.production) {
        enableProdMode();
    }

    // Just add a provider for the config as an argument to platformBrowserDynamic() call:
    platformBrowserDynamic([
        { provide: MATOMO_CONFIG, useValue: config.matomoConfig },
    ])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
});