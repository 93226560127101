export enum Types {
  Text = 'text',
  Date = 'date',
  Enum = 'enum',
  Dates = 'dates',
  Number = 'numerical',
  Default = 'Default',
  Collection = 'collection',
  Link = "link",
  DateTime = 'datetime',
  Time = 'time',
  Boolean = 'boolean',
  Choices = 'choices'
}

export enum DateTimeTypes {
  Date = 'date',
  Dates = 'dates',
  DateTime = 'datetime',
  Time = 'time'
}
