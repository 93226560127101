import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, Renderer2, AfterContentChecked, LOCALE_ID, Inject } from '@angular/core';
import { LayoutService } from './core/services/layout.service';
import { Layout } from './shared/models/layout.model';
import { AuthGuardMessageService } from './core/guards/auth-guard.message.service';
import { AuthService } from './core/services/auth.service';
import { InterceptorMessageService } from './core/interceptor/interceptor.message.service';
import { EventService } from './core/services/event.service';
import { Subscription } from 'rxjs';
import { EventTypes } from './core/enums/event.types';
import { EventBuildNewResults } from './features/results/shared/models/eventBuildNewResults.model';
import { PullDownView } from './features/results/shared/models/pull-down.model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RouteConstants } from './common/global-constants';
import { PrimeNGConfig } from 'primeng/api';
import { GlobalErrorEventModel } from './common/models/global-error-event.model';
import { ChartService } from './features/results/graphsAndCharts/charts/chart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  layout: Layout = {
    pageHeader: {
      pageTitle: '',
      pageIcon: '',
      iconLink: '',
      confirmDialogRequired: false,
      pullDownItems: []
    },
    breadcrumbItems: []
  } as Layout;
  enableText = false;
  loading = false;
  isUserDataLoaded = false;
  openAccessDeniedDialog = false;
  openBuildNewResultsModal = false;
  isBuildNewResultsModalEdit = false;
  pullItems: PullDownView[];
  displayTemplate = false;
  unKnownProfile = false;
  primeng: primeng = new primeng();
  openGlobalErrorDialog = false;
  isTimeoutError = false;
  errorMessage = "";
  technicalErrorReasonMessage="";
  technicalErrorSupportMessage="";
  correlationId="";
  retry: Function;
  onCloseGlobalErrorDialog: Function;
  userLanguage = 'en';
  private userDeniedPermissionSubscription: Subscription;
  private isUserDataLoaded$Subscription: Subscription;
  private isLoadingSubscription: Subscription;
  private isGraphLoadingSubscription: Subscription;
  private openBuildNewResultViewSubscription: Subscription;
  private setApplicationTitleSubscription: Subscription;
  private globalErrorHandlingEventSubscripion: Subscription;
  private isErrorDialogDisplayed = false;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private config: PrimeNGConfig,
    private layoutService: LayoutService,
    private authGuardMsgService: AuthGuardMessageService,
    public authService: AuthService,
    private interceptorMsgService: InterceptorMessageService,
    private eventService: EventService,
    private title: Title,
    private translateService: TranslateService,
    private chartService: ChartService,
    private renderer: Renderer2) {
    this.userDeniedPermissionSubscription = this.authGuardMsgService.userDeniedPermission.subscribe((data: boolean) => {
      this.openAccessDeniedDialog = data;
      this.profileCheck();
    });
    this.isUserDataLoaded$Subscription = this.authService.isUserDataLoaded$.subscribe(s => {
      this.isUserDataLoaded = s;
      this.userLanguage = this.authService.getUserLanguage();
    });
    this.isLoadingSubscription = this.interceptorMsgService.isLoading.subscribe(data => {
      setTimeout(() => {
        this.loading = data;
      });
    });

    this.isGraphLoadingSubscription = this.chartService.isGraphLoading.subscribe(data => {
      this.enableText = data;
    })

    this.title.setTitle(this.translateService.instant('eiResults'));

    this.openBuildNewResultViewSubscription = this.eventService.listen(EventTypes.BuildNewResultView)
      .subscribe((eventData: EventBuildNewResults) => {
        this.openBuildNewResultsModal = true;
        this.isBuildNewResultsModalEdit = eventData.isEdit;
      });

    this.globalErrorHandlingEventSubscripion = this.eventService.listen(EventTypes.GlobalServiceError)
      .subscribe((eventData: GlobalErrorEventModel) => {
        this.handleGlobalError(eventData);
      });
  }

  async ngOnInit() {
    this.layoutService.layoutChanges.subscribe((layout: Layout) => {
      this.layout = layout;
      this.displayTemplate = false;
      this.translateService.setDefaultLang('en');
      this.setLocale(this.locale);
    });

  }

  private handleGlobalError(eventData: GlobalErrorEventModel) {
    if (!this.isErrorDialogDisplayed) {
      let closedFromRetry = false;
      this.isTimeoutError= false;
      this.chartService.setIsGraphLoading(false);
      if (eventData.IsTimeout) {
        this.isTimeoutError = true;
        this.errorMessage = this.translateService.instant('eiTimeoutError');
        this.retry = () => {
          this.openGlobalErrorDialog = false;
          this.isErrorDialogDisplayed = false;
          closedFromRetry = true;
          eventData.execute(true);
        }
      }
      else{
        this.technicalErrorReasonMessage = this.translateService.instant('eiTechnicalErrorReasonMessage');
        this.technicalErrorSupportMessage = this.translateService.instant('eiTechnicalErrorSupportMessage');
        this.correlationId = eventData.CorrelationId;
      }
      this.onCloseGlobalErrorDialog = () => {
        this.openGlobalErrorDialog = false;
        this.isErrorDialogDisplayed = false;
        if (!closedFromRetry && eventData.IsTimeout) {
          eventData.execute(false);

        }
      }

      this.openGlobalErrorDialog = true;
      this.isErrorDialogDisplayed = true;

    }
  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  private profileCheck() {
    const preconditionFailed = this.authService.isPreconditionFailure();
    if (preconditionFailed) {
      this.unKnownProfile = preconditionFailed;
      this.openAccessDeniedDialog = !this.unKnownProfile;
    }
  }

  setLocale(lang: string) {
    this.translateService.use(lang);
    let dayNamesMin: string[] = [];
    let monthNames: string[] = [];
    let monthNamesShort: string[] = [];
    var days = this.translateService.instant('dayNamesMin');
    dayNamesMin = JSON.parse("[" + days + "]")
    var months = this.translateService.instant('monthNames');
    monthNames = JSON.parse("[" + months + "]");
    var monthShort = this.translateService.instant('monthNamesShort');
    monthNamesShort = JSON.parse("[" + monthShort + "]");
    this.primeng = { dayNamesMin: dayNamesMin[0], monthNames: monthNames[0], monthNamesShort: monthNamesShort[0] };
    this.config.setTranslation(this.primeng);
  }

  ngOnDestroy() {
    this.userDeniedPermissionSubscription.unsubscribe();
    this.isUserDataLoaded$Subscription.unsubscribe();
    this.isLoadingSubscription.unsubscribe();
    this.openBuildNewResultViewSubscription.unsubscribe();
    this.setApplicationTitleSubscription.unsubscribe();
    this.globalErrorHandlingEventSubscripion.unsubscribe();
    this.isGraphLoadingSubscription.unsubscribe();
  }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  closeErrorDialog() {
    this.openAccessDeniedDialog = false;
  }

  retryProfile() {
    this.unKnownProfile = false;
    location.reload();
  }

  getPage() {
    return window.location.href.includes(RouteConstants.GraphsAndChartsPath);
  }


}
export class primeng {
  dayNamesMin: any;
  monthNames: any;
  monthNamesShort: any;
}
