import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config-service';
import { DataService } from 'src/app/core/services/data.service';
import { Definitions } from '../../shared/models/definitions';
import { FieldDescription } from '../../shared/models/field-definition.model';
import { AccountSearchRequest, AccountSearchResponse } from '../../shared/models/search-result.model';
import { ResultsDefinitions } from '../../shared/models/graph-models/results-filter.model';
import { PreferenceDefinition } from '../../shared/models/graph-models/preference-definition.model';

@Injectable()
export class FieldDefinitionService {

  constructor(
    private dataService: DataService,
    private appConfigService: AppConfigService) {
  }

  getDefinitions(): Observable<Definitions> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'system';
    return this.dataService.get<Definitions>(apiUrl)
      .pipe(
        map((value: Definitions) => {
          return value;
        }));
  }

  getFieldDetails(templateName: string): Observable<FieldDescription[]> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'system/fields/details?template=' + templateName;
    return this.dataService.get<FieldDescription[]>(apiUrl)
      .pipe(
        map((value: FieldDescription[]) => {
          return value;
        }));
  }
  getAccounts(searchRequest: AccountSearchRequest): Observable<AccountSearchResponse> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsSearchServiceBaseUrl + 'data-catalog/accounts';
    return this.dataService.put<AccountSearchRequest, AccountSearchResponse>(apiUrl, searchRequest, true)
      .pipe(
        map((value: AccountSearchResponse) => {
          return value;
        }));
  }

  getDefinitionsForGraph(templateName: string): Observable<any>{
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'system/filters?filterTemplate=' + templateName;
    return this.dataService.get<ResultsDefinitions>(apiUrl)
      .pipe(
        map((value: ResultsDefinitions) => {
          return value;
        }));
  }

  getPreferencesForGraph(templateName: string): Observable<any> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'graphs/preferences?preferenceTemplate=' + templateName;
    return this.dataService.get<PreferenceDefinition>(apiUrl)
      .pipe(
        map((value: PreferenceDefinition) => {
          return value;
        }));
  }
}
