import '../../../common/extensions/string.extensions';
import { MenuItem } from 'primeng/api';
import { HttpStatusCode } from 'src/app/core/enums/HttpStatusCode';
import { FieldDescription } from 'src/app/features/results/shared/models/field-definition.model';
import { GridColumn } from 'src/app/shared/models/gridColumn.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteConstants } from '../../../common/global-constants/route-constants';
import { EventTypes } from '../../../core/enums/event.types';
import { Types } from '../../../core/enums/types';
import { EventService } from '../../../core/services/event.service';
import { LayoutService } from '../../../core/services/layout.service';
import { EventBuildNewResults } from '../shared/models/eventBuildNewResults.model';
import { ManageResultView } from '../shared/models/manage-result-view.model';
import { ResultsProfileService } from '../shared/results-profile.service';
import { ManageResultsViewServiceService } from './manage-results-view-service.service';
import { StoreKeys, StoreService } from '../../../core/store/ngrx-store.service';
import { UserProfileModel } from '../../../shared/models/user-profile.model';
import { Access } from '../shared/models/access.model';
import { map } from 'rxjs/operators';
import { AccessTypes } from '../../../core/enums/access-types';
import { SortType } from '../../../core/enums/sort-status';
import '../../../common/extensions/array.extensions';
import { ResultsKebabService } from '../shared/results-kebab.service';

const defaultSortFieldNameValue = 'viewedOn';
@Component({
  templateUrl: './manageResultViews.component.html',
  styleUrls: ['./manageResultViews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageResultViewsComponent implements OnInit, OnDestroy {
  views: ManageResultView[];
  types = Types;
  colHeader: GridColumn[] = [];
  menuItems: MenuItem[];
  defaultLabel: string;
  assignLabel: string;
  isOpen = false;
  isCopyDialogOpen = false;
  isEditDialogOpen = false;
  code: string;
  sortType = SortType;
  configurationGuid: string;
  openErrorDialog = false;
  unableToSetDefaultview: boolean;
  viewNotAvailble: boolean;
  isAssignEventTriggered = false;
  currentView: ManageResultView;
  defaultSortField: string;
  sortOrder: any;
  SHARE_SCOPE = 'share.scope';
  accessType: string = null;

  constructor(
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private eventService: EventService,
    private manageResultViewsService: ResultsProfileService,
    private router: Router,
    private manageResultService: ManageResultsViewServiceService,
    private storeService: StoreService,
    private editResultsService: ResultsKebabService ) {
    this.loadResultsData();
  }

  public setDefaultView(selectedView: ManageResultView) {
    this.code = selectedView.code;
    this.manageResultViewsService.setDefaultView(selectedView.code)
      .subscribe((respone: any) => {
        this.loadResultsData();
        selectedView.default = true;
      },
        (error: HttpErrorResponse) => {
          this.openErrorDialog = true;
          this.viewNotAvailble = error.status === HttpStatusCode.NOT_FOUND;
          this.unableToSetDefaultview = !(error.status === HttpStatusCode.NOT_FOUND);
        });
  }

  ngOnInit(): void {
    this.layoutService.setPageHeader(this.translateService.instant('eiManageResultViews'), 'ei ei-library ei-26', '/', true);
    this.storeService.Get(StoreKeys.UserDetails).subscribe((user: UserProfileModel) => {
      this.colHeader = this.manageResultService.getColumnConfiguration(user.internal);
    });

    this.colHeader.forEach((col: GridColumn) => {
      if (col.field !== defaultSortFieldNameValue) {
        col.sortStatus = this.sortType.Neutral;
      }
      else {
        col.sortStatus = this.sortType.Down;
      }
    });
  }

  public openNewResultViewModal(): void {
    const eventData = new EventBuildNewResults();
    eventData.isEdit = false;
    eventData.isPreview = true;
    setTimeout(() => this.eventService.broadCast(EventTypes.BuildNewResultView, eventData));
  }

  public openSaveAsResultViewModal(data: ManageResultView): void {
    this.isAssignEventTriggered = false;
    this.configurationGuid = data.code;
    this.accessType = data.share?.type;
    this.onCopyResultClick();
  }

  public openEditResultViewModal(data: ManageResultView): void {
    this.isAssignEventTriggered = false;
    this.configurationGuid = data.code;
    this.currentView = data;
    this.CheckDisplayDialog(data, false);
  }

  public onAssignClick(data: ManageResultView) {
    this.isAssignEventTriggered = true;
    this.configurationGuid = data.code;
    this.currentView = data;
    this.CheckDisplayDialog(data, true);
  }

  public getMenuItems(data: ManageResultView) {
    const items = [];
    items.push(
      {
        label: this.translateService.instant('eiAssignTo'), icon: 'ei ei-users-plus',
        id:'assignMenu',command: (event: any) => { this.onAssignClick(data); }
      },
      {
        label: this.translateService.instant('eiEdit'), icon: 'ei ei-fw ei-pencil',
        id:'editMenu',
        command: (event: any) => { this.openEditResultViewModal(data); }
      },
      {
        label: this.translateService.instant('eiMakeDefault'), icon: 'ei ei-fw ei-stream-check',
        id:'defaultMenu',command: (event: any) => { this.setDefaultView(data); }
      },
      {
        label: this.translateService.instant('eiSaveAs'), icon: 'ei ei-fw ei-floppy-disk',
        id: 'saveAs',
        command: (event: any) => { this.openSaveAsResultViewModal(data); }
      },
      {
        label: this.translateService.instant('eiDelete'), icon: 'ei ei-fw ei-trash2',
        id: 'deleteMenu', disabled: data.permissions.delete === false,
        title: data.permissions.delete === false ? this.translateService.instant('eiCanNotDelete') : '',
        command: () => { this.onDeleteButtonClick(data); }
      });
    return items;
  }

  public loadResultsData(): void {
    this.manageResultViewsService.getResultViews()
      .subscribe((response: ManageResultView[]) => {
        this.views = response;
      });
  }

  public onDeleteButtonClick(data: ManageResultView) {
    this.code = data.code;
    // show confirmation dialog
    this.isOpen = true;
  }

  public onCloseDailog(event: Event): void {
    this.isOpen = false;
    this.isCopyDialogOpen = false;
    this.isEditDialogOpen = false;
  }

  public onCopyResultClick(): void {
    this.isCopyDialogOpen = false;
    this.openErrorDialog = this.editResultsService.copyResultView(this.configurationGuid, this.isAssignEventTriggered,
      false, this.accessType);
    this.accessType = null;
    this.viewNotAvailble = this.openErrorDialog;
  }

  public onEditResultClick(): void {
    this.isEditDialogOpen = false;
    this.editResultsService.loadViewModal(this.currentView, this.isAssignEventTriggered);
  }

  onOkClick() {
    this.openErrorDialog = false;
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate([RouteConstants.ManageResultViewPath]);
    });
  }

  public onYesButtonClickEvent(event: Event): void {
    this.isOpen = false;
    // call service for delete

    this.manageResultViewsService.deleteResultView(this.code)
      .subscribe((response: any) => {
        this.loadResultsData();
      });
  }

  public getResultConfiguration(code: string) {
    this.router.navigate([RouteConstants.ResultByViewCodePath.replaceRouteVariables(code)]);
  }

  resolveDescription(field: string): FieldDescription {
    const value = this.manageResultService.fieldDesc.find(x => x.code === field);
    return value;
  }

  canDisplayTooltip(col: GridColumn, rowData: ManageResultView) {
    if (!col.displayTooltip) {
      return false;
    }
    if (rowData.classification === 'Shared' && rowData.share && rowData.share.scope <= 5) {
      return true;
    }
    return false;
  }

  loadTooltipContent(rowData: ManageResultView) {
    return this.manageResultViewsService.getAccounts(rowData.code).pipe(map((access: Access) => {
      return access.items?.map(x => x.name ? `${x.name} (${x.code})` : `${x.code}`).join('</br>');
    }));
  }
  getDisplayText(rowData: ManageResultView, col: GridColumn) {
    if (col.field === this.SHARE_SCOPE) {
      if (rowData.share.type === AccessTypes.Account || rowData.share.type === AccessTypes.AllAccount) {
        if(rowData.share.scope>1){
          return `${rowData.share.scope} ${this.translateService.instant('eiAccounts')}`;
        }
        else{
          return `${rowData.share.scope} ${this.translateService.instant('eiAccount')}`;
        }
      } else if (rowData.share.type === AccessTypes.Public) {
        return this.translateService.instant('eiAllUsers');
      }
      return '';
    } else {
      if(col.isDataTranslatable){
        return this.translateService.instant("ei"+rowData[col.field]);
      }
      return rowData[col.field];
    }
  }

  customSort(col: GridColumn, clickEvent: any) {
    const column: GridColumn = this.colHeader.find(x => x.field.toLowerCase() === col.field.toLowerCase());

    this.colHeader.forEach((column: GridColumn) => {
      if (col.field !== column.field) {
        column.sortStatus = this.sortType.Neutral;
      }
    });

    switch (col.sortStatus) {
      case this.sortType.Neutral:
        column.sortStatus = this.sortType.Up;
        col.field === this.SHARE_SCOPE ? 
          this.views.sort((a, b) => { return a.share.scope > b.share.scope ? 1 : -1}) : this.setSortOrder(column.field);
        break;
      case this.sortType.Up:
        column.sortStatus = this.sortType.Down;
        col.field === this.SHARE_SCOPE ? 
          this.views.sort((a, b) => { return a.share.scope > b.share.scope ? -1 : 1}) : this.setSortOrder(`-${column.field}`);
        break;
      case this.sortType.Down:
      default:
        column.sortStatus = this.sortType.Neutral
        this.colHeader.find(x => x.field === defaultSortFieldNameValue).sortStatus = this.sortType.Neutral;
        this.setSortOrder(`-${defaultSortFieldNameValue}`);
        break;
    }

  }

  private CheckDisplayDialog(data: ManageResultView, isAssign: boolean): void {
    if (!data.owned && data.permissions.edit) {
      this.isEditDialogOpen = true;
    } else if (!(data.owned && data.permissions.edit)) {
      this.isCopyDialogOpen = true;
    } else if (data.owned && data.permissions.edit) {
      this.editResultsService.loadViewModal(data, isAssign);
      this.isEditDialogOpen = this.isCopyDialogOpen = false;
    }
  }

  setSortOrder(cloumn: string) {
      this.views.multiSort([cloumn]);
  }

  ngOnDestroy(): void {
    this.manageResultService.fieldDesc = [];
  }

}
