import { Injectable } from '@angular/core';
import { AnalyticsSources } from './analytics-constants';
import { AnalyticsService } from './analytics-service';

@Injectable({
  providedIn: 'root'
})

export class NoAnalytics implements AnalyticsService {
  constructor() {
  }
  static source: string = AnalyticsSources.NoAnalytics
  readonly LOG_MESSAGE = 'Analytics is disabled';
  async initialize() {
  }

  logPageView(path: string, title?: string, location?: string, options?: Object): void {
    console.log(this.LOG_MESSAGE);
  }

  logEvent(action: string, category?: string, label?: string, value?: number): void {
    console.log(this.LOG_MESSAGE);
  }

  logAppView(screen: string, appName: string, appId?: string, appVersion?: string, installerId?: string): void {
    console.log(this.LOG_MESSAGE);
  }
}
