import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DopPickListModule } from '../../../../../libs/dop-pick-list/src/public-api';
import { ResultsCommonModule } from '../../../common/common.module';
import { AppConfirmDialogModule } from '../../../common/components/app-confirm-dialog/app-confirm-dialog.module';
import { AssignAccountsComponent } from './assign-accounts/assign-accounts.component';
import { AssignFieldsComponent } from './assign-fields/assign-fields.component';
import { BuildNewResultViewComponent } from './buildNewResultView.component';
import { FieldDefinitionService } from './services/fieldDefinition.service';
import { FieldDefinitionCachedService } from './services/fieldDefinition.cached.service';

@NgModule({
  declarations: [BuildNewResultViewComponent, AssignAccountsComponent, AssignFieldsComponent],
  imports: [
    ResultsCommonModule,
    DialogModule,
    TranslateModule,
    AppConfirmDialogModule,
    DopPickListModule,
    RadioButtonModule,
    CheckboxModule,
    SelectButtonModule
  ],
  providers: [FieldDefinitionService, FieldDefinitionCachedService],
  exports: [
    BuildNewResultViewComponent,
    ResultsCommonModule
  ]
})
export class BuildNewResultViewModule { }
