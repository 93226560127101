import { DisplayType } from './../../../core/enums/display-template';
import { FieldDescription } from '../../../features/results/shared/models/field-definition.model';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-enum-type',
  templateUrl: './enum-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnumTypeComponent implements OnInit {
  @Input() enumValue: string;
  @Input() fieldDescription: FieldDescription;
  @Input() displayText = false;
  @Output() IconClick = new EventEmitter();
  @Output() IconHover = new EventEmitter();
  constructor() { }
  color: string;
  icon: string;
  text: string;
  template: string;
  displayType = DisplayType;
  ngOnInit(): void {
    this.text = this.fieldDescription.values[this.enumValue];
    this.icon = this.fieldDescription.capabilities.enum[this.enumValue]?.icon;
    this.color = this.fieldDescription.capabilities.enum[this.enumValue]?.color;
    this.template = this.fieldDescription.capabilities.component.template;
  }

  onClickEnum()
    {
      this.IconClick.emit();
    }

  onHoverEnum()
    {      
      this.IconHover.emit();
    }
}
