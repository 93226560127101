/**
 *Analytics service providers with configurable value
 * */
export enum AnalyticsSources {
  NoAnalytics = "None"
}

/**
 * Any reusable static keys related to analytics can be kept here. Ex : Events like Click
 * */
