import { FieldConfiguration } from 'src/app/features/results/shared/models/field-definition.model';
import { EventBaseData } from '../../../../shared/models/event.model';
import { EditResultViewModel } from './editResultView.model';

export class EventBuildNewResults extends EventBaseData
{
  isEdit: boolean;
  isPreview: boolean;
  isAssign: boolean;
}
