import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectedItemModel } from '../../../../../../libs/dop-pick-list/src/lib/selected-item.model';
import { TreeItem, TreeModel } from '../../../../../../libs/dop-pick-list/src/lib/tree-model';
import { EventTypes } from '../../../../core/enums/event.types';
import { EventService } from '../../../../core/services/event.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { Definitions } from '../../shared/models/definitions';
import { EventBuildNewResults } from '../../shared/models/eventBuildNewResults.model';
import { FieldDescription, FieldGroup } from '../../shared/models/field-definition.model';
import { ViewConfiguration } from '../../shared/models/result-viewconfiguration.model';
import { FieldConfigurationService } from '../../shared/field-configuration.service';
import { PicklistTranslationModel } from '../../../../../../libs/dop-pick-list/src/lib/picklist-translation.model';
import { FieldDefinitionCachedService } from '../services/fieldDefinition.cached.service';
import { EventUpdateSeletedField } from '../../shared/models/eventUpdateSeletedField.model';
import { ColumnCapability } from '../../../../core/enums/column-capability';
import { StoreKey } from 'src/app/core/store/ngrx-store.keys';
import { StoreService } from 'src/app/core/store/ngrx-store.service';

@Component({
  selector: 'app-assign-fields',
  templateUrl: './assign-fields.component.html'
})
export class AssignFieldsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() openBuildNewResultsModal: boolean;
  @Input() isEdit: boolean;

  @Output() isEnabledContinue = new EventEmitter<boolean>();

  isOpen = false;
  selectedItems: SelectedItemModel[] = [];
  viewConfiguration: ViewConfiguration = new ViewConfiguration();
  pickListTranslations: PicklistTranslationModel = new PicklistTranslationModel();
  treeModel: TreeModel = new TreeModel();


  private openBuildNewResultViewSubscription: Subscription;
  private fieldConfigurationsChangedSubscription: Subscription;
  private readonly updateSelectedItemsSubscription: Subscription;

  public isPreview: boolean;
  constructor(
    private readonly storeService: StoreService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private fieldDefinitionService: FieldDefinitionCachedService,
    private eventService: EventService,
    private fieldConfigurationService: FieldConfigurationService) {
    this.layoutService.setPageHeader(this.translateService.instant('eiBuildNewResultView'), 'ei ei-library ei-26', '/', true);

    this.openBuildNewResultViewSubscription = this.eventService.listen(EventTypes.BuildNewResultView)
      .subscribe((eventData: EventBuildNewResults) => {
        this.isPreview = eventData.isPreview;
        this.openBuildNewResultsModal = true;

        if (!(eventData.isEdit && eventData.isPreview)) {
          this.viewConfiguration = new ViewConfiguration();
          this.selectedItems = [];
          this.treeModel.resetTreeItems();
        }

        this.getFieldDefinitions();
      });

      this.updateSelectedItemsSubscription = this.eventService.listen(EventTypes.UpdateSelectedFields)
      .subscribe((data: EventUpdateSeletedField) => {
        if ( data.length > 0) {
          this.selectedItems.sort((a,b) => {
            return data.columns[a.id] - data.columns[b.id] 
          });
        }
      });
  }

  ngOnInit() {
    this.setTranslations();
  }

  private getFieldDefinitions(): Promise<void> {
    if (!(this.treeModel && this.treeModel.items.length > 0 && this.fieldConfigurationsChangedSubscription)) {
      return this.fieldDefinitionService.getDefinitions().then((definitions: Definitions) => {
        this.treeModel = Object.assign(new TreeModel(), {
          id: 'fieldSelectionPickList',
          items: this.mapTreeModel(definitions.groups)
        }
        );
        
        if(this.treeModel.items.some(e => e.id == "AdditionalFields"))
        {
          this.treeModel.items.forEach(async (item: TreeItem) => {
            if (item.id==="AdditionalFields") {
              const user = await this.storeService.Get(StoreKey.UserDetails).toPromise();
              item.items.sort( (a, b) => a.name.localeCompare(b.name, user.language, {'sensitivity': 'base'}));
            }
          });
        }

        if (!this.fieldConfigurationsChangedSubscription) {
          this.fieldConfigurationsChangedSubscription = this.eventService.listen(EventTypes.FieldConfigurationsChanged)
            .subscribe((viewConfiguration: ViewConfiguration) => {
              this.viewConfiguration = viewConfiguration;
            });
        }
      });
    }
  }

  public editResults(eventData) {
    if (this.treeModel.items && this.treeModel.items.length === 0) {
      this.getFieldDefinitions().then(_ => this.editResultsFields(eventData));
    } else {
      this.editResultsFields(eventData);
    }
  }

  private editResultsFields(eventData) {
    this.selectedItems = [];
    this.treeModel.resetTreeItems();
    this.isPreview = eventData.isPreview;
    this.isEdit = eventData.isEdit;
    if (eventData.isEdit || (eventData.isPreview && !eventData.isReset)) {
      this.viewConfiguration = new ViewConfiguration();
      this.viewConfiguration.code = eventData.selectedItems.code;
      this.viewConfiguration.name = eventData.selectedItems.name;
      this.viewConfiguration.default = eventData.selectedItems.default;
      this.viewConfiguration.template = eventData.selectedItems.fieldConfig.view.template;
      this.viewConfiguration.configuration = eventData.selectedItems.fieldConfig;
      const fields = Object.getOwnPropertyNames(eventData.selectedItems.fieldConfig?.fields)
        .map((field) => {
          const obj = {};
          obj[field] = eventData.selectedItems.fieldConfig?.fields[field].exclude === undefined;
          return obj;
        });
      this.selectedItems = this.treeModel.moveSelectedItemById(this.treeModel.items, fields, null);
      this.selectedItems.forEach(si => {
        if (eventData.selectedItems.fieldConfig?.fields[si.id][ColumnCapability.ExportOnly]) {
          si.selectedForMoreAction = true;
        }
      });

    }
  }

  private mapTreeModel(fieldGroups: FieldGroup[]): TreeItem[] {
    const treeItems: TreeItem[] = [];
    fieldGroups.forEach((fieldGroup: FieldGroup) => {
      const treeItem = new TreeItem();
      treeItem.id = fieldGroup.code;
      treeItem.name = fieldGroup.name;
      treeItem.items = [];
      fieldGroup.fields.forEach((fieldDescription: FieldDescription) => {
        treeItem.items.push({
          id: fieldDescription.code,
          name: fieldDescription.name,
          dataItem: fieldDescription,
          items: []
        });
      });
      treeItems.push(treeItem);
    });
    return treeItems;
  }

  private setTranslations() {
    this.translateService.get(['eiAvailableFields', 'eiAddAll', 'eiRemoveAll'
      , 'eiSelectedFields', 'eiDownloadOnly','eiDownloadOnlyNote','eiSearch'])
      .subscribe((res) => {

        this.pickListTranslations = {
          availableFieldsTitle: res.eiAvailableFields,
          addAllTitle: res.eiAddAll,
          removeAllTitle: res.eiRemoveAll,
          selectedItemsTitle: res.eiSelectedFields,
          displaySelectionBoxTitle: res.eiDownloadOnly,
          displaySelectionBoxNote: res.eiDownloadOnlyNote,
          searchTextNote: res.eiSeachTextNote,
          search: res.eiSearch
        };
      });
  }

  confirmFieldMovement() {
    return this.treeModel.confirmMovement();
  }

  cancelFieldMovement() {
    return this.treeModel.cancelMovement();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.openBuildNewResultsModal && simpleChanges.openBuildNewResultsModal.currentValue) {
      this.setTranslations();
    }
  }

  onSelectedItemsChange(value: SelectedItemModel[]) {
    this.isEnabledContinue.emit(value.length > 0);
  }

  public onApply(): void {
  }

  ngOnDestroy() {
    this.openBuildNewResultViewSubscription.unsubscribe();
    this.fieldConfigurationsChangedSubscription.unsubscribe();
    this.updateSelectedItemsSubscription.unsubscribe();
  }
}
