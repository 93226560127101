import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KebabMenuComponent } from './kebab-menu.component';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';



@NgModule({
  declarations: [KebabMenuComponent],
  imports: [
    CommonModule,
    MenuModule,
    ButtonModule
  ],
  exports: [KebabMenuComponent]
})
export class KebabMenuModule { }
