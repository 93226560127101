export class BrowserFinder {
  private static findBrowserName(browserType: string) {
    const agent = window.navigator.userAgent.toLowerCase();
    return agent.indexOf(browserType);
  }

  public static isIE() {
    return this.findBrowserName('trident') > -1;
  }

  public static isEdge() {
    return this.findBrowserName('edge') > -1;
  }

  public static isChrome() {
    return this.findBrowserName('chrome') > -1 && !!(window as any).chrome;
  }

  public static isFirefox() {
    return this.findBrowserName('firefox') > -1;
  }

  public static isSafari() {
    return this.findBrowserName('safari') > -1;
  }

  public static isOpera() {
    return this.findBrowserName('opr') > -1 && !!(window as any).opr;
  }

}
