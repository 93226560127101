import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthGuardMessageService {

    constructor() { }

    public userDeniedPermission = new BehaviorSubject(false);
}
