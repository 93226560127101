declare global {
  interface String {
    replaceRouteVariables(...args: any[]): string;
    matchesRoutePath(arg: string): boolean;
    equals(arg: string);
    len(char: string): number;
    replaceAllOccurenceWith(char: string, replaceWith: string): string;
  }
}

String.prototype.replaceRouteVariables = function (this: string, ...args: any[]): string {
  let replacedText = this;
  this.split('/').filter(x => x.startsWith(':')).forEach((chunk: string, index: number) => {
    replacedText = replacedText.replace(chunk, args[index]);
  });
  return replacedText;
};

String.prototype.matchesRoutePath = function (this: string, arg: string): boolean {
  return this.split('/').filter(x => !x.startsWith(':'))[0] === arg;
};

String.prototype.equals = function (this: string, arg: string): boolean {
  return this && arg && this.toLowerCase() == arg.toLowerCase();
}

String.prototype.len = function (this: string, arg: string): number {
  const word = this.toLowerCase();
  const letter = arg.toLowerCase();
  return word.lastIndexOf(letter) - word.indexOf(letter) + 1;
};

String.prototype.replaceAllOccurenceWith = function (this: string, char: string, replaceWith: string): string {
  var firstIndex = this.toLowerCase().indexOf(char.toLowerCase());
  var lastIndex = this.toLowerCase().lastIndexOf(char.toLowerCase());
  var actualCharacter = this.charAt(firstIndex);
  return this.substring(0, firstIndex) + replaceWith + this.substring(lastIndex + 1);
}

export { };
