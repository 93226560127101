import { EventTypes } from 'src/app/core/enums/event.types';
import { NewBuildStepsSteps } from 'src/app/core/enums/newBuildSteps';
import { AuthService } from 'src/app/core/services/auth.service';
import { EventService } from 'src/app/core/services/event.service';
import { StoreKeys, StoreService } from 'src/app/core/store/ngrx-store.service';
import { UserProfileModel } from 'src/app/shared/models/user-profile.model';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectedItemModel } from '../../../../../libs/dop-pick-list/src/lib/selected-item.model';
import { RouteConstants } from '../../../common/global-constants/route-constants';
import { AccessTypes } from '../../../core/enums/access-types';
import { LayoutService } from '../../../core/services/layout.service';
import { FieldConfigurationService } from '../shared/field-configuration.service';
import { EventBuildNewResults } from '../shared/models/eventBuildNewResults.model';
import { EditResultViewEvent } from '../shared/models/eventEditResultView.model';
import { ResultsProfileService } from '../shared/results-profile.service';
import { AssignAccountsComponent } from './assign-accounts/assign-accounts.component';
import { AssignFieldsComponent } from './assign-fields/assign-fields.component';
import { ViewAccess } from '../shared/models/result-viewconfiguration.model';
import { Subscription } from 'rxjs';
import '../../../common/extensions/string.extensions';

@Component({
  selector: 'app-new-result-view',
  templateUrl: './buildNewResultView.component.html',
  styleUrls: ['./buildNewResultView.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildNewResultViewComponent implements OnChanges, OnDestroy {

  @Input() openBuildNewResultsModal: boolean;
  @Input() isEdit: boolean;

  @Output() openBuildNewResultsModalChange = new EventEmitter<boolean>();

  @ViewChild(AssignFieldsComponent) fieldsNewResultViewComponent: AssignFieldsComponent;
  @ViewChild(AssignAccountsComponent) assignNewResultViewComponent: AssignAccountsComponent;

  isDownloadAllSelected = false;

  isOpen = false;
  isEnabledContinue = false;
  isViewDefault = false;
  modalHeader: string;
  confirmationHeader: string;
  makeDefaultViewLabel: string;
  groupBy: string;
  isInternal: boolean;
  accounts: string[];
  steps = NewBuildStepsSteps;
  step = NewBuildStepsSteps.Fields;
  isAllDownloadSelected = false;
  public isPreview: boolean;
  private editResultViewSubscription: Subscription;
  private openBuildNewResultViewSubscription: Subscription;
  private userDataLoadedSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private fieldConfigurationService: FieldConfigurationService,
    private resultsProfileService: ResultsProfileService,
    private eventService: EventService) {

    this.route.url.subscribe((url: UrlSegment[]) => {
      if (url.some(p => RouteConstants.BuildNewResultViewPath.matchesRoutePath(p.path))) {
        const eventData = new EventBuildNewResults();
        eventData.isEdit = false;
        eventData.isPreview = true;
        this.eventService.broadCast(EventTypes.BuildNewResultView, eventData);
      }
    });

    this.layoutService.setPageHeader(this.translateService.instant('eiBuildNewResultView'), 'ei ei-library ei-26', '/', true);

    this.editResultViewSubscription = this.eventService.listen(EventTypes.EditResultView)
      .subscribe((eventData: EditResultViewEvent) => {
        this.openBuildNewResultsModal = true;
        this.isPreview = eventData.isPreview;
        this.isEdit = eventData.isEdit;
        this.fieldsNewResultViewComponent.editResults(eventData);
        this.assignNewResultViewComponent.editResults(eventData);
        if (eventData.isAssign) {
          this.modalHeader = this.translateService.instant('eiAssignAccounts');
          this.step = NewBuildStepsSteps.Assign;
        } else {
          this.setField();
        }
      });

    this.userDataLoadedSubscription = this.authService.isUserDataLoaded$.subscribe(_ => {
      this.storeService.Get(StoreKeys.UserDetails).subscribe((data: UserProfileModel) => {
        this.isInternal = data.internal;
        this.accounts = data.accounts;
      });
    });
    this.openBuildNewResultViewSubscription = this.eventService.listen(EventTypes.BuildNewResultView)
      .subscribe((eventData: EventBuildNewResults) => {
        this.isAllDownloadSelected = false;
        if (eventData.isAssign) {
          this.setAssign();
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.openBuildNewResultsModal && simpleChanges.openBuildNewResultsModal.currentValue) {
      this.confirmationHeader = this.translateService.instant('eiConfirmationModalHeader');
      if (this.step === NewBuildStepsSteps.Fields) {
        this.modalHeader = this.translateService.instant('eiSelectFields');
      } else {
        this.modalHeader = this.translateService.instant('eiAssignAccounts');
      }
      this.makeDefaultViewLabel = this.translateService.instant('eiMakeViewAsDefault');
      this.groupBy = this.translateService.instant('eiGroupBy');
    }
  }

  onIsContinueChange(value: boolean) {
    this.isEnabledContinue = value;
  }

  onNextStep() {
    if (this.step === NewBuildStepsSteps.Fields) {
      this.setAssign();
    } else if (this.step === NewBuildStepsSteps.Assign) {
      this.onSave(false);
    }
  }

  onPreviousStep() {
    if (this.step === NewBuildStepsSteps.Name) {
      this.setAssign();
    } else if (this.step === NewBuildStepsSteps.Assign) {
      this.setField();
    }
  }

  setField() {
    this.modalHeader = this.translateService.instant('eiSelectFields');
    this.step = NewBuildStepsSteps.Fields;
  }

  setAssign() {
    this.modalHeader = this.translateService.instant('eiAssignAccounts');
    if (!this.assignNewResultViewComponent.selectedType && !this.isInternal) {
      this.assignNewResultViewComponent.selectedType = AccessTypes.AllAccount;
    }
    if (!this.assignNewResultViewComponent.selectedType && this.isInternal) {
      this.assignNewResultViewComponent.selectedType = AccessTypes.Public;
    }

    this.step = NewBuildStepsSteps.Assign;
  }

  resetSelectedFilters(selectedFilters: SelectedItemModel[]) {
    selectedFilters = selectedFilters.concat(
      this.fieldsNewResultViewComponent.selectedItems.filter(x => x.isHidden && !selectedFilters.some(y => y.id === x.id)));
    const sortedList = this.fieldsNewResultViewComponent.selectedItems.map(x => x.id);
    selectedFilters.sort((a, b) => {
      return sortedList.indexOf(a.id) - sortedList.indexOf(b.id);
    });
    selectedFilters.forEach(filter => {
      const selectedFilter = this.fieldsNewResultViewComponent.selectedItems.find(x => x.id === filter.id);
      if (selectedFilter) {
        filter.selectedForMoreAction = selectedFilter.selectedForMoreAction;
      }
    });
    this.fieldsNewResultViewComponent.selectedItems = selectedFilters;
  }

  onSave(isPrivate: boolean) {
    const selectedFilters = this.fieldsNewResultViewComponent.confirmFieldMovement();
    this.resetSelectedFilters(selectedFilters);
    const viewAccess = new ViewAccess();
    if (this.isInternal && this.assignNewResultViewComponent?.selectedType === AccessTypes.Public) {
      viewAccess.type = AccessTypes.Public;
    }
    else if (!this.isInternal && isPrivate ||
      ((!this.assignNewResultViewComponent?.selectedType || this.assignNewResultViewComponent.selectedItems.length === 0)
        && this.assignNewResultViewComponent?.selectedType !== AccessTypes.AllAccount)) {
      viewAccess.type = AccessTypes.Private;
    }
    else if (!this.isInternal && !isPrivate && this.assignNewResultViewComponent?.selectedType === AccessTypes.AllAccount) {
      viewAccess.type = this.assignNewResultViewComponent.selectedType;
      viewAccess.items = this.accounts;
    }
    else {
      viewAccess.type = this.assignNewResultViewComponent.selectedType;
      viewAccess.items = this.assignNewResultViewComponent.selectedItems.map(x => x.id);
    }

    this.fieldConfigurationService.setViewConfiguration(this.fieldsNewResultViewComponent.viewConfiguration,
      this.fieldsNewResultViewComponent.selectedItems.map(x => x.dataItem), null, null, viewAccess,
      this.fieldsNewResultViewComponent.selectedItems.filter(x => x.selectedForMoreAction).map(x => x.id));

    if (!this.fieldsNewResultViewComponent.isPreview ||
      (this.fieldsNewResultViewComponent.viewConfiguration.name && this.fieldsNewResultViewComponent.viewConfiguration.code)) {
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate([RouteConstants.EditResultViewPath]);
      });
    }
    else {
      const viewConfiguration = this.fieldConfigurationService.getViewConfiguration();
      viewConfiguration.template = viewConfiguration.configuration.view.template;

      this.resultsProfileService.saveResultsView(viewConfiguration)
        .subscribe((respone: string) => {
          this.router.navigateByUrl('', { skipLocationChange: true }).then((result) => {
            if (result != false)
              this.router.navigate([RouteConstants.ResultViewPreviewPath]);
          });
        });
    }

    this.setClosedModalState();
  }

  public callDialogModule(): void {
    this.isOpen = true;
    this.isAllDownloadSelected = false;
  }

  public onCloseDailog(event: Event): void {
    this.isOpen = false;
    this.isAllDownloadSelected = false;
  }

  public cancelAndSetClosedModalState() {
    const selectedFilters = this.fieldsNewResultViewComponent.cancelFieldMovement()
      .concat(this.fieldsNewResultViewComponent.selectedItems.filter(x => x.isHidden));
    this.resetSelectedFilters(selectedFilters);
    this.setClosedModalState();
  }

  public onNoButtonClickEvent(): void {
    this.isOpen = false;
    this.openBuildNewResultsModal = true;
    this.isAllDownloadSelected = false;
  }

  public onYesButtonClickEvent(): void {
    this.cancelAndSetClosedModalState();
    this.router.navigate(['']);
    this.isAllDownloadSelected = false;
  }

  setClosedModalState() {
    this.isOpen = false;
    this.openBuildNewResultsModal = false;
    this.openBuildNewResultsModalChange.emit(false);
    this.step = 0;
  }

  public isPreviewDisabled() {
    return this.isDownloadAllChecked() === true ?
      this.isAllDownloadSelected :
      !(this.step === this.steps.Fields ?
      (this.fieldsNewResultViewComponent && this.fieldsNewResultViewComponent.selectedItems.length > 0) :
      (this.assignNewResultViewComponent &&
        (this.assignNewResultViewComponent.selectedType === this.assignNewResultViewComponent.selectAccountOption ?
          this.assignNewResultViewComponent.selectedItems.length > 0 : true)));
  }

  public isDownloadAllChecked() {
    if (this.fieldsNewResultViewComponent && this.fieldsNewResultViewComponent.selectedItems.length > 0) {
      this.isAllDownloadSelected = this.fieldsNewResultViewComponent.selectedItems.filter(x => !x.isHidden)
        .every(x => x.selectedForMoreAction === true);
    }
    return this.isAllDownloadSelected;
  }

  public isContinueDisabled() {
    let enable = false;

    enable = this.step === this.steps.Fields && this.fieldsNewResultViewComponent
      && this.fieldsNewResultViewComponent.selectedItems.length > 0 ? (this.isInternal ? true : false) : false;

    if (this.step === this.steps.Assign && this.assignNewResultViewComponent && this.assignNewResultViewComponent.selectedType) {
      if (this.isInternal) {
        enable = this.assignNewResultViewComponent.selectedType === this.assignNewResultViewComponent.publicOption ||
          (this.assignNewResultViewComponent.selectedType === this.assignNewResultViewComponent.selectAccountOption
            && this.assignNewResultViewComponent.selectedItems.length > 0) ? true : false;

      }
      else {
        enable = (this.assignNewResultViewComponent.selectedType === this.assignNewResultViewComponent.allAccountOption ||
          (this.assignNewResultViewComponent.selectedType === this.assignNewResultViewComponent.selectAccountOption
            && this.assignNewResultViewComponent.selectedItems.length > 0)) ? true : false;
      }
    }
    return this.isDownloadAllChecked() === true ? this.isAllDownloadSelected : !enable;
  }

  ngOnDestroy() {
    this.editResultViewSubscription.unsubscribe();
    this.openBuildNewResultViewSubscription.unsubscribe();
    this.userDataLoadedSubscription.unsubscribe();
  }
}
