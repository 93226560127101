import { NgModule } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';
import { NumberRangeComponent } from './number-range/number-range.component';
import { BooleanListComponent } from './boolean-list/boolean-list.component';
import { TimeRangeComponent } from './time-range/time-range.component';
import { ListboxModule } from 'primeng/listbox';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
    declarations: [
        LoadingComponent, TimeRangeComponent, BooleanListComponent, NumberRangeComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        StepsModule,
        BreadcrumbModule,
        MenubarModule,
        SplitButtonModule,
        ListboxModule,
        TranslateModule,
        InputNumberModule,
        CalendarModule,
        ProgressSpinnerModule
    ],
    exports: [
        HttpClientModule,
        FormsModule,
        StepsModule,
        BreadcrumbModule,
        MenubarModule,
        SplitButtonModule,
        LoadingComponent,
        TimeRangeComponent,
        BooleanListComponent,
        NumberRangeComponent
    ],
})
export class SharedModule { }
