<p-dialog [(visible)]="openBuildNewResultsModal" [modal]="true" [responsive]="true" (onHide)="cancelAndSetClosedModalState()" [style]="{width: '1000px',height:'570px'}" [baseZIndex]="2000" [styleClass]="'build-new'">
  <p-header class="header">
    <i *ngIf="step!==steps.Fields" class="ei ei-arrow-left" (click)="onPreviousStep()"></i>{{modalHeader}}
  </p-header>
  <div class="m-b-12" class="modal-new-results">
    <app-assign-fields #addFields [hidden]="step!==steps.Fields" [isEdit]="isEdit" [(openBuildNewResultsModal)]="openBuildNewResultsModal" (isEnabledContinue)="onIsContinueChange($event)"></app-assign-fields>
    <app-assign-accounts #assignAccounts [hidden]="step!==steps.Assign" (isEnabledContinue)="onIsContinueChange($event)"></app-assign-accounts>
  </div>
  <p-footer>
    <div class="text-right">
      <button *ngIf="!isInternal && step===steps.Fields" pButton class="p-button p-component p-button-text-only" type="button" label="Continue" (click)="onNextStep()" [disabled]="isPreviewDisabled()" id="assign_button">
        {{'eiAssign' |translate}}
      </button>
      <button *ngIf="!isInternal && step===steps.Fields" pButton class="p-button p-component p-button-text-only" type="button" label="Preview" (click)="onSave(true)" [disabled]="isPreviewDisabled()" id="preview_button">
        {{'eiPreview' |translate}}
      </button>
      <div *ngIf='isDownloadAllChecked()' class="download-warning">
        {{'eiDownloadOnlyAlertInfo' |translate}}
      </div>
      <button *ngIf="isInternal || (!isInternal && step===steps.Assign)" pButton class="p-button p-component p-button-text-only" type="button" label="Continue" (click)="onNextStep()" [disabled]="isContinueDisabled()" id="continue_button">
        {{'eiContinue' |translate}}
      </button>
      <button pButton class="p-button-secondary p-button p-component p-button-text-only p-button-cutom-secondary" type="button" (click)="callDialogModule()" id="cancel_button">
        {{'eiCancel'|translate}}
      </button>
    </div>
  </p-footer>
</p-dialog>
<app-confirm-dialog [openDialog]="isOpen" (openDialogChange)="onCloseDailog($event)"  (confirmYes)="onYesButtonClickEvent()"  (confirmNo)="onNoButtonClickEvent()"  [header]= "confirmationHeader" [scrollable]="false">{{'eiConfirmaionMessage'|translate}}</app-confirm-dialog>
