import { ManageResultView } from './models/manage-result-view.model';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppConfigService } from '../../../core/services/app-config-service';
import { ViewConfiguration } from './models/result-viewconfiguration.model';
import { ResultViewAccess, ResultViewAccessDetails } from '../shared/models/result-view-access.model';
import { DataService } from '../../../core/services/data.service';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Access } from './models/access.model';
import { EnableCacheHeaderName, HandleTechnicalError } from '../../../common/global-constants/application-constants';
import { RequestCache } from '../../../core/interceptor/request-cache.service';
import { SavedGraphModel } from './models/graph-models/saved-graphs.model';
import { UserPreference } from './models';

@Injectable({
  providedIn: 'root'
})
export class ResultsProfileService {
  profileApiBaseUrl: any;
  constructor(
    private dataService: DataService,
    private appConfigService: AppConfigService,
    private requestCache: RequestCache) {
    this.profileApiBaseUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl;
  }

  saveResultsView(resultsView: ViewConfiguration): Observable<string> {
    const apiUrl = this.profileApiBaseUrl + 'views';
    return this.dataService.post<ViewConfiguration, string>(apiUrl, resultsView, false, { headers: this.getHeader() });
  }

  editResultsView(resultsView: ViewConfiguration): Observable<string> {
    const apiUrl = this.profileApiBaseUrl + 'views/' + resultsView.code + '';
    return this.dataService.put<ViewConfiguration, string>(apiUrl, resultsView, true);
  }

  getDefaultView(): Observable<ViewConfiguration> {
    const apiUrl = this.profileApiBaseUrl + 'views/default';
    return this.dataService.get(apiUrl)
      .pipe(map((value: ViewConfiguration) => {
        return value;
      }), catchError((error: HttpErrorResponse) => {
        if (error.status) {
          this.errorHandle(error);
        }
        return throwError('Error-Occured');
      }));
  }

  getFieldConfiguration(code: any): Observable<ViewConfiguration> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/' + code + '/configuration';
    return this.dataService.get(apiUrl)
      .pipe(
        map((value: ViewConfiguration) => {
          return value;
        }), catchError(this.errorHandle));
  }

  getResultViews(): Observable<ManageResultView[]> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views';
    return this.dataService.get(apiUrl, false, { headers: this.getHeader() })
      .pipe(
        map((value: ManageResultView[]) => {
          return value;
        }));
  }

  getChartsAndGraphsViews(): Observable<SavedGraphModel[]> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'graphs';
    return this.dataService.get(apiUrl)
      .pipe(
        map((value: SavedGraphModel[]) => {
          return value;
        }), catchError(this.errorHandle));
  }

  deleteResultView(code){
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/' + code;
    return this.dataService.delete(apiUrl, code)
      .pipe(
        retry(1),
        catchError(this.errorHandle)
      );
  }

  deleteGraphView(code) {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'graphs/' + code;
    return this.dataService.delete(apiUrl, code)
      .pipe(
        retry(1),
        catchError(this.errorHandle)
      );
  }

  renameGraphView(code, renameRequest) {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'graphs/' + code + '/rename';
    return this.dataService.put(apiUrl, renameRequest, true).pipe(catchError(this.errorHandle));;
  }

  setDefaultView(code) {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/';
    return this.dataService.putRouteObject<string>(apiUrl + code + '/default', true)
      .pipe(catchError(this.errorHandle));
  }

  getResultViewAccessDetails(code: any, count: number): Observable<ResultViewAccessDetails> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/' + code + '/access/account?count=' + count;
    return this.dataService.get(apiUrl)
      .pipe(
        map((value: ResultViewAccessDetails) => {
          return value;
        }), catchError((error: HttpErrorResponse) => {
          if (error.status) {
            this.errorHandle(error);
          }
          return throwError('Error-Occured');
        }));
  }

  getResultViewAccess(code: any): Observable<ResultViewAccess> {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/' + code + '/access';
    return this.dataService.get(apiUrl)
      .pipe(
        map((value: ResultViewAccess) => {
          return value;
        }), catchError((error: HttpErrorResponse) => {
          if (error.status) {
            this.errorHandle(error);
          }
          return throwError('Error-Occured');
        }));
  }

  removeAccountAccessCache(code: string) {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/' + code + '/access/account';
    return this.requestCache.remove(apiUrl);
  }

  getAccounts(code: string) {
    const apiUrl = this.appConfigService.configuration.apiConfig.resultsProfileServiceBaseUrl + 'views/';
    const headers = {};
    headers[EnableCacheHeaderName] = 'true';
    const headerInfo = new HttpHeaders(headers);
    return this.dataService.get<Access>(apiUrl + code + '/access/account', false, { headers: headerInfo })
      .pipe(catchError(this.errorHandle));
  }

  savePreference(typeCode: string, preference: UserPreference) {
    const apiUrl = `${this.profileApiBaseUrl}preferences/${typeCode}`;
    return this.dataService.put(apiUrl, preference, true).pipe(catchError(this.errorHandle));;
  }

  getPreference(typeCode: string) {
    const apiUrl = `${this.profileApiBaseUrl}preferences/${typeCode}`;
    return this.dataService.get(apiUrl).pipe(catchError(this.errorHandle));
  }

  private getHeader() {
    const headers = {};
    headers[HandleTechnicalError] = 'true';
    return headers;
  }

  // Error handling
  errorHandle(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  }
}
