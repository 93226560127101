import { NgModule } from '@angular/core';
import { DopPickListComponent } from './dop-pick-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IndeterminateDirective } from './indeterminate-directive';
import { UiScrollModule } from 'ngx-ui-scroll';
import { DopTooltipModule } from '../../../dop-tooltip/src/libs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
  declarations: [DopPickListComponent, IndeterminateDirective],
  imports: [
    CommonModule,
    FormsModule,
    UiScrollModule,
    DopTooltipModule,
    TranslateModule
  ],
  providers: [TranslateService],
  exports: [DopPickListComponent, IndeterminateDirective,
    UiScrollModule
  ]
})
export class DopPickListModule { }
