export enum ColumnCapability {
  Sort = 'sort',
  Filter = 'filter',
  Exclude = 'exclude',
  Search = 'search',
  ListTextFilter = 'listTextFilter',
  TextFilter = 'textFilter',
  DateFilter = 'dateFilter',
  RollingDateFilter = 'rollingDateFilter',
  TimeFilter = 'timeFilter',
  ListBoolFilter = 'listBoolFilter',
  NumberFilter = 'numberFilter',
  Display = 'display',
  Tooltip = 'tooltip',
  ExportOnly = 'exportOnly',
  OpenSearch = 'openSearch',
  CompositeListtextFilter = 'compositeListTextFilter'
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum Operation {
  Equal = 'Equal',
  NotEqual = 'NotEqual'
}
