import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Types } from '../../../core/enums/types';
import { AppConfigService } from 'src/app/core/services/app-config-service';
import { CustomDatePipe } from '../../pipes/custom-date.pipe';

@Component({
  selector: 'app-date-type',
  templateUrl: './date-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTypeComponent implements OnInit {
  @Input() value: string | any;
  @Input() type?: Types;
  @Input() isAFColumn?: boolean;
  @Input() isTimeBeEnabled?: boolean;
  @Input() tooltipDisabled = true;
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly datePipe: CustomDatePipe
  ) { }
  isDateEnabled: boolean;
  isTimeEnabled: boolean;
  disableStyle = false;
  tooltipValue = '';
  ngOnInit(): void {
    if ((this.type != null && this.type.length > 0) && this.isAFColumn) {
      this.isDateEnabled = !!((this.type === Types.Date || this.type === Types.DateTime));
      this.isTimeEnabled = !!((this.type === Types.Time || this.type === Types.DateTime));
      this.tooltipValue = this.getTooltipvalue(this.isDateEnabled, false, this.value);
      if (this.type === Types.Time && this.value) {
        this.disableStyle = true;
        this.value = this.getTimevalue(this.value);
        this.tooltipValue = this.getTooltipvalue(false, this.isTimeEnabled, this.value);
      } else if (this.type === Types.DateTime && this.value) {
        this.tooltipValue = this.tooltipValue + this.getTooltipvalue(false, this.isTimeEnabled, this.value);
      }
    } else {
      this.isDateEnabled = true;
      this.isTimeEnabled = (this.isAFColumn || this.isTimeBeEnabled) ? true : this.appConfig.getTimeEnabled();
      this.tooltipValue = this.getTooltipvalue(this.isDateEnabled, this.isTimeBeEnabled, this.value);
    }
  }

  getTimevalue(value): Date {
    if (typeof value === "string") {
      const splittime = this.value.replace('T', '').replace('Z', '').split(":").map(Number);
      splittime.shift();
      return new Date(0, 0, 0, ...splittime);
    }
    return new Date(0, 0, 0, this.value.hours, this.value.minutes, this.value.seconds, this.value.milliseconds);
  }

  getTooltipvalue(isDateEnabled, isTimeBeEnabled, value) {
    return (isDateEnabled ? this.datePipe.transform(value, 'shortDate') : '')
      + ' ' + (isTimeBeEnabled ? this.datePipe.transform(value, 'shortTime') : '');
  }
}
