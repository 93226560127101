import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AppConfig } from '../../shared/models/app-config';
import { registerLocaleData } from '@angular/common';
import { DopSessionRevocationConfig } from 'libs/dop-session-revocation/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  authConfig: AuthConfig;
  dopSessionConfig?: DopSessionRevocationConfig;
  configuration: AppConfig;
  userLanguageCode: string;
  formatCulture: string;
  AppConfigService: any;
  wareHouseDate= '2010-01-01';
  uiStartDate: string;

  constructor(private http: HttpClient) { }
  loadDefaultLangauge = false;
  setConfiguration(): Promise<AppConfig> {
    return new Promise((resolve, reject) => {
      const path = environment.configurationPath;
      this.http.get(path).toPromise().then((response: any) => {
        this.configuration = response;
        return resolve(this.configuration);
      }).then(() => resolve(this.configuration))
        .catch(() => reject());
    });
  }

  setInit(){
    this.http.head(this.configuration.apiConfig.resultsProfileServiceBaseUrl + 'warmup').subscribe();
    this.http.head(this.configuration.apiConfig.resultsSearchServiceBaseUrl + 'warmup').subscribe();
  }

  setupAuthConfig(configuration: AppConfig): AuthConfig {
    this.configuration = configuration;
    return this.authConfig = new AuthConfig({
      issuer: this.configuration.oauthConfiguration.issuer,
      loginUrl: this.configuration.oauthConfiguration.loginUrl,
      logoutUrl: this.configuration.oauthConfiguration.logoutUrl,
      tokenEndpoint: this.configuration.oauthConfiguration.tokenEndpoint,
      clientId: this.configuration.oauthConfiguration.clientId,
      resource: this.configuration.oauthConfiguration.resource,
      redirectUri: this.configuration.oauthConfiguration.redirectUri,
      scope: this.configuration.oauthConfiguration.scope,
      responseType: this.configuration.oauthConfiguration.responseType,
      requestAccessToken: this.configuration.oauthConfiguration.requestAccessToken,
      oidc: this.configuration.oauthConfiguration.oidc
    });
  }

  getUIStartDate(): string {
    // If the UIstart date is not available from configuration endpoint use the warehouse date
    return this.uiStartDate ? this.uiStartDate : this.configuration.wareHouseDate;
  }

  getCompareSampleMaxLimit(): number {
    return this.configuration.compareSampleMaxLimit;
  }

  getGroupByOrderScrollLength(): number {
    return this.configuration.groupByOrderScrollLength;
  }

  getGroupBySampleScrollLength(): number {
    return this.configuration.groupBySampleScrollLength;
  }

  getSpecificExportSampleLimit(): number {
    return this.configuration.specificExportSampleLimit;
  }

  getAssessmentSampleLimit(): number {
    return this.configuration.assessmentSampleLimit;
  }

  get EolBaseUrl(): string {
    return this.configuration.apiConfig.eolBaseUrl;
}

  setCurrentCulture(cultureId: string) {
    import(`/node_modules/@angular/common/locales/global/${cultureId}.js`)
      .then(lang => {
        if(lang?.default) {
          registerLocaleData(lang.default)
        }
      },
      () => {
        import(`/node_modules/@angular/common/locales/global/${cultureId.split('-')[0]}.js`)
        .then(lang => {
          if(lang?.default) {
            registerLocaleData(lang.default)
          }
        }); /* TODO : Need to log the culture on failure
              can be done once we have logging in client side.
            */
      });
  }

  setSessionRevocationConfig(configuration: AppConfig) : DopSessionRevocationConfig | undefined {
    this.configuration = configuration;
    const dopSessionConfig: DopSessionRevocationConfig | undefined = configuration.dopSessionRevocationLibrary;
    if (dopSessionConfig) {
        this.dopSessionConfig = {
            serviceBaseUrl: dopSessionConfig.serviceBaseUrl,
            sessionCheckInterval: dopSessionConfig.sessionCheckInterval
        };
    }
    return this.dopSessionConfig;
}

  getResultsAppVersion(): string {
    return this.configuration.appVersionNumber;
  }

  getTimeEnabled(): boolean {
    return this.configuration.isTimeEnabledForNativeFields;
  }

  getLanguagePreference(): boolean {
    return this.configuration.showLanguageInFooter;
  }
}
