import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() enableSpinnerText: boolean;

  constructor(private translateService: TranslateService) { }

  getFormattedTranslatedData(label: string) {
    let translatedData: string = this.translateService.instant(label);
    let dataParts = translatedData.split('$lineBreak$');
    return dataParts.length > 1 ? dataParts[0] + "<br>" + dataParts[1] : dataParts[0];
  }
}
