import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-kebab-menu',
  templateUrl: './kebab-menu.component.html',
  styleUrls: ['./kebab-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KebabMenuComponent {

  @Input() menuItems: MenuItem[];
  @Input() disabled = false;
  constructor() { }
}
