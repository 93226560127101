import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectedItemModel } from './selected-item.model';
import { PagedTreeModel, PickListRequestModel, TreeItem, TreeModel } from './tree-model';
import { PicklistTranslationModel } from './picklist-translation.model';
import { Observable, of, Subscription } from 'rxjs';
import { Datasource, IDatasource } from 'ngx-ui-scroll';
import { map } from 'rxjs/operators';
import { EventTypes } from 'src/app/core/enums/event.types';
import { EventService } from 'src/app/core/services/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-dop-pick-list',
  templateUrl: './dop-pick-list.component.html',
  styleUrls: ['./dop-pick-list.component.scss']
})
// Virtual scroll works only for flat data as of now.
export class DopPickListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() treeModel: TreeModel;
  @Input() isEdit: boolean;
  @Input() enableSearch: boolean;
  @Input() enableSelectedSearch: boolean;
  @Input() displaySelectionCheckbox: boolean;
  @Input() enableVirtualScroll = false;
  @Input() selectionBoxHeight: number;
  @Input() selectionBoxWidth: number;
  @Input() libTranslations: PicklistTranslationModel;
  @Input() clientSideLoad = true;
  @Input() searchOnKeyUp = false;
  @Input() selectAllAssigned = false;
  @Output() selectedItemsChange = new EventEmitter<SelectedItemModel[]>();
  @Output() treeModelChange = new EventEmitter<TreeModel>();
  @Input() dataSource: (requestModel: PickListRequestModel) => Observable<PagedTreeModel>;
  allSelectedItemsChecked = false;
  fewSelectedItemsChecked = false;
  selectedListItems: SelectedItemModel[];
  selectedAccountsList: Map<string,boolean> = new Map();
  virtualDatasource: IDatasource;
  displaySelectAll = false;
  public searchKey = '';
  public assignItemSearchKey = '';
  private openBuildNewResultViewSubscription: Subscription;
  private openEditResultViewSubscription: Subscription;
  private totalResult: number;
  get selectedItems(): SelectedItemModel[] {
    return this.selectedListItems;
  }

  constructor(
    private eventService: EventService){
  }

  @Input()
  set selectedItems(value: SelectedItemModel[]) {
    this.selectedListItems = value;
    this.selectedItemsChange.emit(value);
  }

  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('searchFieldBox') searchFieldBox: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treeModel && changes.treeModel.currentValue.items && (
      changes.treeModel.previousValue && changes.treeModel.previousValue.items.length === 0 &&
      changes.treeModel.currentValue.items.length > 0)) {
      this.setUpTreeView();
    }
  }

  ngAfterViewInit(): void {
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.initDataSource();
    }

    this.onFieldsSearch();
  }

  ngOnInit(): void {
    this.setUpTreeView();
    this.openBuildNewResultViewSubscription = this.eventService.listen(EventTypes.BuildNewResultView)
    .subscribe(() => {
      this.searchKey='';
      this.assignItemSearchKey ='';
      this.displaySelectAll = false;
    });
    this.openEditResultViewSubscription = this.eventService.listen(EventTypes.EditResultView)
    .subscribe(() => {
      this.searchKey='';
      this.assignItemSearchKey ='';
      this.displaySelectAll = false;
    });
  }
  
  ngOnDestroy(): void {
    if(this.openBuildNewResultViewSubscription)
    {
    this.openBuildNewResultViewSubscription.unsubscribe();
  }
  if(this.openEditResultViewSubscription)
  {
  this.openEditResultViewSubscription.unsubscribe();
  }
  }

  private setUpTreeView() {
    if (!this.isEdit) {
      this.removeAll();
    }
    if (!this.treeModel.items) {
      this.treeModel.items = [];
    }

    this.setTreeModel(this.treeModel);
    this.expandAndUncheckAll();
    this.treeModelChange.emit(this.treeModel);
}

  public setTreeModel(treeMdl: TreeModel, reloadDatasource?: boolean) {
    treeMdl.items = this.mapTreeItemModel(treeMdl.items, 1);
    treeMdl.isFlatData = !this.treeModel.items.some(x => x.items && x.items.length > 0);
    this.treeModel = Object.assign(new TreeModel(), treeMdl);
    if (reloadDatasource && this.clientSideLoad && this.virtualDatasource) {
      this.virtualDatasource.adapter.reload(1);
    }
  }

  private expandAndUncheckAll() {
    this.treeModel.items.forEach((item: TreeItem) => {
      item.expanded = true;
      item.items.forEach((childItems: TreeItem) => {
        childItems.selected = false;
      });
    });
  }

  get enableMoveRight(): boolean {
    return this.treeModel.getSelectedItems().length > 0;
  }

  get enableMoveLeft(): boolean {
    return this.selectedItems && this.selectedItems.some((item: SelectedItemModel) => {
      return item.selectedToMove;
    });
  }

  get enableReorder(): boolean {
    return this.selectedItems && this.selectedItems.some((item: SelectedItemModel) => {
      return item.selectedToMove;
    }) && this.selectedItems.length > 1;
  }

  private mapTreeItemModel(items: TreeItem[], level: number) {
    const mappedItems: TreeItem[] = [];
    items.forEach((item: TreeItem) => {
      const mappedItem = Object.assign(new TreeItem(), item);
      item.selected = false;
      mappedItem.level = mappedItem.level ?? level;
      if (item.items && item.items.length > 0) {
        mappedItem.items = this.mapTreeItemModel(item.items, level + 1);
      }
      mappedItems.push(mappedItem);
    });
    return mappedItems;
  }

  isChecked(ele: any, item: TreeItem) {
    ele.indeterminate = false;
    if (item.items && item.items.length > 0) {
      if (item.items.every((child: TreeItem) => {
        return child.selected;
      })) {
        item.selected = true;
        return true;
      } else if (item.items.some((child: TreeItem) => {
        return child.selected;
      })) {
        ele.indeterminate = true;
        return false;
      } else {
        return false;
      }
    }
    return item.selected;
  }

  private setTemporaryMovement(item: TreeItem, selectedFieldsToMove: string[]) {
    if (selectedFieldsToMove.indexOf(item.id) > -1) {
      item.isAddedInDraft = item.isMoved;
      item.isRemovedInDraft = !item.isMoved;
    }
  }

  private setMovedTreeItem(
    items: TreeItem[],
    selectedItems: SelectedItemModel[],
    isMovedBack: boolean,
    selectedFieldsToMove: string[],
    parentId: string = null) {
    items.forEach((item: TreeItem) => {

      this.setUnSelectedItem(selectedItems, item, isMovedBack, selectedFieldsToMove);
      this.setSelectedItem(selectedItems, item, parentId, selectedFieldsToMove);
      if (item.hasChildren()) {
        this.setMovedTreeItem(item.items, selectedItems, isMovedBack, selectedFieldsToMove, item.id);
      }
    });
  }

  private setUnSelectedItem(selectedItems, item, isMovedBack, selectedFieldsToMove) {
    selectedItems.forEach((selectedItem: SelectedItemModel) => {
      if (selectedItem.parentId === item.id || selectedItem.id === item.id) {
        if (selectedItem.parentId !== item.id) {
          item.isMoved = !isMovedBack;
          this.setTemporaryMovement(item, selectedFieldsToMove);
        }
        item.selected = false;
      }
    });
  }

  private setSelectedItem(selectedItems, item, parentId, selectedFieldsToMove) {
    if (selectedItems.length === 0) {
      if ((this.treeModel.isFlatData || item.level > 1) && (!this.treeModel.isTreeSeached || item.isSearched)) {
        item.isMoved = true;
        this.setTemporaryMovement(item, selectedFieldsToMove);
        if (!this.selectedItems.some(x => x.id === item.id)) {
          this.selectedItems.push(this.treeModel.mapSelectedItem(item, parentId));
        }
      }
      item.selected = false;
    }
  }

  moveRight() {
    const resetSearch = this.selectedItems.length === 0;
    this.selectedItems = this.selectedItems.concat(this.treeModel.getSelectedItems());
    this.setMovedTreeItem(this.treeModel.items, this.selectedItems, false, this.treeModel.getSelectedItems().map(x => x.id));
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.virtualDatasource.adapter.reload(1);
    }

    this.onFieldsSearch();

    this.treeModelChange.emit(this.treeModel);
    if (this.enableSelectedSearch) {
      if (resetSearch) {
        this.assignItemSearchKey = '';
      }
      this.onSearchSelectedItems(this.assignItemSearchKey);
      this.checkDisplaySelectAll();
    }
  }

  moveLeft() {
    const selectedToMoveItems = this.selectedItems.filter((item: SelectedItemModel) => {
      return item.selectedToMove;
    });

    this.setMovedTreeItem(this.treeModel.items, selectedToMoveItems, true, selectedToMoveItems.map(x => x.id));
    this.selectedItems = this.selectedItems.filter((item: SelectedItemModel) => {
      return !item.selectedToMove;
    });
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.virtualDatasource.adapter.reload(1);
    }

    this.onFieldsSearch();

    this.treeModelChange.emit(this.treeModel);
    if (this.enableSelectedSearch) {
      this.onSearchSelectedItems(this.assignItemSearchKey);
      this.checkDisplaySelectAll();
    }
  }

  filterNonMovedItems(items: TreeItem[]) {
    return items.filter((item: TreeItem) => {
      return !item.isMoved && (!this.treeModel.isTreeSeached || item.isSearched);
    });
  }

  addAll() {
    // Do not clear on virtual scroll as more items can be added on scroll further
    if (!this.enableVirtualScroll && !this.searchOnKeyUp) {
      this.selectedItems = [];
    }
    const resetSearch = this.selectedItems.length === 0;
    this.setMovedTreeItem(this.treeModel.items, [], false, this.treeModel.getAvailableItems().map(x => x.id));
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.virtualDatasource.adapter.reload(1);
    }

    this.onFieldsSearch();
    this.treeModelChange.emit(this.treeModel);
    if (this.enableSelectedSearch) {
      if (resetSearch) {
        this.assignItemSearchKey = '';
      }
      this.onSearchSelectedItems(this.assignItemSearchKey);
      this.checkDisplaySelectAll();
    }
  }

  removeAll() {
    if (this.selectedItems && this.selectedItems.length === 0) {
      return;
    }
    this.setMovedTreeItem(this.treeModel.items, this.selectedItems, true, this.selectedItems.map(x => x.id));
    this.selectedItems = [];
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.virtualDatasource.adapter.reload(1);
    }

    this.onFieldsSearch();

    this.treeModelChange.emit(this.treeModel);
    if (this.enableSelectedSearch) {
      this.onSearchSelectedItems(this.assignItemSearchKey);
      this.checkDisplaySelectAll();
    }
  }
  getDisplayableItems(items: SelectedItemModel[]) {
    return this.isSelectedItemsSearched() ?
      items.filter(x => !x.isHidden && x.isSearched)
      : items.filter(x => !x.isHidden);
  }

  reorder(up: boolean) {
    const selectedItemsToReorder = this.selectedItems.filter((item: SelectedItemModel) => {
      return item.selectedToMove;
    });

    (up ? selectedItemsToReorder : selectedItemsToReorder.reverse()).forEach((item: SelectedItemModel) => {
      const fromIndex = this.selectedItems.indexOf(item);
      if ((up && fromIndex - 1 >= 0) || (!up && fromIndex + 1 <= this.selectedItems.length - 1)) {

        const toIndex = up ? fromIndex - 1 : fromIndex + 1;
        this.selectedItems.splice(fromIndex, 1);
        this.selectedItems.splice(toIndex, 0, item);
      }
    });
  }
  private traverseAllChildNodesAndSearch(items: TreeItem[]) {
    items.forEach((item: TreeItem) => {
      if (!item.hasChildren()) {
        if (this.treeModel.isTreeSeached && !item.isMoved) {
          if (this.searchOnKeyUp) {
            item.isSearched = item.name.toLowerCase().includes(this.treeModel.searchKey.toLowerCase());
          } else {
            item.isSearched = (item.id.toLowerCase().includes(this.treeModel.searchKey.toLowerCase()) ||
              item.name.toLowerCase().includes(this.treeModel.searchKey.toLowerCase()));
          }
        } else {
          item.isSearched = false;
        }
      } else {
        this.traverseAllChildNodesAndSearch(item.items);
      }
    });
  }

  private clientSideSearch(searchKey: string) {
    this.traverseAllChildNodesAndSearch(this.treeModel.items);
  }

  private setMovedFlag(items: TreeItem[]) {
    items.forEach((item: TreeItem) => {
      if (item.hasChildren()) {
        this.setMovedFlag(item.items);
      } else {
        if (this.selectedItems.some(x => x.id === item.id)) {
          item.isMoved = true;
        }
        item.isSearched = this.treeModel.isTreeSeached;
      }
    });
  }
  private clientSidePaging(start: number, end: number): TreeItem[] {
    const data: TreeItem[] = [];
    // Assuming this.treeModel.items contains searched data.
    const items = this.filterNonMovedItems(this.treeModel.items);

    for (let i = start - 1; i < (start - 1 + end) && i < items.length; i++) {
      if (items[i]) {
        data.push(items[i]);
      }
    }
    return data;
  }

  private initDataSource(): void {
    this.virtualDatasource = new Datasource({
      get: (index, count) => {

        const start = Math.max(1, index);
        const end = index + count - 1;

        if (this.clientSideLoad) {
          this.clientSideSearch(this.searchKey);
          return of(this.clientSidePaging(start, end));
        } else {
          if (this.searchKey === '') {
            return of([]);
          }
          this.syncSelectedLists(index);
          //Method itself return empty array if count is 0.
          const checkedBeforeScroll = this.treeModel.getSelectedItems();
          // For server load do the search and pagination together
          return this.dataSource(new PickListRequestModel(start - 1, count + this.selectedItems.length, this.searchKey, this.selectedItems.map(x => x.id))).pipe(
            map((response: PagedTreeModel) => {
              this.totalResult = response.totalCount;
              this.setMovedFlag(response.items);
              response.items.forEach((item: TreeItem) => {                
                checkedBeforeScroll?.forEach(checkedText => {
                  if(item.id == checkedText.id)
                  {
                    item.selected = true;
                  }
                 });                
                if (!this.treeModel.items.some(x => x.id === item.id)) {
                  this.treeModel.items.push(item);
                }
              });
              const accountToDisplay = this.filterNonMovedItems(response.items);
              const initialCount = (index ==1) ? 0 : this.getInitialCount(response.items);
              this.updateAccountList(response.items);
              return accountToDisplay.slice(initialCount, initialCount + count);
            }));
        }
      },
      settings: {
        minIndex: 1,
        bufferSize: 6
      }
    });
  }
  
  private clientSideSearchFields(checkedBeforeNewSearch : string[]): TreeItem[] {
    const data: TreeItem[] = [];
    this.treeModel.items.forEach((item) => {
      const items = this.filterNonMovedItems(item.items);

      if (items.length > 0) {
        item.items.forEach(x => {
          const res = items.filter(i => i === x).shift();
          if (res) {
            data.push(res);
          } else {
            x.selected = checkedBeforeNewSearch.includes(x.id);
          }
        });
      } else {
        item.selected = checkedBeforeNewSearch.includes(item.id);
        item.items.forEach((x) => {
            x.selected = checkedBeforeNewSearch.includes(x.id);
        });
      }
    });

    return data;
  }

  onFieldsSearch() {
    if (this.searchOnKeyUp) {
      this.onSearch(this.searchKey);
    }
  }


  // virtual scroll is enabled only to flatdata as of now
  public onSearch = (searchKey: string) => {
    const checkedBeforeNewSearch = this.treeModel.getSelectedItems();
    const selectedIds = checkedBeforeNewSearch?.map(x=> x.id);
          
    this.searchKey = searchKey;
    this.treeModel.items.filter(x => x.hasChildren()).forEach(i => i.searchKey = searchKey);
    this.treeModel.searchKey = searchKey;
    this.treeModel.isTreeSeached = this.treeModel.searchKey !== '';
    if (this.searchOnKeyUp) {
      this.treeModel.items.forEach((x) => {
        x.isSearched = searchKey === '' ? false : true
      });
    }
    if (this.clientSideLoad) {
      if (this.treeModel.isFlatData && this.enableVirtualScroll) {
        this.virtualDatasource.adapter.reload(1);
      } else {
        this.clientSideSearch(searchKey);
        if (this.searchOnKeyUp) {          
          return of(this.clientSideSearchFields(selectedIds));
        }
      }
    } else {
      if (this.dataSource) {
        if (this.treeModel.isFlatData && this.enableVirtualScroll) {
          if(checkedBeforeNewSearch?.length ==0)
          {
          this.totalResult = 0 ;
          this.treeModel.items = [];
          }
          else
          {            
          this.totalResult = checkedBeforeNewSearch.length !== undefined ?  checkedBeforeNewSearch.length : 0  ;
          this.treeModel.items= this.treeModel.items.filter(x=> selectedIds.includes(x.id));          
          }
          this.virtualDatasource.adapter.reload(1);
        } 
        else {
          // Loading 100 only as of now
          this.dataSource(new PickListRequestModel(0, 100, searchKey)).subscribe((res: PagedTreeModel) => {
            this.setMovedFlag(res.items);
            this.treeModel.items = res.items;
          });
        }
      }
    }

    checkedBeforeNewSearch?.forEach(checkedText => {
      this.treeModel.items.filter(x => x.id === checkedText.id).forEach(i => i.selected = true);
     });               
    
  }

  public resetSearch() {
    this.searchBox.nativeElement.value = '';
    if (this.searchFieldBox != undefined) {
      this.searchFieldBox.nativeElement.value = '';
    }
    this.treeModel.isTreeSeached = false;
    this.treeModel.searchKey = '';
    this.searchKey = '';
    if (this.treeModel.isFlatData && this.enableVirtualScroll) {
      this.virtualDatasource.adapter.reload(1);
    }
  }

  private isSelectedItemsSearched() {
    return this.assignItemSearchKey && this.assignItemSearchKey !== '' ? true : false;
  }

  public onSearchSelectedItems(searhKey: string) {
    this.assignItemSearchKey = searhKey;
    this.selectedItems.forEach(item => {
      if (!this.isSelectedItemsSearched()) {
        item.isSearched = false;
      } else {
        if (!item.isHidden) {
          item.isSearched = (item.id.toLowerCase().includes(searhKey.toLowerCase()) ||
            item.name.toLowerCase().includes(searhKey.toLowerCase()));
        } else {
          item.isSearched = false;
        }
      }
    });
    this.checkDisplaySelectAll();
    this.onSelectSelectedItem();
  }

  private checkDisplaySelectAll() {
    this.displaySelectAll = this.selectAllAssigned && this.isSelectedItemsSearched()
      && this.getDisplayableItems(this.selectedItems).length > 0;
  }

  public onSelectAllSearchedData(e) {
    this.selectedItems.filter(x => !x.isHidden && x.isSearched).forEach(x => x.selectedToMove = e.target.checked);
    this.fewSelectedItemsChecked = false;
  }

  public onSelectSelectedItem() {
    this.allSelectedItemsChecked = this.isSelectedItemsSearched() && this.selectedItems.filter(x => !x.isHidden && x.isSearched).every(x => x.selectedToMove);
    this.fewSelectedItemsChecked = this.isSelectedItemsSearched() && !this.allSelectedItemsChecked &&
      this.selectedItems.some(x => !x.isHidden && x.isSearched && x.selectedToMove);
  }

  syncSelectedLists(index:number){
    this.selectedItems.forEach( item =>{
      if(!this.selectedAccountsList.has(item.id)){
        this.selectedAccountsList.set(item.id, true);
      }
    });

    this.selectedAccountsList.forEach((val, key) =>{
      if(index==1) {
        this.selectedAccountsList.set(key, true);
      }
      if(this.selectedItems.findIndex(a => a.id == key) == -1) {
        this.selectedAccountsList.delete(key);
      }
    });
  }

  updateAccountList(items: TreeItem[]){
    items.forEach(item =>{
      if(this.selectedAccountsList.has(item.id)){
        this.selectedAccountsList.set(item.id,!this.selectedAccountsList.get(item.id));
      }
    })
  }

  getInitialCount(items: TreeItem[]): number{
    let count =0;
    this.selectedAccountsList.forEach((val, key) =>{
      if(items.findIndex(item => item.id == key) == -1 && !val)
        count++;
    });
    return count;
  }
}
