import { Injectable } from '@angular/core';
import { NoAnalytics } from './no-analytics-service';


@Injectable()
export abstract class AnalyticsService {
  static source: string
  abstract initialize();

  /**
    * @param path /home
    * @param title Homepage
    * @param location '{ page_location }'
    * @param options '{ ... custom dimentions }'
    */
  abstract logPageView(path: string, title?: string, location?: string, options?: Object);

  /**
    * @param action 'video_auto_play_start'
    * @param category 'video_auto_play'
    * @param label 'My promotional video'
    * @param value An value to measure something
    */
  abstract logEvent(action: string, category?: string, label?: string, value?: number): void;

  /**
   * @param screen 'screen_name'
   * @param appName 'app_name'
   * @param appId 'app_id'
   * @param appVersion 'app_version'
   * @param installerId 'app_installer_id'
   */
  abstract logAppView(screen: string, appName: string, appId?: string, appVersion?: string, installerId?: string): void;
}

export namespace AnalyticsService {
  type Constructor<T> = {
    new(...args: any[]): T;
    readonly prototype: T;
  };
  const implementations: { [key: string]: Constructor<AnalyticsService> } =
  {
    [NoAnalytics.source]: NoAnalytics
  };
  export function GetImplementations(): { [key: string]: Constructor<AnalyticsService> } {
    return implementations;
  }
}
