import { Injectable } from '@angular/core';
import { FieldDescription } from 'src/app/features/results/shared/models/field-definition.model';
import { TranslateService } from '@ngx-translate/core';
import { SelectedItemModel } from 'libs/dop-pick-list/src/lib/selected-item.model';
import { FieldConfigurationService } from '../shared/field-configuration.service';
import { GridColumn } from 'src/app/shared/models/gridColumn.model';
import { ColumnCapability } from './../../../core/enums/column-capability';
import { Types } from 'src/app/core/enums/types';

@Injectable({
  providedIn: 'root'
})
export class ManageResultsViewServiceService {

  fieldDesc: FieldDescription[] = [];
  selectedItems: SelectedItemModel[] = [];
  gridColumns: GridColumn[] = [];

  constructor(
    private translateService: TranslateService,
    private fieldConfigurationService: FieldConfigurationService) {
    this.fieldDesc = [];
  }

  setGridHeaders(isInternalUser: boolean) {
    const viewCapability = { [ColumnCapability.Sort]: 'default', [ColumnCapability.Display]: { width: '130' }};
    const viewName = new FieldDescription('name', this.translateService.instant('eiResultViewName'), Types.Default, null, viewCapability);

    this.fieldDesc.push(viewName);

    const classificationCapability = {
      [ColumnCapability.Sort]: 'default', [ColumnCapability.Display]: { width: '150' },
      enum: {
        Shared: { icon: 'ei ei-users2 ei-color' }, Private: { icon: 'ei ei-user-lock ei-color' },
        Public: { icon: 'ei ei-users ei-color' }
      }, component: { template: 'icon' }
    };
    const classification = new FieldDescription(
      'classification', this.translateService.instant('eiClassification'), Types.Enum,
      {
        Shared: this.translateService.instant('eiShared'), Private: this.translateService.instant('eiPrivate'),
        Public: this.translateService.instant('eiPublic')
      }, classificationCapability);

    this.fieldDesc.push(classification);

    const templateCapability = { [ColumnCapability.Sort]: 'default', [ColumnCapability.Display]: { width: '200' } };
    const template = new FieldDescription('template', this.translateService.instant('eiGroupBy'), Types.Text, null, templateCapability);
    template.isDataTranslatable=true;

    this.fieldDesc.push(template);

    if (isInternalUser) {
      const assignedToCapability = {
        [ColumnCapability.Sort]: 'default',
        [ColumnCapability.Display]: { width: '200' },
        [ColumnCapability.Tooltip]: 'default'
      };
      const assignedTo = new FieldDescription('share.scope',
        this.translateService.instant('eiAssignedTo'),
        Types.Text, null, assignedToCapability);
      // TODO: change to assigned to
      this.fieldDesc.push(assignedTo);
    }

    const viewedOnCapability = { [ColumnCapability.Sort]: 'default', [ColumnCapability.Display]: { width: '200' } };
    const viewedOn = new FieldDescription('viewedOn', this.translateService.instant('eiLastViewed'), Types.Date, null, viewedOnCapability);

    this.fieldDesc.push(viewedOn);

    const createdByCapability = { [ColumnCapability.Sort]: 'default', [ColumnCapability.Display]: { width: '200' } };
    const createdBy = new FieldDescription('createdBy', this.translateService.instant('eiCreatedBy'), Types.Text, null,
      createdByCapability);

    this.fieldDesc.push(createdBy);

  }

  getColumnConfiguration(isInternalUser: boolean): GridColumn[] {
    this.setGridHeaders(isInternalUser);
    return this.fieldConfigurationService.getColumnConfiguration(null, this.fieldDesc);
  }
}
