import { Injectable, Output, EventEmitter } from '@angular/core';
import { Layout } from '../../shared/models/layout.model';
import { MenuItem } from 'primeng/api';

@Injectable()
export class LayoutService {

  @Output() layoutChanges: EventEmitter<Layout> = new EventEmitter();
  layout: Layout = {
    pageHeader: {
      pageTitle: '',
      pageIcon: '',
      iconLink: '',
    },
    breadcrumbItems: []
  } as Layout;

  constructor() { }

  setPageHeader(pageTitle: string, pageIcon: string, iconLink: string, confirmDialogRequired: boolean = false) {
    this.layout.pageHeader.pageTitle = pageTitle;
    this.layout.pageHeader.pageIcon = pageIcon;
    this.layout.pageHeader.iconLink = iconLink;
    this.layout.pageHeader.confirmDialogRequired = confirmDialogRequired;
    this.layoutChanges.emit(this.layout);
  }

  updateBreadcrumb(breadcrumbItems: MenuItem[]) {
    this.layout.breadcrumbItems = breadcrumbItems;
    this.layoutChanges.emit(this.layout);
  }

}
