import { TreeModel, TreeItem, PagedTreeModel, PickListRequestModel } from 'libs/dop-pick-list/src/lib/tree-model';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldDefinitionService } from '../services/fieldDefinition.service';
import { AccountSearchRequest, AccountSearchResponse } from '../../shared/models/search-result.model';
import { SelectedItemModel } from '../../../../../../libs/dop-pick-list/src/lib/selected-item.model';
import { PicklistTranslationModel } from '../../../../../../libs/dop-pick-list/src/lib/picklist-translation.model';
import { AuthService } from '../../../../core/services/auth.service';
import { EventService } from '../../../../core/services/event.service';
import { EventTypes } from '../../../../core/enums/event.types';
import { EventBuildNewResults } from '../../shared/models/eventBuildNewResults.model';
import { DopPickListComponent } from '../../../../../../libs/dop-pick-list/src/public-api';
import { EditResultViewEvent } from '../../shared/models/eventEditResultView.model';
import { AccessTypes } from '../../../../core/enums/access-types';
import { ResultsProfileService } from '../../shared/results-profile.service';
import { ResultViewAccess, ResultViewAccessDetails } from '../../shared/models/result-view-access.model';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

const timeOutInterval = 100;
const maxCount = 100;
@Component({
  selector: 'app-assign-accounts',
  templateUrl: './assign-accounts.component.html',
  styleUrls: ['./assign-accounts.component.scss']
})
export class AssignAccountsComponent implements OnInit, OnDestroy {
  allAccountOption = AccessTypes.AllAccount;
  selectAccountOption = AccessTypes.Account;
  publicOption = AccessTypes.Public;
  types: any;
  selectedItems: SelectedItemModel[] = [];
  treeAccounts: TreeModel = new TreeModel();
  pickListTranslations: PicklistTranslationModel = new PicklistTranslationModel();
  clientSideLoading = true;
  isInternal = false;
  canManagePublicViews = false;

  @Output() isEnabledContinue = new EventEmitter<boolean>();
  private openBuildNewResultViewSubscription: Subscription;
  private openEditResultViewSubscription: Subscription;
  private isUserDataLoadedSubscription: Subscription;
  @ViewChild('accountSelector') accountSelector: DopPickListComponent;
  private selectedAccessType?: string;
  get selectedType(): string {
    return this.selectedAccessType;
  }

  set selectedType(value: string) {
    if (this.selectedType === (AccessTypes.AllAccount || AccessTypes.Public) && value === AccessTypes.Account) {
      this.resetAccountSelections();
    }
    this.selectedAccessType = value;
  }

  constructor(
    private translateService: TranslateService,
    private fieldDefinitionService: FieldDefinitionService,
    private authService: AuthService,
    private eventService: EventService,
    private resultsProfileService: ResultsProfileService) {
    this.treeAccounts.id = 'accountsSelectionPickList';
    this.treeAccounts.items = [];

    this.openBuildNewResultViewSubscription = this.eventService.listen(EventTypes.BuildNewResultView).
      subscribe((eventData: EventBuildNewResults) => {
        if (!(eventData.isEdit && eventData.isPreview)) {
          this.resetAccountSelections(true);
        }

        if (!(this.treeAccounts && this.treeAccounts.items.length > 0)) {
          this.isUserDataLoadedSubscription = this.authService.isUserDataLoaded$.subscribe(isLoaded => {
            if (isLoaded) {
              this.getAccountTreeView();
            }
          });
        }
        
        if(!this.selectedType){
          this.updateSelectedType();
        }
        this.setTranslations();
      });

    this.openEditResultViewSubscription = this.eventService.listen(EventTypes.EditResultView).
      subscribe(_ => this.setTranslations());
  }

  private getAccountTreeView(callBack?: Function) {
    this.checkUserProfile();
    // For customer user client side will be enabled. For internal user server side will be called by passing observable to library
    if (this.clientSideLoading) {
      this.searchAccounts(new PickListRequestModel(0, 0)).subscribe((res: PagedTreeModel) => {
        this.treeAccounts.items = res.items;

        if (callBack) {
          callBack();
        }
      });
    } else {
      if (callBack) {
        callBack();
      }
    }
  }

  private resetAccountSelections(resetAccessType?: boolean) {
    this.selectedItems = [];
    if (resetAccessType) {
      this.selectedType = undefined;
    }
    this.treeAccounts.isTreeSeached = false;
    this.treeAccounts.searchKey = '';
    this.treeAccounts.items.forEach(item => {
      item.isMoved = false;
      item.isSearched = false;
    });

    if (this.accountSelector) {
      this.accountSelector.resetSearch();
    }
  }

  ngOnInit() {
    this.setTranslations();
  }

  private checkUserProfile() {
    const userDetail = this.authService.getUserDetails();
    this.clientSideLoading = userDetail.internal ? false : true;
    this.isInternal = userDetail.internal;
    this.canManagePublicViews = userDetail.canManagePublicViews;
    this.updateSelectedType();
    this.setDateValues();
  }

  private updateSelectedType(){
    if(this.isInternal && !this.canManagePublicViews) {
      this.selectedType = this.selectAccountOption;
    }
  }

  private setTranslations() {
    this.translateService.get(['eiSearchForAccounts', 'eiAddAll', 'eiRemoveAll', 'eiSelectedAccounts', 'eiSearchWithAccountCodeOrNameOrCity', 'eiSeachTextNote','eiSelectAll','eiSearch'])
      .subscribe((res) => {

        this.pickListTranslations = {
          availableFieldsTitle: res.eiSearchForAccounts,
          addAllTitle: res.eiAddAll,
          removeAllTitle: res.eiRemoveAll,
          selectedItemsTitle: res.eiSelectedAccounts,
          searchBackgroundText: res.eiSearchWithAccountCodeOrNameOrCity,
          searchTextNote: res.eiSeachTextNote,
          selectAll:res.eiSelectAll,
          search:res.eiSearch
        };
      });
  }

  setDateValues() {
    this.translateService.get(['eiAssignToAllAccounts', 'eiAssignToAllUsers', 'eiAssignSelectedAccounts'])
      .subscribe(translations => {
        this.types = [
          {
            value: 1, label: this.isInternal ? translations.eiAssignToAllUsers : translations.eiAssignToAllAccounts,
            code: this.isInternal ? this.publicOption : this.allAccountOption
          },
          { value: 2, label: translations.eiAssignSelectedAccounts, code: this.selectAccountOption }
        ];
      });
  }

  public searchAccounts = (requestModel: PickListRequestModel): Observable<PagedTreeModel> => {
    const searchRequest = new AccountSearchRequest(requestModel.pageOffset,
      requestModel.pageSize, requestModel.searchKey, requestModel.excludeList);
    return this.fieldDefinitionService.getAccounts(searchRequest)
      .pipe(
        map((response: AccountSearchResponse) => {
          if (!response.data && response.data.length === 0) {
            return;
          }

          const items: TreeItem[] = [];
          const item = response.data[0].properties;
          Object.getOwnPropertyNames(item).forEach((code: string) => {
            const treeItem = new TreeItem();
            treeItem.id = code;
            treeItem.name = `${item[code]} (${code})`;
            treeItem.dataItem = { code, name: treeItem.name };
            items.push(treeItem);
          });
          return new PagedTreeModel(response.pagination.count, items);
        }));
  }

  public editResults(eventData: EditResultViewEvent) {
    if (this.treeAccounts.items && this.treeAccounts.items.length === 0) {
      this.getAccountTreeView(_ => this.editResultView(eventData));
    } else {
      this.editResultView(eventData);
    }
  }

  private editResultView(eventData: EditResultViewEvent) {
    if (eventData.selectedItems.code || eventData.selectedItems.selectedViewCodeToClone) {
      this.selectedItems = [];
      const user = this.authService.getUserDetails();
      if (user.internal) {
        this.setAccountsForInternalUser(eventData);
      }
      else {
        this.setAccountsForExternalUser(eventData);
      }
    }
    else {
      this.resetAccountSelections(true);
    }
  }

  private setAccountsForInternalUser(eventData: EditResultViewEvent) {
    if (this.treeAccounts) {
      this.treeAccounts.items = [];
      this.treeAccounts.isTreeSeached = false;
    }

    if (this.accountSelector) {
      this.accountSelector.resetSearch();
    }

    if (eventData.selectedItems.accessType === AccessTypes.Account || !this.canManagePublicViews) {
      this.selectedType = AccessTypes.Account;
      this.resultsProfileService.getResultViewAccessDetails(
        eventData.selectedItems.code ?? eventData.selectedItems.selectedViewCodeToClone, maxCount)
        .subscribe((viewAccessResponse: ResultViewAccessDetails) => {

          viewAccessResponse.items.forEach(item => {
            setTimeout(this.setItems, timeOutInterval, item.code, `${item.name} (${item.code})`, this);
          });

        });
    } else {
      this.selectedType = AccessTypes.Public;
    }
  }

  private setAccountsForExternalUser(eventData: EditResultViewEvent) {
    if (eventData.selectedItems.accessType === AccessTypes.Account) {
      this.selectedType = eventData.selectedItems.accessType;
      this.resultsProfileService.getResultViewAccess(eventData.selectedItems.code ?? eventData.selectedItems.selectedViewCodeToClone)
        .subscribe((viewAccessResponse: ResultViewAccess) => {

          this.resetAccountSelections();
          const selectedAccounts = viewAccessResponse.items.map(item => {
            const obj = {};
            obj[item] = this.treeAccounts.items.find(x => x.id === item)?.name ?? item;
            return obj;
          });

          this.selectedItems = this.treeAccounts.moveSelectedItemById(this.treeAccounts.items, selectedAccounts, null);
          this.accountSelector.setTreeModel(this.treeAccounts, true);
        });
    } else {
      this.selectedType = AccessTypes.AllAccount;
    }
  }

  private setItems(code: string, name: string, that: any) {
    const selectedItem = new SelectedItemModel();
    selectedItem.id = code;
    selectedItem.name = name.indexOf(code) > -1 ? name : `${name} (${code})`;
    selectedItem.isHidden = false;
    selectedItem.dataItem = Object.assign({ isHidden: false }, { code, name });
    that.selectedItems.push(selectedItem);
  }

  onSelectedItemsChange(value: SelectedItemModel[]) {
    this.isEnabledContinue.emit(value.length > 0);
  }

  public onApply(): void {
    // TODO : map selected accounts to save request
  }


  ngOnDestroy(): void {
    if (this.openBuildNewResultViewSubscription) {
      this.openBuildNewResultViewSubscription.unsubscribe();
    }
    if (this.openEditResultViewSubscription) {
      this.openEditResultViewSubscription.unsubscribe();
    }
    if (this.isUserDataLoadedSubscription) {
      this.isUserDataLoadedSubscription.unsubscribe();
    }
  }
}
