import { Component, Input, Output, TemplateRef, EventEmitter, OnDestroy } from '@angular/core';
import { PullDownView } from '../../features/results/shared/models/pull-down.model';
import { EventService } from 'src/app/core/services/event.service';
import { EventTypes } from 'src/app/core/enums/event.types';
import { ContentTemplate } from '../../features/results/resultView/pullDownData-event.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-header',
  templateUrl: './app-page-header.component.html',
  styleUrls: ['./app-page-header.component.scss']
})
export class AppPageHeaderComponent implements OnDestroy {

  @Input() title: string;
  @Input() icon: string;
  @Input() iconLink: string;
  @Input() pulldownitems: PullDownView[];
  @Input() displayTemplate: boolean;
  @Output() displayTemplateChange = new EventEmitter<boolean>();
  templateContent: TemplateRef<any>;
  renderPageHeaderContentSubcription: Subscription;


  constructor(
    private eventService: EventService) {

    this.renderPageHeaderContentSubcription = eventService.listen(EventTypes.RenderPageHeaderContent)
    .subscribe((template: ContentTemplate) => {
      this.templateContent = template.templateContent;
      this.displayTemplate = template.displayTemplate;
      this.displayTemplateChange.emit(true);
    });

  }
  ngOnDestroy(): void {
    this.renderPageHeaderContentSubcription.unsubscribe();
  }
}
