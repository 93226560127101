import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private httpClient: HttpClient) {
  }

  get<TResponse>(apiUrl: string, includeFullResponse: boolean = false, options?: any): Observable<TResponse> {
    return this.httpClient.get<TResponse>(apiUrl, options).pipe(map((res: any) => {
      return includeFullResponse ? res as TResponse : res.data as TResponse;
    }));
  }

  post<TRequest, TResponse>(apiUrl: string, request: TRequest, includeFullResponse: boolean = false, options?: any): Observable<TResponse> {
    return this.httpClient.post<TResponse>(apiUrl, request, options).pipe(map((res: any) => {
      return includeFullResponse ? res as TResponse : res.data as TResponse;
    }));
  }


  put<TRequest, TResponse>(apiUrl: string, request: TRequest, includeFullResponse: boolean = false, options?: any): Observable<TResponse> {
    return this.httpClient.put<TResponse>(apiUrl, request, options).pipe(map((res: any) => {
      return includeFullResponse ? res as TResponse : res.data as TResponse;
    }));
  }

  putRouteObject<TResponse>(apiUrl: string, includeFullResponse: boolean = false, options?: any): Observable<TResponse> {
    return this.httpClient.put<TResponse>(apiUrl, null, options).pipe(map((res: any) => {
      return includeFullResponse ? res as TResponse : res.data as TResponse;
    }));
  }

  delete<TRequest>(apiUrl: string, request: TRequest) {
    return this.httpClient.delete<TRequest>(apiUrl, request).pipe();
  }

  head(apiUrl: string) {
    return this.httpClient.head(apiUrl).pipe();
  }
}
