import { Injectable } from "@angular/core";
import { ManageResultView } from "./models/manage-result-view.model";
import { EditResultViewEvent } from 'src/app/features/results/shared/models/eventEditResultView.model';
import { EventService } from '../../../core/services/event.service';
import { EventTypes } from '../../../core/enums/event.types';
import { ViewConfiguration } from "./models/result-viewconfiguration.model";
import { EditResultViewModel } from "./models/editResultView.model";
import { ResultsProfileService } from "./results-profile.service";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpStatusCode } from "../../../core/enums/HttpStatusCode";
import { FieldConfigurationService } from "./field-configuration.service";

@Injectable({
  providedIn: 'root'
})

export class ResultsKebabService {

  constructor(
    private resultsProfileService: ResultsProfileService,
    private eventService: EventService,
    private editViewModel: EditResultViewModel,
    private fieldConfigurationService: FieldConfigurationService
  ) {
    editViewModel = new EditResultViewModel();
  }

  loadViewModal(data: ManageResultView, isAssign: boolean): void {
    this.editViewModel.name = data.name;
    this.editViewModel.accessType = data.share?.type;
    if (data.permissions.edit) {
      this.editViewModel.code = data.code;
      this.resultsProfileService.getFieldConfiguration(data.code)
        .subscribe((response: ViewConfiguration) => {
          this.editViewModel.fieldConfig = response.configuration;
          this.editViewModel.default = data.default;
          this.broadCastEvent(this.editViewModel, true, false, false, isAssign);
        });
    }
  }

  copyResultView(configurationGuid: string, isAssignEventTriggered: boolean, retianFilter = false, accessType?: string): boolean {
    this.editViewModel.accessType = accessType ?? this.editViewModel.accessType;
    if (!retianFilter || !this.fieldConfigurationService.getViewConfiguration()?.configuration) {
      this.resultsProfileService.getFieldConfiguration(configurationGuid)
        .subscribe((response: ViewConfiguration) => {
          this.editViewModel.fieldConfig = response.configuration;
          this.buildEditViewModel(configurationGuid, isAssignEventTriggered);
        },
          (error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.NOT_FOUND) {
              return true;
            }
          });
    } else {
      this.editViewModel.fieldConfig = this.fieldConfigurationService.getViewConfiguration().configuration;
      this.buildEditViewModel(configurationGuid, isAssignEventTriggered);
    }

    return false;
  }

  private buildEditViewModel(configurationGuid: string, isAssignEventTriggered: boolean) {
    this.editViewModel.default = false;
    this.editViewModel.code = null;
    this.editViewModel.name = null;
    this.editViewModel.selectedViewCodeToClone = configurationGuid;
    this.broadCastEvent(this.editViewModel, true, true, false, isAssignEventTriggered);
    return false;
  }

  private broadCastEvent(data: any, isEdit: boolean, isPreview: boolean, isReset: boolean, isAssign?: boolean) {
    const eventData = new EditResultViewEvent();
    eventData.isEdit = isEdit;
    eventData.isPreview = isPreview;
    eventData.isReset = isReset;
    eventData.isAssign = isAssign;
    eventData.selectedItems = data;
    this.eventService.broadCast(EventTypes.EditResultView, eventData);
  }
}
