import { EventBaseData } from '../../../../shared/models/event.model';
import { EditResultViewModel } from './editResultView.model';

export class EditResultViewEvent extends EventBaseData {
  isEdit: boolean;
  isPreview: boolean;
  selectedItems?: EditResultViewModel;
  isReset: boolean;
  isAssign?: boolean;
}
