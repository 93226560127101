<div class="manage-results-view">
  <div class="fixed-top flex-body">
    <button class="align-right" pButton icon="ei ei-plus" label="{{'eiBuildNew'|translate}}" iconPos="left"
      (click)="openNewResultViewModal()" type="button" appAEvent
      appACategory="NRE_BuildNewResultsView_AccessBuildNewResultsView" appAAction="click">
    </button>
  </div>
  <div class="scrollable-content">
    <div class="p-grid-row" id="manage-grid">
      <p-table #viewsGridTable [columns]="colHeader" [value]="views" dataKey="Code" [resizableColumns]="true"
        [reorderableColumns]="true" [scrollable]="true" scrollHeight="flex">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <ng-container *ngFor="let col of columns">
              <col [style.width.px]="col.width">
            </ng-container>
            <col style="width:52px" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns">
              <th [pSortableColumn]="col.field" [pSortableColumnDisabled]="true" pResizableColumn pReorderableColumn
                [ngClass]="{ 'p-sortable-column':col.sort, 'p-highlight': (col.sortStatus !== sortType.Neutral)}"
                class="p-sortable-column p-highlight" [ngStyle]="{'text-align':col.type === types.Enum ? '' : ''}"
                (click)="customSort(col,$event)">
                <span>
                  {{col.header}}
                  <span *ngIf="col.sort" class="flex-inline-body m-l-4"
                    [ngClass]="{'m-r-auto' : col.type !== types.Text}">
                    <i class="p-sortable-column-icon {{col.sortStatus}}"></i>
                  </span>
                </span>
              </th>
            </ng-container>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-view let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [ngStyle]="{'text-align':col.type === types.Enum ? '' : ''}">
              <ng-container [ngSwitch]="col.type">
                <span *ngSwitchCase="types.Enum">
                  <app-enum-type [enumValue]="view[col.field]" [fieldDescription]="resolveDescription(col.field)"
                    [displayText]=true></app-enum-type>
                </span>
                <span *ngSwitchCase="types.Date">
                  <app-date-type [value]="view[col.field]" [isTimeBeEnabled] = "false"></app-date-type>
                </span>
                <span class="break-space" *ngSwitchCase="types.Text">
                  <span class="break-word" *ngIf="!canDisplayTooltip(col,view)">{{getDisplayText(view,col)}}</span>
                  <span class="break-word" *ngIf="canDisplayTooltip(col,view)"
                    [libDopTooltipDirective]="loadTooltipContent(view)">{{getDisplayText(view,col)}}</span>
                </span>
                <span *ngSwitchDefault><span style="cursor: pointer;" (click)="getResultConfiguration(view.code)"
                    id={{view.name}}>
                    <span style="color:#0072BC">{{view.name}}</span>&nbsp;<i *ngIf="view.default" class="default-label">
                      {{'eiDefault'|translate}}</i>
                  </span></span>
              </ng-container>
            </td>
            <td id="{{view.name + '_menu'}}" class="text-right">
              <app-kebab-menu [menuItems]='getMenuItems(view)'></app-kebab-menu>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <app-confirm-dialog [openDialog]="isOpen" (openDialogChange)="onCloseDailog($event)"
    (confirmYes)="onYesButtonClickEvent($event)"  (confirmNo)="onCloseDailog($event)"
     header="{{'eiConfirmationModalHeader'|translate}}" [scrollable]="false">{{'eiDeleteConfirmationMessage'|translate}}
  </app-confirm-dialog>
  <app-confirm-dialog [openDialog]="isCopyDialogOpen" (openDialogChange)="onCloseDailog($event)"
    (confirmYes)="onCopyResultClick()"  (confirmNo)="onCloseDailog($event)"
     header="{{'eiConfirmationModalHeader'|translate}}" [scrollable]="false">{{'eiCopyViewMessage'|translate}}
  </app-confirm-dialog>
  <app-confirm-dialog [openDialog]="isEditDialogOpen" (openDialogChange)="onCloseDailog($event)"
    (confirmYes)="onEditResultClick()"  (confirmNo)="onCloseDailog($event)"
    header="{{'eiConfirmationModalHeader'|translate}}" [scrollable]="false">{{'eiForceEditMessage'|translate}}
</app-confirm-dialog>
  <p-dialog [(visible)]="openErrorDialog" [modal]="true" [style]="{width: '480px'}" [closable]="false">
    <p-header>
      <i class="ei ei-warning orange"></i>&nbsp;
      {{'eiConfirmationModalHeader'|translate}}
    </p-header>
    <span *ngIf='unableToSetDefaultview'>
      <p>{{'eiErrorSetDefaultView'|translate}}</p>
    </span>
    <span *ngIf='viewNotAvailble'>
      <p>{{'eiErrorLoadView'|translate}}</p>
    </span>
    <p-footer>
      <button pButton type="button" (click)="onOkClick()">
        {{'eiOk'|translate}}
      </button>
    </p-footer>
  </p-dialog>
</div>
