<p-dialog [(visible)]="openDialog" [modal]="true" [responsive]="true" [style] = "{'width': width, 'height': height}" (onHide)="resetDialog()" [baseZIndex]="2500" appendTo="body" [styleClass]="'confirm-dialog'">
  <p-header>
    <i class="ei ei-warning orange "></i>&nbsp;
    {{header}}
  </p-header>
  <div id="cnf-dialog-content" [ngStyle]="{'height' : scrollable ? (dialogHeight + 'px') : 'inherit'}"
    [ngClass]="{'overflow' : scrollable}">
    <ng-content></ng-content>
  </div>
  <p-footer>
    <div class="text-right">
      <button id="cnf-dialog-yes" pButton type="button"  (click)="confirmYesClick()" [disabled] = "positiveButtonDisabled"
      [pTooltip]="toolTipMessage | translate" [tooltipDisabled]="!positiveButtonDisabled" tooltipPosition="top">
          {{positiveButtonLabel|translate}}
      </button>
      <button id="cnf-dialog-no" pButton type="button" class="p-button-tertiary"  (click)="confirmNoClick()">
          {{negativeButtonLabel|translate}}
      </button>
    </div>
  </p-footer>
</p-dialog>