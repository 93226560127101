import { Injectable } from '@angular/core';
import { ActionTypes } from './ngrx-store.actions';
import { AsyncSubject, Observable } from 'rxjs';
import { StoreKey } from './ngrx-store.keys';
import { Store } from '@ngrx/store';
import { FieldSearchResponse } from 'src/app/features/results/shared/models/filter-definition.model';
export const StoreKeys = StoreKey;

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    constructor(private store: Store<{ state: any }>) { }

    Get(id: StoreKey): Observable<any> {
        const subject = new AsyncSubject();
        this.getValue(id).subscribe(data => {
            subject.next(data);
        });
        subject.complete();
        return subject;
    }

    GetByCode(code: string): Observable<FieldSearchResponse> {
        const subject = new AsyncSubject<FieldSearchResponse>();
        this.getValue(undefined, code).subscribe(data => {
            subject.next(data);
        });
        subject.complete();
        return subject;
    }

    GetMany(ids: StoreKey[]): Observable<any> {
        const returnData: any = {};
        const subject = new AsyncSubject();
        for (const id of ids) {
            this.getValue(id).subscribe(data => returnData[StoreKey[id]] = data);
        }
        subject.next(returnData);
        subject.complete();
        return subject;
    }

    Save(id: StoreKey, data: any, isAppLevel?: boolean) {
        const payload: any = {};
        payload[StoreKey[id]] = { data, isAppLevel: isAppLevel ? isAppLevel : false };
        this.store.dispatch({ type: ActionTypes.save.toString(), payload });
    }

    SaveByCode(code: string, data: FieldSearchResponse, isAppLevel?: boolean) {
        const payload: any = {};
        payload[code] = { data, isAppLevel: isAppLevel ? isAppLevel : false };
        this.store.dispatch({ type: ActionTypes.save.toString(), payload });
    }

    private getValue(id: StoreKey, code = ''): Observable<any> {
        const input = StoreKey[id] ?? code;
        const subject = new AsyncSubject();
        this.store.select((s: any) => s.state[input])
            .subscribe((state: any) => {
                if (state) {
                    const data = state.data;
                    subject.next(data);
                    subject.complete();
                    if (state.isAppLevel === false) {
                        this.store.dispatch({ type: ActionTypes.delete.toString(), payload: input });
                    }
                } else {
                    subject.complete();
                }
            }).unsubscribe();
        return subject;
    }
}
