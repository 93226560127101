import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Injector, inject, InjectionToken } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { StepsModule } from 'primeng/steps';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { LayoutService } from './core/services/layout.service';
import { ResultsCommonModule } from './common/common.module';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { OAuthModule } from 'angular-oauth2-oidc';
import { StoreModule } from '@ngrx/store';
import { RootReducer } from './core/store/ngrx-store.reducer';
import { AuthGuardMessageService } from './core/guards/auth-guard.message.service';
import { AuthService } from './core/services/auth.service';
import { StoreService } from './core/store/ngrx-store.service';
import { TokenInterceptor } from './core/interceptor/token-interceptor';
import { InterceptorMessageService } from './core/interceptor/interceptor.message.service';
import { BuildNewResultViewModule } from './features/results/buildNewResultView/buildNewResultView.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './core/services/app-config-service';
import { CustomTranslationLoader } from './shared/translations/custom-translation-loader';
import { DataService } from './core/services/data.service';
import { ManageResultViewsModule } from './features/results/manageResultViews/manageResultViews.module';
import { RequestCache } from './core/interceptor/request-cache.service';
import { EventService } from './core/services/event.service';
import { LOCALE_ID } from '@angular/core';
import { DynamicLocaleIdProvider } from './shared/localizer/dynamic-localeId-provider';
import { AnalyticsService } from './core/analytics/analytics-service';
import { NoAnalytics } from './core/analytics/no-analytics-service';
import { CookieService } from 'ngx-cookie-service';
import { DopSessionRevocationModule } from 'libs/dop-session-revocation/src/public-api';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { MATOMO_CONFIGURATION, MatomoModule } from 'ngx-matomo';
import { ErrorHandlerInterceptor } from './core/interceptor/error-handler.interceptor';
import { ChartService } from './features/results/graphsAndCharts/charts/chart.service';

export let AppInjector: Injector;

const initConfig = (authService: AuthService) => {
  return () => {
    return authService.login();
  };
};

export const MATOMO_CONFIG = new InjectionToken<any>('My Backend Configuration');

const getServiceInstance = (appConfigService: AppConfigService, authService: AuthService, injector: Injector) => {
  const implementations = AnalyticsService.GetImplementations();
  if (appConfigService.configuration.analyticsSource
    && implementations.hasOwnProperty(appConfigService.configuration.analyticsSource)
    && implementations[appConfigService.configuration.analyticsSource]
    && authService.getUserDetails()?.trackingConsent) {
    // If User is disagreed to log analytics? return NoAnalyticsService instance with source 'None'
    return injector.get(implementations[appConfigService.configuration.analyticsSource]);
  } else {
    return injector.get(NoAnalytics);
  }
}

const rxjsServices = [TokenInterceptor, InterceptorMessageService, ErrorHandlerInterceptor];

export function matomoConfigFactory(matomoConfig: any) {
  return matomoConfig;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    StoreModule.forRoot({ state: RootReducer }),
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    StepsModule,
    BreadcrumbModule,
    ResultsCommonModule,
    SharedModule,
    DialogModule,
    BuildNewResultViewModule,
    ManageResultViewsModule,
    SatPopoverModule,
    TranslateModule.forRoot({
      loader: {

        provide: TranslateLoader,
        useClass: CustomTranslationLoader,
        deps: [HttpClient, AppConfigService, DataService, EventService]
      }, extend: true
    }),
    DopSessionRevocationModule.forRoot(),
    MatomoModule.forRoot()
  ],
  providers: [
    CookieService,
    LayoutService,
    StoreService,
    AuthGuardMessageService,
    ...rxjsServices,
    AuthService,
    RequestCache,
    ChartService,
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AuthService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useClass: DynamicLocaleIdProvider, deps: [AppConfigService] },
    { provide: AnalyticsService, useFactory: getServiceInstance, deps: [AppConfigService, AuthService, Injector] },
    {
      provide: MATOMO_CONFIGURATION,
      useFactory: matomoConfigFactory,
      deps: [MATOMO_CONFIG]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector = injector;
  }
}

