import { NgModule } from '@angular/core';
import { DateTypeComponent } from './date-type.component';
import { CommonModule } from '@angular/common';
import { CustomDatePipeModule } from 'src/app/common/pipes/custom-date-pipe.module';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  declarations: [DateTypeComponent],
  imports: [CommonModule, CustomDatePipeModule, TooltipModule],
  exports: [DateTypeComponent]
})
export class DateTypeModule { }
