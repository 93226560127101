import { EventBaseData } from '../../../shared/models/event.model';
import { ServiceMethod } from '../../../core/enums/service-method';

export class ErrorEventModel extends EventBaseData {
  constructor(
    public serviceMethod: ServiceMethod,
    public message?: string,
    public title?: string,
    public cancelable?: boolean,
    public proceedable?: boolean,
    public isTechnicalError?: boolean,
    public isRead?: boolean) {
    super(isRead);
  }
}
