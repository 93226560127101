export enum StoreKey {
  // Add the Store Keys below
  UserDetails,
  UserPermission,
  Definitions,
  ParameterTrendFilterDefinitions,
  ParameterTrendPrefDefinitions,
  Fields,
  SampleDetailFields,
  OrderDetailFields,
  ShowAggregatedColumnsinDownload,
  UseSpecificExport,
  UseComplianceAssessment,
  EnableGraphsAndCharts,
  EnableParameterTrend,
  EnableResultConformity,
  EnableReceivedSampleStatus,
  GridData
}
