import { SelectedItemModel } from './selected-item.model';

export class TreeModel {
  id: string;
  isFlatData?= false;
  isTreeSeached?= false;
  searchKey?= '';
  items: TreeItem[] = [];

  public mapSelectedItem?(item: TreeItem, parentId: string): SelectedItemModel {
    const selectedItem = new SelectedItemModel();
    selectedItem.id = item.id;
    selectedItem.name = item.name;
    selectedItem.parentId = parentId;
    selectedItem.dataItem = Object.assign({ isHidden: false }, item.dataItem);
    return selectedItem;
  }

  public moveSelectedItemById?(items: TreeItem[], fieldIds: { [key: string]: boolean }[], pid: string) {
    let dataitems: SelectedItemModel[] = [];
    items.forEach((item: TreeItem) => {
      if (fieldIds.some(field => item.id === Object.getOwnPropertyNames(field)[0] && field[item.id]) ) {
        item.isMoved = true;
        const dataItem = this.mapSelectedItem(item, pid);

        if (fieldIds.some(field => item.id === Object.getOwnPropertyNames(field)[0] && !field[item.id])) {
          dataItem.isHidden = true;
          dataItem.dataItem.isHidden = true;
          item.isMoved = false;
        } else {
          item.isMoved = true;
          dataItem.isHidden = false;
          dataItem.dataItem.isHidden = false;
        }
        dataitems.push(dataItem);
      }
      if (item.items.length > 0) {
        dataitems = dataitems.concat(this.moveSelectedItemById(item.items, fieldIds, item.id));
      }
    });
    const selectedFieldIds = fieldIds.map(x => Object.getOwnPropertyNames(x)[0]);
    dataitems.sort((a, b) => {
      return selectedFieldIds.indexOf(a.id) - selectedFieldIds.indexOf(b.id);
    });
    return dataitems;
  }

  private getSelectedChildren?(items: TreeItem[], selectedDataItems: SelectedItemModel[], parentid: string) {
    items.forEach((item: TreeItem) => {
      if ((this.isFlatData || item.level > 1) && item.selected && !item.isMoved) {
        selectedDataItems.push(this.mapSelectedItem(item, parentid));
      }
      if (item.hasChildren()) {
        this.getSelectedChildren(item.items, selectedDataItems, item.id);
      }
    });
  }
  getSelectedItems?() {
    const selectedItems: SelectedItemModel[] = [];
    this.getSelectedChildren(this.items, selectedItems, null);
    return selectedItems;
  }
  private getAvailableChildren?(items: TreeItem[], availableItems: TreeItem[]) {
    items.forEach((item: TreeItem) => {
      if ((this.isFlatData || item.level > 1) && !item.isMoved) {
        availableItems.push(item);
      }
      if (item.hasChildren()) {
        this.getAvailableChildren(item.items, availableItems);
      }
    });
  }
  getAvailableItems?() {
    const availableItems: TreeItem[] = [];
    this.getAvailableChildren(this.items, availableItems);
    return availableItems;
  }

  private resetDraftMode?(items: TreeItem[], confirm: boolean) {
    items.forEach((item: TreeItem) => {
      if (confirm) {
        item.isAddedInDraft = false;
        item.isRemovedInDraft = false;
      } else {
        if (item.isAddedInDraft) {
          item.isMoved = false;
        }
        else if (item.isRemovedInDraft) {
          item.isMoved = true;
        }
      }
      if (item.hasChildren()) {
        this.resetDraftMode(item.items, confirm);
      }
    });
  }

  private getMovedItems?(items: TreeItem[], movedItems: SelectedItemModel[], parentid: string) {
    items.forEach((item: TreeItem) => {
      if ((this.isFlatData || item.level > 1) && item.isMoved && !movedItems.some(x => x.id === item.id)) {
        movedItems.push(this.mapSelectedItem(item, parentid));
      }
      if (item.hasChildren()) {
        this.getMovedItems(item.items, movedItems, item.id);
      }
    });
  }

  confirmMovement?(): SelectedItemModel[] {
    this.resetDraftMode(this.items, true);
    const selectedItems: SelectedItemModel[] = [];
    this.getMovedItems(this.items, selectedItems, null);
    return selectedItems;
  }

  cancelMovement?() {
    this.resetDraftMode(this.items, false);
    const selectedItems: SelectedItemModel[] = [];
    this.getMovedItems(this.items, selectedItems, null);
    return selectedItems;
  }
  private resetTreeItemsToDefault?(items: TreeItem[]) {
    items.forEach((item: TreeItem) => {
      item.isMoved = false;
      item.selected = false;
      item = Object.assign(new TreeItem(), item);
      if (item.hasChildren()) {
        this.resetTreeItemsToDefault(item.items);
      }
    });
  }

  resetTreeItems?() {
    this.resetTreeItemsToDefault(this.items);
  }
}

export class PickListRequestModel {
  constructor(
    public pageOffset: number,
    public pageSize: number,
    public searchKey = '',
    public excludeList: string[] = []) {
  }
}

export class PagedTreeModel {
  constructor(
    public totalCount: number,
    public items: TreeItem[]) {
  }
}

export class TreeItem {
  id: string;
  name: string;
  items: TreeItem[] = [];
  selected?: boolean;
  dataItem?: any;
  expanded?= true;
  indeterminated?: boolean;
  level?: number;
  isMoved?= false;
  isSearched?= false;
  isAddedInDraft?: boolean;
  isRemovedInDraft?: boolean;
  private isSelected?: boolean;
  searchKey?: string;
  hasChildren?() {
    return this.items && this.items.length > 0;
  }

  onSelect?(e: any, treeModel: TreeModel = null, searchKey = '') {
    this.items.forEach((item: TreeItem) => {
      if (this.isSearchKeyAvailable() && !item.hasChildren()) {
        item.selected = item.isSearched ? e.target.checked : false;
      } else {
        item.selected = e.target.checked;
      }
    });
    
    if (treeModel) {
      treeModel.items.find(x => x.id === this.id).selected = e.target.checked;
    }
    return this.dataItem;
  }
  onToggle?() {
    if (this.items.length > 0) {
      this.expanded = !this.expanded;
    }
  }
  allChildrenMoved?(): boolean {
    const childrenMoved = this.items.filter(x => x.isMoved);
    return childrenMoved !== null ? this.items.length === childrenMoved.length : false;
  }

  hasSearched?(searchKey: string): boolean {
    const childrenSearched = this.items.filter(x => x.isSearched);
    return searchKey ==='' ? true : childrenSearched?.length > 0 ? true : false;
  }

  isSearchKeyAvailable?(): boolean {
    return this.searchKey !== undefined && this.searchKey !== '';
  }

  constructor() {
    Object.defineProperty(this, 'selected', {
      get() { 
        return !this.hasChildren() ? this.isSelected :
        this.isSearchKeyAvailable() ? 
        this.items.filter(x => x.isSearched).every((item: TreeItem) => {
          return item.selected;
        }) :       
        this.items.every((item: TreeItem) => {
          return item.selected;
        });
      },
      set(value: boolean) {
        this.isSelected = value;
      }
    });
    Object.defineProperty(this, 'indeterminated', {
      get() {
        if (!this.hasChildren()) {
          return false;
        }
        let selectedItems = this.items.filter(x => x.isSelected).length;
        if (this.isSearchKeyAvailable()) {
          selectedItems = this.items.filter(x => x.isSearched && x.isSelected).length;
          return selectedItems > 0 && selectedItems !== this.items.filter(x => x.isSearched).length;
        }
        return selectedItems > 0 && selectedItems !== this.items.length;
      }
    });

  }

}
