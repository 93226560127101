import { Injectable } from '@angular/core';
import { StoreKey } from 'src/app/core/store/ngrx-store.keys';
import { StoreKeys, StoreService } from 'src/app/core/store/ngrx-store.service';
import { EventTypes } from '../../../../core/enums/event.types';
import { GridTemplate } from '../../../../core/enums/grid-template';
import { EventService } from '../../../../core/services/event.service';
import { SystemDataLoadEvent } from '../../../../shared/models/system-data-event.model';
import { FieldConfigurationService } from '../../shared/field-configuration.service';
import { Definitions } from '../../shared/models/definitions';
import { FieldDescription } from '../../shared/models/field-definition.model';
import { FieldDefinitionService } from './fieldDefinition.service';
import { ResultsDefinitions } from '../../shared/models/graph-models/results-filter.model';
import { PreferenceDefinition } from '../../shared/models/graph-models/preference-definition.model';

@Injectable()
export class FieldDefinitionCachedService {

  constructor(
    private storeService: StoreService,
    private readonly fieldDefinitionService: FieldDefinitionService,
    private readonly eventService: EventService,
    private fieldConfigurationService: FieldConfigurationService
  ) { }


  async getDefinitions(): Promise<Definitions> {
    return await this.storeService.Get(StoreKey.Definitions).toPromise().then(async data => {
      if (!data) {
        return await this.fieldDefinitionService.getDefinitions().toPromise().then((res: Definitions) => {
          this.storeService.Save(StoreKey.Definitions, res, true);
          return res;
        });
      }

      return data;
    });
  }

  async getGraphDefinitions(templateName: string, key: StoreKey): Promise<ResultsDefinitions> {
    return await this.storeService.Get(key).toPromise().then(async data => {
      if (!data) {
        return await this.fieldDefinitionService.getDefinitionsForGraph(templateName).toPromise().then((res: ResultsDefinitions) => {
          this.storeService.Save(key, res, true);
          return res;
        });
      }

      return data;
    });
  }

  async getGraphPreferences(templateName: string, key: StoreKey): Promise<PreferenceDefinition> {
    return await this.storeService.Get(key).toPromise().then(async data => {
      if (!data) {
        return await this.fieldDefinitionService.getPreferencesForGraph(templateName).toPromise().then((res: PreferenceDefinition) => {
          this.storeService.Save(key, res, true);
          return res;
        });
      }

      return data;
    });
  }

  setDefinitions() {
    this.getDefinitions().then(() => {
      this.fieldConfigurationService.isFieldDefinitionsLoaded = true;
      this.eventService.broadCast(EventTypes.SystemDataLoaded, new SystemDataLoadEvent(true));
    })
  }

  setResultsDefinitions(template: string, filterKey: StoreKey, prefKey: StoreKey) {
    this.getGraphDefinitions(template, filterKey);
    this.getGraphPreferences(template, prefKey);
  }

  async getFieldDetails(templateName: string): Promise<FieldDescription[]> {
    const key = templateName === GridTemplate.Sample.toLowerCase() ? 
      StoreKey.SampleDetailFields : (templateName === GridTemplate.Order.toLowerCase() ? StoreKey.OrderDetailFields : StoreKeys.Fields);
    let collectFields: FieldDescription[] = await this.storeService.Get(key).toPromise();
    if (!collectFields) {
      collectFields = await this.fieldDefinitionService.getFieldDetails(templateName).toPromise();
      this.storeService.Save(key, collectFields, true);
    }
    return collectFields;
  }
}
