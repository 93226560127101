import { AppConfigService } from '../../core/services/app-config-service';

export class DynamicLocaleIdProvider extends String {
  constructor(protected service: AppConfigService) {
    super('');
  }

  toString() {
    return this.service.formatCulture;
  }
}
