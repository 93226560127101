import { FormatWidth } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChildren } from "@angular/core";
import { Calendar } from "primeng/calendar";
import { CustomDatePipe } from "src/app/common/pipes/custom-date.pipe";
import { DateService } from "src/app/common/pipes/date.service";
import { Converter } from "src/app/common/static-classes/converters";
import { TabModel, TimeRangeContentModel } from "src/app/features/results/resultView/resultgrid/grid-filter/tab.model";

@Component({
  selector: 'app-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss']
})
export class TimeRangeComponent implements OnInit, AfterViewInit {
  @Input() tab: TabModel;
  @Input() tooltipDisabled = true;
  @Output() dateChip: EventEmitter<any> = new EventEmitter();
  minTime: Date;
  maxTime: Date;
  timeFormat: string;
  hourFormat: number;
  @ViewChildren(Calendar, { emitDistinctChangesOnly: false }) public cals: Calendar[];
  constructor(private dateService: DateService, private readonly datePipe: CustomDatePipe) {

  }

  ngOnInit(): void {
    this.timeFormat = this.dateService.getCustomLocalTimeFormat(FormatWidth.Short, true);
    this.hourFormat = this.timeFormat.toLowerCase().indexOf('a') > -1 ? 12 : 24;
    if (this.tab.selectedContent instanceof (TimeRangeContentModel)) {
      var selectedContent = this.tab.selectedContent as TimeRangeContentModel;
      if (selectedContent.times.length == 2) {
        this.minTime = Converter.FromTimeStringToDate(selectedContent.times[0]);
        this.maxTime = Converter.FromTimeStringToDate(selectedContent.times[1]);
      }
    }
  }

  resetValues() {
    this.minTime = undefined;
    this.maxTime = undefined;
  }

  ngAfterViewInit() {
    this.cals.forEach(calendar => {
      let elem = calendar.inputfieldViewChild.nativeElement;
      elem.addEventListener('click', () => {
        if (!elem.value) {
          calendar.initTime(new Date());
          calendar.value = new Date();
        }
      });
    });
  }

  onMinTimeSelect(e: any, minTimeEle: Calendar) {
    if (!this.minTime) {
      minTimeEle.clearTimePickerTimer();
      minTimeEle.value = undefined;
    }

    if (this.minTime) {
      this.minTime.setSeconds(0);
      this.minTime.setMilliseconds(0);
    }

    if (this.maxTime && this.minTime && this.maxTime < this.minTime) {
      this.minTime = undefined;
      minTimeEle.clearTimePickerTimer();
      minTimeEle.value = undefined;
    }
    this.setOrResetSelectedContent();
  }

  onMaxTimeSelect(maxTimeEle: Calendar) {
    if (!this.maxTime) {
      maxTimeEle.clearTimePickerTimer();
      maxTimeEle.value = undefined;
    }
    if (this.maxTime) {
      this.maxTime.setSeconds(0);
      this.maxTime.setMilliseconds(0);
    }
    if (this.minTime && this.maxTime && this.maxTime < this.minTime) {
      this.maxTime = undefined;
      maxTimeEle.clearTimePickerTimer();
      maxTimeEle.value = undefined;
    }
    this.setOrResetSelectedContent();
  }

  private setOrResetSelectedContent() {
    var selectedContent = this.tab.selectedContent as TimeRangeContentModel;
    if (!selectedContent) {
      selectedContent = new TimeRangeContentModel();
    }
    if (!this.minTime || !this.maxTime) {
      selectedContent.times = [];
    } else {
      selectedContent.times = [Converter.ToTimeString(this.minTime), Converter.ToTimeString(this.maxTime)];
    }
    this.tab.selectedContent = selectedContent;
    this.dateChip.emit(this.tab);
  }

  setTimeFormat(time: Date): string {
    return this.datePipe.transform(time, 'shortTime');
  }
}
